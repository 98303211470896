import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link } from "react-router-dom";
import { useState } from "react";


export default function FundsGrowth() {
    const [selectedCountry, setSelectedCountry] = useState("");

    const countries = [
        "United States",
        "Canada",
        "United Kingdom",
        "Australia",
        "India",
        "Germany",
        "France",
        "Japan",
        "China",
        "Brazil",
    ];
    return (
        <>
            <ScrollToTop>
                <Header />
                <section className="section_space growth_hero_bg">
                    <div className="container">
                        <div className="row align-items-center justify-content-center g-4">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <h2 class="main_heading">Grow Your Funds Up</h2>
                                <p className="font_16">Deposit and trade to unlock cashback and grow your idle funds like never before!</p>
                                <p className="font_16">Get ready to embark on your 2024 journey to earn cashback Maintain a <strong>minimum of $5,000 eligible funds* and trade at least 0.5 lots per week</strong> to earn your daily 0.05% cashback!</p>
                                <p className="font_16">Tap on the power of compounding and watch your idle funds grow as you trade.</p>
                                <Link to="">
                                    <button className="btn btn_blue px-lg-5 deposit_button font_16">CHECK PROMOTIONAL ELIGIBILITY</button>
                                </Link>
                                <p className="font_16 mt-3">*<Link to="">Terms and Conditions</Link> apply.</p>
                                <p className="font_16">*Existing and fresh funds are applicable.</p>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="hero_img">
                                    <img src="assets/images/fundsgrowth/funds-growth-img.webp" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space overflow-hidden">
                    <div class="container">
                        <div className="row">
                            <h1 className="sub_heading text-center">How to Join Tradsify Funds Growth</h1>
                            <div className="col-lg-12">
                                <ul class="nav nav-tabs mt-4 justify-content-center mb-4" id="myTab" role="tablist">
                                    <li class="nav-item deposit_item" role="presentation">
                                        <button class="nav-link deposit_tab_button font_20 active" id="new-user-tab" data-bs-toggle="tab" data-bs-target="#new-user" type="button" role="tab" aria-controls="new-user" aria-selected="true">New User</button>
                                    </li>
                                    <li class="nav-item deposit_item" role="presentation">
                                        <button class="nav-link deposit_tab_button font_20" id="existing-user-tab" data-bs-toggle="tab" data-bs-target="#existing-user" type="button" role="tab" aria-controls="existing-user" aria-selected="false">Existing User</button>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content mt-4" id="myTabContent">
                                <div class="tab-pane fade show active" id="new-user" role="tabpanel" aria-labelledby="new-user-tab">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6 g-3">
                                            <div className="main_funds position-relative">
                                                <div className="funds_join_img">
                                                <img src="assets/images/fundsgrowth/funds1.png" alt="" className="trad_live"/>
                                                </div>
                                                <div className="funds_step1">1</div>
                                                <p className="font_16">Open a Tradsify live account.</p>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 g-3">
                                            <div className="main_funds position-relative">
                                                <div className="funds_join_img">
                                                    <img src="assets/images/fundsgrowth/funds2.png" alt="" className="trad_live"/>
                                                </div>
                                                <div className="funds_step2">2</div>
                                                <p className="font_16">Register to join the Funds Growth promotion.</p>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 g-3">
                                            <div className="main_funds position-relative">
                                                <div className="funds_join_img">
                                                    <img src="assets/images/fundsgrowth/funds3.png" alt="" className="trad_live"/>
                                                </div>
                                                <div className="funds_step3">3</div>
                                                <p className="font_16">Deposit and trade with the required standard lots within the promotion period.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center d-flex justify-content-center mt-4">
                                        <button class="btn btn_blue hero_refer_btn px-lg-5" type="button">OPEN LIVE ACCOUNT</button>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="existing-user" role="tabpanel" aria-labelledby="existing-user-tab">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6 g-3">
                                            <div className="main_funds position-relative">
                                                <div className="funds_join_img">
                                                    <img src="assets/images/fundsgrowth/funds1.png" alt="" className="trad_live"/>
                                                </div>
                                                <div className="funds_step1">1</div>
                                                <p className="font_16">Log in to your account and check your eligibility.</p>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 g-3">
                                            <div className="main_funds position-relative">
                                                <div className="funds_join_img">
                                                    <img src="assets/images/fundsgrowth/funds2.png" alt="" className="trad_live"/>
                                                </div>
                                                <div className="funds_step2">2</div>
                                                <p className="font_16">If you're not eligible, you can start depositing to meet the minimum funds required</p>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 g-3">
                                            <div className="main_funds position-relative">
                                                <div className="funds_join_img">
                                                    <img src="assets/images/fundsgrowth/funds3.png" alt="" className="trad_live"/>
                                                </div>
                                                <div className="funds_step3">3</div>
                                                <p className="font_16">Start trading with the required standard lots within the promotion period</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center d-flex justify-content-center mt-4">
                                        <button class="btn btn_blue hero_refer_btn px-lg-5" type="button">LOGIN NOW</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space deposit_formbg">
                    <div className="container">
                        <div className="row g-4">
                            <div className="col-lg-6">
                                <h2 className="sami_medium_heading mb-3">Check your promotional eligibility</h2>
                                <p className="font_16">Check the promotional eligibility for your country of residency to see how much bonus you can earn.</p>
                            </div>
                            <div className="col-lg-6">
                                <form className="country_form text-center">
                                    <div className="deposit_email position-relative mb-4">
                                        <input className="deposit_medium" type="email" name="email" id="email" autocomplete="off" placeholder="Email" />
                                    </div>
                                    <div className="mb-4">
                                        <select
                                            id="countrySelect"
                                            className="form-select deposit_country"
                                            value={selectedCountry}
                                            onChange={(e) => setSelectedCountry(e.target.value)}
                                        >
                                            <option value="" disabled>
                                                -- Select Country --
                                            </option>
                                            {countries.map((country, index) => (
                                                <option key={index} value={country}>
                                                    {country}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button type="submit" className="btn btn_blue px-lg-5 deposits_button mb-4">CHECK NOW</button>
                                    <p className="font_16">Not a client?
                                        <Link target="_blank" href="https://my.tradsify.com/en/auth/sign-in">Open live account</Link> now.</p>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="section_space">
                    <div className="container">
                        <div className="row justify-content-center g-3">
                            <div className="col-lg-12">
                            <h2 class="medium_heading text-center mb-3">Earn Weekly Cashback</h2>
                            </div>
                            <div class="table-responsive">
                                <table class="table mb-0">
                                    <thead>
                                        <tr class="table-header">
                                            <th class="p-3">Days</th>
                                            <th class="p-3">Eligible Funds</th>
                                            <th class="p-3 text-center">Lots Traded</th>
                                            <th class="p-3 text-center">Cashback Rate</th>
                                            <th class="p-3 text-center">Eligibility</th>
                                            <th class="p-3 text-center">Total Earnings(USD)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="table-row">
                                            <td class="p-3">Monday</td>
                                            <td class="p-3">$10,000</td>
                                            <td class="p-3 highlight-cell"></td>
                                            <td class="p-3 highlight-cells"></td>
                                            <td class="p-3 text-center">YES</td>
                                            <td class="p-3 text-center">$5.00</td>
                                        </tr>
                                        <tr class="table-row">
                                            <td class="p-3">Tuesday</td>
                                            <td class="p-3">$5,000</td>
                                            <td class="p-3 highlight-cell"></td>
                                            <td class="p-3 highlight-cells"></td>
                                            <td class="p-3 text-center">YES</td>
                                            <td class="p-3 text-center">$2.50</td>
                                        </tr>
                                        <tr class="table-row">
                                            <td class="p-3">Wednesday</td>
                                            <td class="p-3">$10,000</td>
                                            <td class="p-3 highlight-cell">1</td>
                                            <td class="p-3 highlight-cells">0.05%</td>
                                            <td class="p-3 text-center">YES</td>
                                            <td class="p-3 text-center">$5.00</td>
                                        </tr>
                                        <tr class="table-row">
                                            <td class="p-3">Thursday</td>
                                            <td class="p-3">$7,000</td>
                                            <td class="p-3 highlight-cell"></td>
                                            <td class="p-3 highlight-cells"></td>
                                            <td class="p-3 text-center">YES</td>
                                            <td class="p-3 text-center">$3.50</td>
                                        </tr>
                                        <tr class="table-row">
                                            <td class="p-3">Friday</td>
                                            <td class="p-3">$10,000</td>
                                            <td class="p-3 highlight-cell"></td>
                                            <td class="p-3 highlight-cells"></td>
                                            <td class="p-3 text-center">YES</td>
                                            <td class="p-3 text-center">$5.00</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr class="footer-row">
                                            <td class="p-3" colspan="4">Total Weekly Earnings</td>
                                            <td class="p-3" colspan="4">$21.00</td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                            <div className="col-lg-12">
                                <p className="font_16 mt-3">*The table above shows an example of daily Cashback payout in one week.</p>
                            </div>
                        </div>
                    </div>
                </section> */}
                <section className="section_space">
                    <div className="container">
                        <div className="text-center">
                            <h2 className="sub_heading mb-xl-5 mb-3">Frequently Asked Questions</h2>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="false"
                                            aria-controls="collapseOne"
                                        >
                                            1 .  How do I get funds growth?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            funds growth is based on a daily cashback calculation of 0.05% per day on eligible funds. The accrued cashback settles at a rate of 0.25% every following Monday, resulting in a total of 13% annually.
                                        </div>
                                    </div>

                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            2 . Can I participate with multiple accounts?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse "
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            Absolutely! You are allowed to participate with multiple accounts. However, it’s important to note that each account must meet specified requirements independently. For instance, if you participate with two accounts (account A and account B) and account A meets the criteria while account B does not, you will only receive cashback associated with account A.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                        >
                                            3 .   Why is there a difference between my equity and eligible funds for cashback calculation?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            The calculation of eligible funds is determined by deducting any credited amounts from your equity. For instance, if your equity is USD$6,000 with a credit of USD$500, you will receive 0.05% daily cashback for USD$5,500.
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                            However, if your equity is USD$5,000 with a credit of USD$500, no cashback will be accrued as your eligible funds fall below the minimum requirement of USD$5,000.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour"
                                        >
                                            4 . Why is my actual cashback payout lower than the projected value shown on Client Portal?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFour"
                                        className="accordion-collapse collapse "
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            The actual cashback payout is contingent on specific conditions: In order to receive the full cashback, it is necessary to maintain eligible funds of at least USD$5,000 daily throughout the previous week and execute a minimum of 0.5 standard lots per USD$5,000 in eligible funds. Failure to meet any of these criteria may result in a lower cashback payout.
                                        </div>
                                        <div className="accordions-body text-start font_16">
                                            For example, if a client maintains eligible funds of USD$18,000 every day but only trades 1 lot, they will receive 0.05% daily cashback for USD$10,000. The calculation, based on 0.05% x $10,000 x 5 days, yields a payout of USD$25.
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                            Here's a breakdown for a clearer visualisation:
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive"
                                            aria-expanded="false"
                                            aria-controls="collapseFive"
                                        >
                                            5 .   I didn't receive any payout this week. Why might that be?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFive"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingFive"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            There are several possible reasons why you didn't receive your cashback payout during Monday’s settlement:
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                            · Your eligible funds fell below USD$5,000 on any day throughout the previous week.<br />
                                            · You executed less than 0.5 standard lots per USD$5,000 in eligible funds during the previous week.<br />
                                            · You made a withdrawal or internal fund transfer from participating accounts during the previous week.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </ScrollToTop>
        </>
    )
}

