import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import Header from "../component/Header";
import Footer from "../component/Footer";

export default function HelpCenter() {
    return (
        <>
            <ScrollToTop>
                <Header />
                <section className="section_space contact_us">
                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col-lg-12">
                                <h2 class="main_heading text-white mb-4">How we can help you?</h2>
                                {/* <div className="help_group">
                                    <input className="help_input font_20 mb-3" type="search" name="email" autocomplete="off" placeholder="search" />
                                    <img src="assets/images/helpcenter/search-svgrepo-com.svg" alt="" />
                                </div>

                                <div className="help_keywords text-center">
                                    <p className="font_16 fw-bold text-white">Top Search Keywords</p>
                                    <ul className="d-flex flex-wrap justify-content-center p-0 m-0 ">
                                        <li className="help_hero_list"><a href="#/"><span>#</span> Account Opening</a></li>
                                        <li className="help_hero_list"><a href="#/"><span>#</span> Negative Balance</a></li>
                                        <li className="help_hero_list"><a href="#/"><span>#</span> Archive Card</a></li>
                                        <li className="help_hero_list"><a href="#/"><span>#</span> Withdrawal</a></li>
                                        <li className="help_hero_list"><a href="#/"><span>#</span> Authenticator Device</a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space">
                    <div className="container">
                        <div className="row g-4">
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="help_card_main">
                                    <div className="help_card_img">
                                        <img className="normal_icon" src="assets/images/helpcenter/card1-img-1.svg" alt="" />
                                        <img className="hover_icon" src="assets/images/helpcenter/card1-img-2.svg" alt="" />
                                    </div>
                                    <div className="help_card_title">
                                        <p className="font_20">Getting Started</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="help_card_main">
                                    <div className="help_card_img">
                                        <img className="normal_icon" src="assets/images/helpcenter/card2-img1.svg" alt="" />
                                        <img className="hover_icon" src="assets/images/helpcenter/card2-img2.svg" alt="" />
                                    </div>
                                    <div className="help_card_title">
                                        <p className="font_20">Accounts</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="help_card_main">
                                    <div className="help_card_img">
                                        <img className="normal_icon" src="assets/images/helpcenter/card3-img1.svg" alt="" />
                                        <img className="hover_icon" src="assets/images/helpcenter/card3-img2.svg" alt="" />
                                    </div>
                                    <div className="help_card_title">
                                        <p className="font_20">Funds</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="help_card_main">
                                    <div className="help_card_img">
                                        <img className="normal_icon" src="assets/images/helpcenter/card4-img1.svg" alt="" />
                                        <img className="hover_icon" src="assets/images/helpcenter/card4-img2.svg" alt="" />
                                    </div>
                                    <div className="help_card_title">
                                        <p className="font_20">Trading Knowledge Center</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="help_card_main">
                                    <div className="help_card_img">
                                        <img className="normal_icon" src="assets/images/helpcenter/card5-img1.svg" alt="" />
                                        <img className="hover_icon" src="assets/images/helpcenter/card5-img2.svg" alt="" />
                                    </div>
                                    <div className="help_card_title">
                                        <p className="font_20">Trading platforms</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="help_card_main">
                                    <div className="help_card_img">
                                        <img className="normal_icon" src="assets/images/helpcenter/card6-img1.svg" alt="" />
                                        <img className="hover_icon" src="assets/images/helpcenter/card6-img2.svg" alt="" />
                                    </div>
                                    <div className="help_card_title">
                                        <p className="font_20">Promotional Offers</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="help_card_main">
                                    <div className="help_card_img">
                                        <img className="normal_icon" src="assets/images/helpcenter/card7-img1.svg" alt="" />
                                        <img className="hover_icon" src="assets/images/helpcenter/card7-img2.svg" alt="" />
                                    </div>
                                    <div className="help_card_title">
                                        <p className="font_20">Partnership Programs</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="help_card_main">
                                    <div className="help_card_img">
                                        <img className="normal_icon" src="assets/images/helpcenter/card8-img1.svg" alt="" />
                                        <img className="hover_icon" src="assets/images/helpcenter/card8-img2.svg" alt="" />
                                    </div>
                                    <div className="help_card_title">
                                        <p className="font_20">Copy Trading</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-12">
                                <div className="help_ticket">
                                    <div className="help_text_box">
                                        <h3 className="sub_heading">Email Us to Create a<br/><span>Support Ticket</span></h3>
                                        <a class="btn btn_blue fw-semibold font_16 z-0" href="#/">EMAIL NOW</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </ScrollToTop>
        </>
    )
}