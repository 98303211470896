import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link } from "react-router-dom";
import { useState } from "react";

export default function ReferFriend() {
    const [selectedCountry, setSelectedCountry] = useState("");
    const countries = [
        "United States",
        "Canada",
        "United Kingdom",
        "Australia",
        "India",
        "Germany",
        "France",
        "Japan",
        "China",
        "Brazil",
    ];

    return (
        <>
            <ScrollToTop>
                <Header />
                <section className="section_space refer_herobg">
                    <div className="container">
                        <div className="row g-4 align-items-center">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="refer_hero_text">
                                    <h2 class="main_heading mb-2">Refer a Friend and Reap Rewards Together</h2>
                                    <p class="font_16 mt-3">Enjoying your experience with Tradsify? Here’s your chance to earn extra reward for yourself and your friend*.</p>
                                    <Link to="" className="btn btn_blue fw-semibold font_16 hero_refer_btn z-0">Opt-In Now
                                    </Link>
                                    <p class="font_16 mt-3">*Terms and Conditions apply.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 order-md-1 order-lg-2">
                                <div className="refer_hero_img">
                                    <img src="assets/images/referfriend/refer-hero.webp" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space refer_form_bg">
                    <div className="container">
                        <div className="row g-5 align-items-center text-center justify-content-center">
                            <div className="col-12">
                                <h2 class="sub_heading mb-4">Earn up to $100 when you refer a friend</h2>
                                <p className="font_16">Refer your friends and earn <strong>up to $100 bonus for you + $50 for your friend*.</strong></p>
                                <p className="font_16">Here's your chance to get rewarded when you bring your friends on board! Simply share your personalised link via Facebook, Twitter, email, or on a blog.</p>
                                <p className="font_16">When your friend signs up, funds their account, and starts live trading, you will earn a referral bonus, and they'll receive a reward too. This bonus will be promptly available in the client portal.</p>
                            </div>
                            <div className="col-12">
                                <div class="img-box position-relative">
                                    <div class="dolar_bg">
                                        {/* <!-- Dollar Image placed here --> */}
                                        <img src="assets/images/referfriend/dolar.svg" alt="" class="dolar-overlay" />

                                        <div class="box money">
                                            <div class="message text-white">
                                                <p class="font_20">Your Friend</p>
                                                <p class="font_16">Open a live account, <br />fund, and trade.</p>
                                            </div>
                                        </div>

                                        <div class="box smile">
                                            <div class="message text-white">
                                                <p class="font_20">You</p>
                                                <p class="font_16">Share your referral link, <br />and earn a cash reward.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <h2 className="sami_medium_heading mb-3">Check your promotional eligibility</h2>
                                <p className="font_16">Check the promotional eligibility for your country of residency to see how much bonus you can earn.</p>
                            </div>
                            <div className="col-lg-5">
                                <form className="country_form text-center">
                                    <div className="deposit_email position-relative mb-4">
                                        <input className="deposit_medium" type="email" name="email" id="email" autocomplete="off" placeholder="Email" />
                                    </div>
                                    <div className="mb-4">
                                        <select
                                            id="countrySelect"
                                            className="form-select deposit_country"
                                            value={selectedCountry}
                                            onChange={(e) => setSelectedCountry(e.target.value)}
                                        >
                                            <option value="" disabled>
                                                -- Select Country --
                                            </option>
                                            {countries.map((country, index) => (
                                                <option key={index} value={country}>
                                                    {country}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button type="submit" className="btn btn_blue px-lg-5 deposits_button mb-4">CHECK NOW</button>
                                    <p className="font_16">Not a client?
                                        <a href="#/"> Open live account</a> now.</p>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space refer_work_bg">
                    <div class="container">
                        <div className="row g-3">
                            <h1 className="sub_heading text-center">How Does it Work?</h1>
                            <div className="col-lg-12">
                                <ul class="nav nav-tabs mt-4 justify-content-center mb-4" id="myTab" role="tablist">
                                    <li class="nav-item deposit_item" role="presentation">
                                        <button class="nav-link deposit_tab_button font_20 active" id="new-user-tab" data-bs-toggle="tab" data-bs-target="#new-user" type="button" role="tab" aria-controls="new-user" aria-selected="true">New User</button>
                                    </li>
                                    <li class="nav-item deposit_item" role="presentation">
                                        <button class="nav-link deposit_tab_button font_20" id="existing-user-tab" data-bs-toggle="tab" data-bs-target="#existing-user" type="button" role="tab" aria-controls="existing-user" aria-selected="false">Existing User</button>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content mt-4" id="myTabContent">
                                <div class="tab-pane fade show active" id="new-user" role="tabpanel" aria-labelledby="new-user-tab">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6 g-3">
                                            <div class="step-circle"><img src="assets/images/referfriend/refer-hand.svg" alt="" /></div>
                                            <h5 className="sub_small_heading">Open Live Trading Account</h5>
                                            <p className="font_16">Open a live account and opt-in to the Tradsify Refer a Friend promotion to be eligible.</p>
                                        </div>
                                        <div class="col-lg-4 col-md-6 g-3">
                                            <div class="step-circle"><img src="assets/images/referfriend/refer-share.svg" alt="" /></div>
                                            <h5 className="sub_small_heading">Share Your Referral Link</h5>
                                            <p className="font_16">Sign in to the Tradsify client portal to get your referral link. Share your unique link with your friends to start earning!</p>
                                        </div>
                                        <div class="col-lg-4 col-md-6 g-3">
                                            <div class="step-circle"><img src="assets/images/referfriend/refer-face-happy.svg" alt="" /></div>
                                            <h5 className="sub_small_heading">Receive Your Referral Bonus</h5>
                                            <p className="font_16">Once your referred friend makes a successful deposit into their trading account and begins trading, both you and your friend will receive a cash reward.</p>
                                        </div>
                                    </div>
                                    <div class="text-center d-flex justify-content-center mt-4">
                                        <button class="btn btn_blue px-lg-5" type="button">OPEN LIVE ACCOUNT</button>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="existing-user" role="tabpanel" aria-labelledby="existing-user-tab">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6 g-3">
                                            <div class="step-circle"><img src="assets/images/referfriend/refer-link.svg" alt="" /></div>
                                            <h5 className="sub_small_heading">Opt-in to the Promotion</h5>
                                            <p className="font_16">Login to the Tradsify client portal to get your unique referral link.</p>
                                        </div>
                                        <div class="col-lg-4 col-md-6 g-3">
                                            <div class="step-circle"><img src="assets/images/referfriend/refer-share.svg" alt="" /></div>
                                            <h5 className="sub_small_heading">Share Your Referral Link</h5>
                                            <p className="font_16">Share your unique link with your friends to start earning!</p>
                                        </div>
                                        <div class="col-lg-4 col-md-6 g-3">
                                            <div class="step-circle"><img src="assets/images/referfriend/refer-face-happy.svg" alt="" /></div>
                                            <h5 className="sub_small_heading">Receive Your Referral Bonus</h5>
                                            <p className="font_16">Once your referred friend makes a successful deposit into their trading account and begins trading, both you and your friend will receive a cash reward.</p>
                                        </div>
                                    </div>
                                    <div class="text-center d-flex justify-content-center mt-4">
                                        <button class="btn btn_blue px-lg-5" type="button">Join Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space">
                    <div className="container">
                        <div className="row g-4 refer_learn">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="refer_learn_text">
                                    <h2 className="medium_heading mb-3">Elevate Your Earnings Potential</h2>
                                    <p className="font_16 mb-4">Discover the Tradsify Partner Program and maximise your earnings through our CPA or IB referral schemes.</p>
                                    <div className="refer_learn_btn d-flex gap-4">
                                        <Link to="" className="btn btn_blue fw-semibold font_16 z-0">Opt-In Now
                                        </Link>
                                        <Link to="" className="btn fw-semibold font_16 btn_black z-0">Opt-In Now
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-md-1 order-lg-2">
                                <div className="refer_learn_img d-flex justify-content-lg-end">
                                    <img src="assets\images\referfriend\download.svg" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </ScrollToTop>
        </>
    )
}