import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link } from "react-router-dom";
import { useState } from "react";

export default function IntroducingBroker() {
    const [selectedCountry, setSelectedCountry] = useState("");

    const countries = [
        "United States",
        "Canada",
        "United Kingdom",
        "Australia",
        "India",
        "Germany",
        "France",
        "Japan",
        "China",
        "Brazil",
    ];

    const [message, setMessage] = useState("");
    const maxLength = 200;

    const handleInputChange = (e) => {
        const input = e.target.value;

        if (input.length <= maxLength) {
            setMessage(input);
        }
    };

    return (
        <>
            <ScrollToTop>
                <Header />
                <section className="section_space broker_hero_bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h2 className="main_heading text-white">Introducing Broker</h2>
                                <p className="font_16 text-white">Join the tradsify IB Program today, which offers one of the most competitive forex IB rebates in the industry.</p>
                                <Link to="" className="btn btn_blue fw-semibold font_16 hero_copytrading_btn z-0">GET STARTED NOW
                                    <span><img src="assets/images/copytrading/hero-btn-icon.svg" alt="" className="hero_btn_icon" /></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space">
                    <div className="container">
                        <div className="row align-items-center g-4">
                            <div className="col-lg-6 col-md-6">
                                <div className="broker_img position-relative">
                                    <img src="assets/images/introducingbroker/broker-trader.webp" alt="" className="broker_trader" />
                                    <img src="assets/images/introducingbroker/broker-connect1.svg" alt="" className="broker_connect" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="broker_trader_text">
                                    <h3 className="small_heading">Connecting Traders and Introducing Brokers</h3>
                                    <p className="font_16">What is an Introducing Broker?</p>
                                    <p className="font_16">An Introducing Broker serves as a facilitator between individual traders and forex brokers. As the name suggests, introducing brokers introduce new clients to a forex broker in return for commissions.</p>
                                    <p className="font_16">As an IB partner, you’ll be earning volume-based commissions on every trade made by the clients you introduce to the broker.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space broker_incomebg">
                    <div className="container">
                        <div class="row g-4">
                            <h2 className="sub_heading text-white text-center mb-4">How to Become an Introducing Broker</h2>
                            <div class="col-lg-4 col-md-6 g-3">
                                <div class="step-circle">1</div>
                                <h5 className="sub_small_heading broker_refer">Sign Up</h5>
                                <p className="font_16 text-white">Sign up in an IB program with one of the largest global multi-asset brokers.</p>
                            </div>
                            <div class="col-lg-4 col-md-6 g-3">
                                <div class="step-circle">2</div>
                                <h5 className="sub_small_heading broker_refer">Refer</h5>
                                <p className="font_16 text-white">Expand your referral network by introducing new clients to trade with Tradsify.</p>
                            </div>
                            <div class="col-lg-4 col-md-6 g-3">
                                <div class="step-circle">3</div>
                                <h5 className="sub_small_heading broker_refer">Earn</h5>
                                <p className="font_16 text-white">Earn volume-based commissions for each trade made by clients you’ve referred.</p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space partner_contact overflow-hidden">
                    <div className="container">
                        <div className="row g-4">
                            <div className="col-lg-6">
                                <div className="contact_icon d-flex gap-3">
                                    <img src="assets/images/partnership-overview/contact-us-icon01.svg" alt="" />
                                    <img src="assets/images/partnership-overview/contact-us-icon02.svg" alt="" />
                                </div>
                                <h2 className="sami_medium_heading mb-3">Have a Question? Get in Touch</h2>
                                <p className="font_16">If you have any questions or enquiries, fill in the form in this section. Alternatively, contact us at</p>
                                <p className="font_16"><a href="mailto:support@tradsify.com" className="text-decoration-underline">support@tradsify.com</a>  and one of our Account Managers will get back to you soon.</p>
                            </div>
                            <div className="col-lg-6">
                                <form className="country_form text-center">
                                    <div className="deposit_email position-relative mb-4">
                                        <input className="deposit_medium mb-3" type="text" name="email" autocomplete="off" placeholder="Full Name" />
                                        <input className="deposit_medium mb-3" type="tel" name="email" id="number" autocomplete="off" placeholder="Phone" />
                                        <input className="deposit_medium" type="email" name="email" id="email" autocomplete="off" placeholder="Email" />
                                    </div>
                                    <div className="mb-4">
                                        <select
                                            id="countrySelect"
                                            className="form-select deposit_country"
                                            value={selectedCountry}
                                            onChange={(e) => setSelectedCountry(e.target.value)}
                                        >
                                            <option value="" disabled>
                                                -- Select Country --
                                            </option>
                                            {countries.map((country, index) => (
                                                <option key={index} value={country}>
                                                    {country}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <textarea
                                        className="deposit_medium partner_message"
                                        id="message"
                                        name="message"
                                        placeholder="Message"
                                        rows="4"
                                        cols="50"
                                        value={message}
                                        onChange={handleInputChange}>
                                    </textarea>
                                    <div className="text-start font_16 mt-2" id="charCount">{`${message.length} of ${maxLength} max characters`}</div>
                                    <div className="d-flex justify-content-end">
                                        <button type="submit" className="btn btn_blue px-lg-5 contact_button mb-4">CHECK NOW</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </ScrollToTop>
        </>
    )
}