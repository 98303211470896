import { Link } from "react-router-dom";
export default function Header() {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-light nav_padding">
      <div className="container-fluid">
        {/* Logo */}
        <Link to="/" className="navbar-brand fw-bold">
          <img
            src="/assets/images/logo/Tradsify.svg"
            alt="Logo"
            className="img-fluid"
          />
        </Link>
        {/* Navbar Toggler */}
        {/* <button
          className="navbar-toggler bg-black"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button> */}
        <button
          className="navbar-toggle nav_ham  d-lg-none d-block"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <input id="checkbox2" type="checkbox" />
          <label className="toggle toggle2" htmlFor="checkbox2">
            <div id="bar4" className="bars" />
            <div id="bar5" className="bars" />
            <div id="bar6" className="bars" />
          </label>
        </button>

        {/* Navbar Links */}
        <div
          className="collapse navbar-collapse bg-light mobile-sidebar tradsify_nav"
          id="navbarSupportedContent"
        >
          <ul className="ps-lg-3 ps-0 navbar-nav mb-2 mb-lg-0">
            {/* Trading Dropdown */}
            <li className="nav-item dropdown fullwidth-dropdown d-none d-lg-block wrapper arrow_icon">
              <div className="d-flex align-items-center">
                <Link to="/coming-soon" className="nav-link navbar_padding">
                  Trading
                </Link>
                <img
                  src="assets/images/header/down-arrow.svg"
                  alt=""
                  className="arrow
              "
                />
              </div>
              <div className="dropdown-menu dropdown-content inner_menu">
                <div className="heading_menu">
                  <div className="row">
                    <div className="col-lg-2">
                      <div className="box-left">
                        <h5 className="sub_small_heading mb-3">TRADING</h5>
                        <p className="font_15">
                          Explore a world of trading possibilities with 1,000+
                          CFD products, covering forex, indices, stocks,
                          commodities, precious metals and more, across various
                          markets on our versatile trading platforms.
                        </p>
                        <a href="#" className="round_button">
                          <img
                            src="assets/images/header/arrow.svg"
                            alt=""
                            srcset=""
                          />
                        </a>
                      </div>
                    </div>

                    <div className="col-lg-2 offset-lg-1">
                      <h6 className="font_20 text-secondary mb-3">Trading</h6>
                      <ul className="list-unstyled ps-0 font_15 text-black text-decoration-none">
                        {/* <li className="mb-3">
                          <Link
                            to="/Instruments"
                            className="text-black text-decoration-none mb-3"
                          >
                            All Instruments
                          </Link>
                        </li> */}
                        <li className="mb-3">
                          <Link
                            to="/markets"
                            className="text-black text-decoration-none mb-3"
                          >
                            Markets
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/Tradingaccounts"
                            className="text-black text-decoration-none mb-3"
                          >
                            Trading Accounts
                          </Link>
                        </li>
                        {/* <li className="mb-3">
                          <Link
                            to="/coming-soon"
                            className="text-black text-decoration-none mb-3"
                          >
                            Trading Fees
                          </Link>
                        </li> */}
                        <li className="mb-3">
                          <Link
                            to="/Support"
                            className="text-black text-decoration-none mb-3"
                          >
                            Support
                          </Link>
                        </li>
                      </ul>
                    </div>

                    <div className="col-lg-2">
                      <h6 className="font_20 text-secondary mb-3">Platforms</h6>
                      <ul className="list-unstyled ps-0">
                        <li className="mb-3">
                          <Link
                            to="/platformsoverview"
                            className="text-black text-decoration-none mb-3"
                          >
                            Overview
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/tradingapp"
                            className="text-black text-decoration-none mb-3"
                          >
                            Tradsify App
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/metatrader-5"
                            className="text-black text-decoration-none mb-3"
                          >
                            MetaTrader 5{" "}
                            <span className="popular font_12">POPULAR</span>
                          </Link>
                        </li>

                        {/* <li className="mb-3">
                          <Link
                            to="/coming-soon"
                            className="text-black text-decoration-none"
                          >
                            TradingView
                          </Link>
                        </li> */}
                        {/* <li className="mb-3">
                          <Link
                            to="/coming-soon"
                            className="text-black text-decoration-none"
                          >
                            ProTrader
                          </Link>
                        </li> */}
                        <li className="mb-3">
                          <Link
                            to="/copytrading"
                            className="text-black text-decoration-none"
                          >
                            Copy Trading{" "}
                            <span className="popular font_12">POPULAR</span>
                          </Link>
                        </li>
                        <li className="mb-3">
                          <Link
                            to="/demotrading"
                            className="text-black text-decoration-none"
                          >
                            Demo Trading
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>

            <li class="mb-1 mobile_nav_menus">
              <Link
                to="/"
                className="navbar-brand fw-bold nav-link navbar_padding mb-3"
              >
                <img
                  src="/assets/images/logo/Tradsify.svg"
                  alt="Logo"
                  className="img-fluid"
                />
              </Link>
              <div
                className="d-flex align-items-center navbar_padding justify-content-between border-top pt-4 dropdown fullwidth-dropdown wrapper arrow_icon"
                data-bs-toggle="collapse"
                data-bs-target="#home-collapse2"
                aria-expanded="false"
              >
                <Link
                  className="nav-link btn-toggle align-items-center text-black"
                  data-bs-toggle="collapse"
                  data-bs-target="#home-collapse2"
                  aria-expanded="false"
                >
                  Trading
                </Link>
                <img
                  src="assets/images/header/down-arrow.svg"
                  alt="arrow"
                  className="arrow"
                  aria-expanded="false"
                />
              </div>
              <div className="collapse" id="home-collapse2">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small px-4">
                  {/* <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none mb-3"
                    >
                      All Instruments
                    </Link>
                  </li> */}
                  <li className="mb-3">
                    <Link
                      to="/markets"
                      className="text-black text-decoration-none mb-3"
                    >
                      Markets
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/Tradingaccounts"
                      className="text-black text-decoration-none mb-3"
                    >
                      Trading Accounts
                    </Link>
                  </li>
                  {/* <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none mb-3"
                    >
                      Trading Fees
                    </Link>
                  </li> */}
                  <li className="mb-3">
                    <Link
                      to="/Support"
                      className="text-black text-decoration-none mb-3"
                    >
                      Support
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="mb-1 mobile_nav_menus">
              <div
                className="d-flex align-items-center navbar_padding justify-content-between fullwidth-dropdown wrapper arrow_icon"
                data-bs-toggle="collapse"
                data-bs-target="#home-collapse5"
                aria-expanded="false"
              >
                <Link className="nav-link btn-toggle align-items-center rounded text-black">
                  Platforms
                </Link>
                <img
                  src="assets/images/header/down-arrow.svg"
                  alt="arrow"
                  className="arrow"
                  aria-expanded="false"
                />
              </div>
              <div className="collapse" id="home-collapse5">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small px-4">
                  <li className="mb-3">
                    <Link
                      to="/platformsoverview"
                      className="text-black text-decoration-none mb-3"
                    >
                      Overview
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/tradingapp"
                      className="text-black text-decoration-none mb-3"
                    >
                      Tradsify App
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/metatrader-5"
                      className="text-black text-decoration-none mb-3"
                    >
                      MetaTrader 5{" "}
                      <span className="popular font_12">POPULAR</span>
                    </Link>
                  </li>
                  {/* <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none"
                    >
                      TradingView
                    </Link>
                  </li> */}
                  {/* <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none"
                    >
                      ProTrader
                    </Link>
                  </li> */}
                  <li className="mb-3">
                    <Link
                      to="/copytrading"
                      className="text-black text-decoration-none"
                    >
                      Copy Trading{" "}
                      <span className="popular font_12">POPULAR</span>
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/demotrading"
                      className="text-black text-decoration-none"
                    >
                      Demo Trading
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item dropdown fullwidth-dropdown d-none d-lg-block wrapper arrow_icon">
              <div className="d-flex align-items-center">
                <Link className="nav-link navbar_padding" to="/about">
                  Learn
                </Link>
                <img
                  src="assets/images/header/down-arrow.svg"
                  alt=""
                  className="arrow
              "
                />
              </div>
              <div className="dropdown-menu dropdown-content inner_menu">
                <div className="heading_menu">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-2">
                        <div className="box-left">
                          <h5 className="sub_small_heading mb-3">LEARN</h5>
                          <p className="font_15">
                            Learn all about trading with Tradsify Academy and
                            get the latest financial news plus in-depth market
                            analysis.
                          </p>
                          <a href="#" className="round_button">
                            <img
                              src="assets/images/header/arrow.svg"
                              alt=""
                              srcset=""
                            />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-2 offset-lg-1">
                        <h6 className="font_20 text-secondary mb-3">Academy</h6>
                        <ul className="list-unstyled ps-0 font_15 text-black text-decoration-none">
                          {/* <li className="mb-3">
                            <Link
                              to="/coming-soon"
                              className="text-black text-decoration-none mb-3"
                            >
                              All Articles
                            </Link>
                          </li> */}
                          <li className="mb-3">
                            <Link
                              to="/course"
                              className="text-black text-decoration-none mb-3"
                            >
                              Courses
                            </Link>
                          </li>
                          {/* <li className="mb-3">
                            <Link
                              to="/coming-soon"
                              className="text-black text-decoration-none mb-3"
                            >
                              eBooks
                            </Link>
                          </li> */}
                          {/* <li className="mb-3">
                            <Link
                              to="/coming-soon"
                              className="text-black text-decoration-none mb-3"
                            >
                              Terminology
                            </Link>
                          </li> */}
                          {/* <li className="mb-3">
                            <Link
                              to="/coming-soon"
                              className="text-black text-decoration-none mb-3"
                            >
                              Live
                            </Link>
                          </li> */}
                          {/* <li className="mb-3">
                            <Link
                              to="/coming-soon"
                              className="text-black text-decoration-none mb-3"
                            >
                              The Tradsify View{" "}
                              <span className="popular font_12">NEW</span>
                            </Link>
                          </li>{" "} */}
                        </ul>
                      </div>

                      {/* <div className="col-lg-2">
                        <h6 className="font_20 text-secondary mb-3">
                          Analysis
                        </h6>
                        <ul className="list-unstyled ps-0">
                          <li className="mb-3">
                            <Link
                              to="/coming-soon"
                              className="text-black text-decoration-none mb-3"
                            >
                              Overview
                            </Link>
                          </li>
                          <li className="mb-3">
                            <Link
                              to="/coming-soon"
                              className="text-black text-decoration-none mb-3"
                            >
                              Economic Calendar
                            </Link>
                          </li>
                          <li className="mb-3">
                            <Link
                              to="/coming-soon"
                              className="text-black text-decoration-none mb-3"
                            >
                              Market Analysis
                            </Link>
                          </li>
                          <li className="mb-3">
                            <Link
                              to="/coming-soon"
                              className="text-black text-decoration-none "
                            >
                              Client Sentiment
                            </Link>
                          </li>
                          <li className="mb-3">
                            <Link
                              to="/coming-soon"
                              className="text-black text-decoration-none"
                            >
                              Forex VPS
                            </Link>
                          </li>
                          <li className="mb-3">
                            <Link
                              to="/coming-soon"
                              className="text-black text-decoration-none"
                            >
                              Trading Signal
                            </Link>
                          </li>
                          <li className="mb-3">
                            <Link
                              to="/coming-soon"
                              className="text-black text-decoration-none"
                            >
                              Notifications
                            </Link>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="mb-1 mobile_nav_menus">
              <div
                className="d-flex align-items-center navbar_padding justify-content-between fullwidth-dropdown wrapper arrow_icon"
                data-bs-toggle="collapse"
                data-bs-target="#home-collapse3"
                aria-expanded="false"
              >
                <Link
                  class="nav-link btn-toggle align-items-center rounded text-black"
                  data-bs-toggle="collapse"
                  data-bs-target="#home-collapse3"
                  aria-expanded="false"
                >
                  Academy
                </Link>
                <img
                  src="assets/images/header/down-arrow.svg"
                  alt="arrow"
                  className="arrow"
                  aria-expanded="false"
                />
              </div>
              <div class="collapse" id="home-collapse3">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small px-4">
                  {/* <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none mb-3"
                    >
                      All Articles
                    </Link>
                  </li> */}
                  <li className="mb-3">
                    <Link
                      to="/course"
                      className="text-black text-decoration-none mb-3"
                    >
                      Courses
                    </Link>
                  </li>
                  {/* <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none mb-3"
                    >
                      eBooks
                    </Link>
                  </li> */}
                  {/* <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none mb-3"
                    >
                      Terminology
                    </Link>
                  </li> */}
                  {/* <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none mb-3"
                    >
                      Live
                    </Link>
                  </li> */}
                  {/* <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none mb-3"
                    >
                      The Tradsify View{" "}
                      <span className="popular font_12">New</span>
                    </Link>
                  </li>{" "} */}

                </ul>
              </div>
            </li>
            {/* <li class="mb-1 mobile_nav_menus">
              <div
                className="d-flex align-items-center navbar_padding justify-content-between fullwidth-dropdown wrapper arrow_icon"
                data-bs-toggle="collapse"
                data-bs-target="#home-collapse6"
                aria-expanded="false"
              >
                <Link
                  class="nav-link btn-toggle align-items-center rounded text-black"
                  data-bs-toggle="collapse"
                  data-bs-target="#home-collapse6"
                  aria-expanded="false"
                >
                  Analysis
                </Link>
                <img
                  src="assets/images/header/down-arrow.svg"
                  alt="arrow"
                  className="arrow"
                  aria-expanded="false"
                />
              </div>
              <div class="collapse" id="home-collapse6">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small px-4">
                  <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none mb-3"
                    >
                      Overview
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none mb-3"
                    >
                      Economic Calendar
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none mb-3"
                    >
                      Market Analysis
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none "
                    >
                      Client Sentiment
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none"
                    >
                      Forex VPS
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none"
                    >
                      Trading Signal
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none"
                    >
                      Notifications
                    </Link>
                  </li>
                </ul>
              </div>
            </li> */}
            <li className="nav-item dropdown fullwidth-dropdown d-none d-lg-block wrapper arrow_icon">
              <div className="d-flex align-items-center">
                <Link className="nav-link navbar_padding">Promotions</Link>
                <img
                  src="assets/images/header/down-arrow.svg"
                  alt=""
                  className="arrow
              "
                />
              </div>

              <div className="dropdown-menu dropdown-content inner_menu">
                <div className="heading_menu">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-2">
                        <div className="box-left">
                          <h5 className="sub_small_heading mb-3">PROMOTIONS</h5>
                          <p className="font_15">
                            Check out our range of promotions, including
                            exciting welcome bonuses, and get rewarded for every
                            trade you make with Tradsify Rewards.
                          </p>
                          <a href="#" className="round_button">
                            <img
                              src="assets/images/header/arrow.svg"
                              alt=""
                              srcset=""
                            />
                          </a>
                        </div>
                      </div>

                      <div className="col-lg-2 offset-lg-1">
                        <h6 className="font_20 text-secondary mb-3">
                          Promotions
                        </h6>
                        <ul className="list-unstyled ps-0 font_15 text-black text-decoration-none">
                        {/* <li className="mb-3">
                            <Link
                              to="/coming-soon"
                              className="text-black text-decoration-none mb-3"
                            >
                             Overview
                            </Link>
                          </li> */}
                          <li className="mb-3">
                            <Link
                              to="/depositbonus"
                              className="text-black text-decoration-none mb-3"
                            >
                              Deposit Bonus{" "}
                              <span className="popular font_12">POPULAR</span>
                            </Link>
                          </li>
                          <li className="mb-3">
                            <Link
                              to="/referfriend"
                              className="text-black text-decoration-none mb-3"
                            >
                              Refer a Friend
                            </Link>
                          </li>
                          <li className="mb-3">
                            <Link
                              to="/tradsifyrewards"
                              className="text-black text-decoration-none mb-3"
                            >
                              Tradsify Rewards
                            </Link>
                          </li>
                          <li className="mb-3">
                            <Link
                              to="/fundsgrowth"
                              className="text-black text-decoration-none mb-3"
                            >
                              Funds Growth{" "}
                              <span className="popular font_12">NEW</span>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="mb-1 mobile_nav_menus">
              <div
                className="d-flex align-items-center navbar_padding justify-content-between fullwidth-dropdown wrapper arrow_icon"
                data-bs-toggle="collapse"
                data-bs-target="#home-collapse4"
                aria-expanded="false"
              >
                <Link
                  class="nav-link btn-toggle align-items-center rounded text-black"
                  data-bs-toggle="collapse"
                  data-bs-target="#home-collapse4"
                  aria-expanded="false"
                >
                  Promotions
                </Link>
                <img
                  src="assets/images/header/down-arrow.svg"
                  alt=""
                  className="arrow
              "
                />
              </div>
              <div class="collapse" id="home-collapse4">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small px-4">
                {/* <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none mb-3"
                    >
                      Overview
                    </Link>
                  </li> */}
                  <li className="mb-3">
                    <Link
                      to="/depositbonus"
                      className="text-black text-decoration-none mb-3"
                    >
                      Deposit Bonus{" "}
                      <span className="popular font_12">POPULAR</span>
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/referfriend"
                      className="text-black text-decoration-none mb-3"
                    >
                      Refer a Friend
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/tradsifyrewards"
                      className="text-black text-decoration-none mb-3"
                    >
                      Tradsify Rewards
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/fundsgrowth"
                      className="text-black text-decoration-none mb-3"
                    >
                      Funds Growth{" "}
                      <span className="popular font_12">NEW</span>
                    </Link>
                  </li>
                  {/* <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none mb-3"
                    >
                      Christmas Cashback Galore{" "}
                      <span className="popular font_12">NEW</span>
                    </Link>
                  </li> */}
                </ul>
              </div>
            </li>
            <li className="nav-item dropdown fullwidth-dropdown d-none d-lg-block wrapper arrow_icon">
              <div className="d-flex align-items-center">
                <Link to="" className="nav-link navbar_padding">
                  About
                </Link>

                <img
                  src="assets/images/header/down-arrow.svg"
                  alt=""
                  className="arrow
              "
                />
              </div>
              <div className="dropdown-menu dropdown-content inner_menu">
                <div className="heading_menu">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-2">
                        <div className="box-left">
                        
                          <h5 className="sub_small_heading mb-3">ABOUT</h5>
                          <p className="font_15">
                            Discover all there is to know about Tradsify and
                            explore potential partnership opportunities.
                          </p>
                          <Link to="/about" className="round_button">
                            <img
                              src="assets/images/header/arrow.svg"
                              alt=""
                              
                            />
                          </Link>
                        </div>
                      </div>

                      <div className="col-lg-2 offset-lg-1">
                        <h6 className="font_20 text-secondary mb-3">About</h6>
                        <ul className="list-unstyled ps-0 font_15 text-black text-decoration-none">
                        <li className="mb-3">
                            <Link
                              to="/aboutoverview"
                              className="text-black text-decoration-none mb-3"
                            >
                             Overview
                            </Link>
                          </li>
                          <li className="mb-3">
                            <Link
                              to="/whytradsify"
                              className="text-black text-decoration-none mb-3"
                            >
                              Why Tradsify?
                            </Link>
                          </li>
                          
                          {/* <li className="mb-3">
                            <Link
                              to="/coming-soon"
                              className="text-black text-decoration-none mb-3"
                            >
                              Fund Insurance
                            </Link>
                          </li> */}
                          {/* <li className="mb-3">
                            <Link
                              to="/coming-soon"
                              className="text-black text-decoration-none mb-3"
                            >
                              ESG
                            </Link>
                          </li> */}
                          {/* <li className="mb-3">
                            <Link
                              to="/coming-soon"
                              className="text-black text-decoration-none mb-3"
                            >
                              Press Releases
                            </Link>
                          </li> */}
                          {/* <li className="mb-3">
                            <Link
                              to="/coming-soon"
                              className="text-black text-decoration-none mb-3"
                            >
                              Our Trading Servers
                            </Link>
                          </li> */}
                          <li className="mb-3">
                            <Link
                              to="/reviews"
                              className="text-black text-decoration-none mb-3"
                            >
                              Reviews
                            </Link>
                          </li>{" "}
                          {/* <li className="mb-3">
                            <Link
                              to="/coming-soon"
                              className="text-black text-decoration-none mb-3"
                            >
                              Media Centre
                            </Link>
                          </li>{" "}
                          <li className="mb-3">
                            <Link
                              to="/coming-soon"
                              className="text-black text-decoration-none mb-3"
                            >
                              Our Partners
                            </Link>
                          </li>{" "}
                          <li className="mb-3">
                            <Link
                              to="/coming-soon"
                              className="text-black text-decoration-none mb-3"
                            >
                              Sponsorship
                            </Link>
                          </li>{" "} */}
                          <li className="mb-3">
                            <Link
                              to="/helpcenter"
                              className="text-black text-decoration-none mb-3"
                            >
                              Help Center
                            </Link>
                          </li>{" "}
                          <li className="mb-3">
                            <Link
                              to="/contact"
                              className="text-black text-decoration-none mb-3"
                            >
                              Contact Us
                            </Link>
                          </li>
                        </ul>
                      </div>

                      <div className="col-lg-2">
                        <h6 className="font_20 text-secondary mb-3">
                          Partnership
                        </h6>
                        <ul className="list-unstyled ps-0">
                        <li className="mb-3">
                            <Link
                              to="/partneroverview"
                              className="text-black text-decoration-none mb-3"
                            >
                              Overview
                            </Link>
                          </li>
                          {/* <li className="mb-3">
                            <Link
                              to="/coming-soon"
                              className="text-black text-decoration-none mb-3"
                            >
                              Introduction
                            </Link>
                          </li> */}
                          <li className="mb-3">
                            <Link
                              to="/introducingbroker"
                              className="text-black text-decoration-none mb-3"
                            >
                              Introducing Broker
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li class="mb-1 mobile_nav_menus">
              <div
                className="d-flex align-items-center navbar_padding justify-content-between fullwidth-dropdown wrapper arrow_icon"
                data-bs-toggle="collapse"
                data-bs-target="#home-collapse"
                aria-expanded="false"
              >
                <Link
                  class="nav-link btn-toggle align-items-center rounded text-black"
                  data-bs-toggle="collapse"
                  data-bs-target="#home-collapse"
                  aria-expanded="false"
                >
                  About
                </Link>
                <img
                  src="assets/images/header/down-arrow.svg"
                  alt=""
                  className="arrow
              "
                />
              </div>
              <div class="collapse" id="home-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small px-4">
                <li className="mb-3">
                    <Link
                      to="/aboutoverview"
                      className="text-black text-decoration-none mb-3"
                    >
                      Overview
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/whytradsify"
                      className="text-black text-decoration-none mb-3"
                    >
                      Why Tradsify?
                    </Link>
                  </li>
                  {/* <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none mb-3"
                    >
                      Fund Insurance
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none mb-3"
                    >
                      ESG
                    </Link>
                  </li>
                  <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none mb-3"
                    >
                      Press Releases
                    </Link>
                  </li> */}
                  {/* <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none mb-3"
                    >
                      Our Trading Servers
                    </Link>
                  </li> */}
                  <li className="mb-3">
                    <Link
                      to="/reviews"
                      className="text-black text-decoration-none mb-3"
                    >
                      Reviews
                    </Link>
                  </li>{" "}
                  {/* <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none mb-3"
                    >
                      Media Centre
                    </Link>
                  </li>{" "}
                  <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none mb-3"
                    >
                      Our Partners
                    </Link>
                  </li>{" "}
                  <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none mb-3"
                    >
                      Sponsorship
                    </Link>
                  </li>{" "} */}
                  <li className="mb-3">
                    <Link
                      to="/helpcenter"
                      className="text-black text-decoration-none mb-3"
                    >
                      Help Center
                    </Link>
                  </li>{" "}
                  <li className="mb-3">
                    <Link
                      to="/contact"
                      className="text-black text-decoration-none mb-3"
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="mb-1 mobile_nav_menus">
              <div
                className="d-flex align-items-center navbar_padding justify-content-between fullwidth-dropdown wrapper arrow_icon"
                data-bs-toggle="collapse"
                data-bs-target="#Partnership"
                aria-expanded="false"
              >
                <Link class="nav-link btn-toggle align-items-center rounded text-black">
                  Partnership
                </Link>
                <img
                  src="assets/images/header/down-arrow.svg"
                  alt=""
                  className="arrow
              "
                />
              </div>
              <div className="collapse" id="Partnership">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small px-4">
                  <li className="mb-3">
                    <Link
                      to="/partneroverview"
                      className="text-black text-decoration-none mb-3"
                    >
                      Overview
                    </Link>
                  </li>
                  {/* <li className="mb-3">
                    <Link
                      to="/coming-soon"
                      className="text-black text-decoration-none mb-3"
                    >
                      Introduction
                    </Link>
                  </li> */}
                  <li className="mb-3">
                    <Link
                      to="/introducingbroker"
                      className="text-black text-decoration-none mb-3"
                    >
                      Introducing Broker
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          </ul>

          <div class="d-lg-none px-4">
            <Link to="https://my.tradsify.com/en/auth/sign-in">
              <button class="btn btn_black mb-2 w-100">Sign In</button>
            </Link>
            <Link to="https://my.tradsify.com/en/auth/sign-up">
              <button class="btn btn_black btn_blue w-100">Sign Up</button>
            </Link>
          </div>
        </div>
        <div class="d-lg-flex d-none">
          <Link
            to="https://my.tradsify.com/en/auth/sign-in"
            className="text-decoration-none fw-bold "
          >
            <p class=" mb-0 me-4 color_black fs-6">Sign In</p>
          </Link>
        </div>
        <div class="d-lg-flex d-none">
          <Link to="https://my.tradsify.com/en/auth/sign-up">
            <button class="btn btn_black ">Sign Up</button>
          </Link>
        </div>
      </div>
    </nav>
  );
}
