import React, { useState } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";


export default function Instruments() {
  const [activeTab, setActiveTab] = useState("forex");

  const tabsData = [
    {
      heading: [
        { head: "Forex" }
      ],
      paragraph: [
        { para: "The 'Spread' and 'Commission' columns are provided for reference only." }
      ],
      headerData: [
        { label: " Symbol" },
        { label: "Contract Size" },
        { label: "Commission" },
        { label: "Min volumeper click" },
        { label: "Max volumeper click" },
        { label: "Leverage" },
        { label: "Trading hours (GMT+2)" },
      ],
      tableData: [
        [
          "Profit Target",
          "P1: 8%, P2: 5%",
          "P1: 8%, P2: 5%",
          "P1: 8%, P2: 5%",
          "P1: 8%, P2: 5%",
          "P1: 8%, P2: 5%",
          "P1: 8%, P2: 5%",
        ],
        [
          "Maximum Daily Loss",
          "5% ($300)",
          "5% ($750)",
          "5% ($1,250)",
          "5% ($2,500)",
          "5% ($5,000)",
          "5% ($10,000)",
        ],
        [
          "Maximum Overall Loss",
          "10% ($600)",
          "10% ($1,500)",
          "10% ($2,500)",
          "10% ($5,000)",
          "10% ($10,000)",
          "10% ($20,000)",
        ],
        [
          "Minimum Trading Days",
          "5 Days",
          "5 Days",
          "5 Days",
          "5 Days",
          "5 Days",
          "5 Days",
        ],
        [
          "First Reward",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
        ],
      ],
    },
    {
      heading: [
        { head: "Indices" }
      ],
      paragraph: [
        { para: "The 'Spread' and 'Commission' columns are provided for reference only." }
      ],
      headerData: [
        { label: "Symbol" },
        { label: "Description" },
        { label: "Contract Size" },
        { label: "Commission" },
        { label: "Spread" },
        { label: "Min volume per click" },
        { label: "Max volume per click" },
        { label: "Leverage" },
        { label: "Trading hours (GMT+2)" },
      ],
      tableData: [
        [
          "Profit Target",
          "P1: 8%, P2: 4%",
          "P1: 8%, P2: 4%",
          "P1: 8%, P2: 4%",
          "P1: 8%, P2: 4%",
          "P1: 8%, P2: 4%",
          "P1: 8%, P2: 4%",
          "P1: 8%, P2: 4%",
          "P1: 8%, P2: 4%",
        ],
        [
          "Maximum Daily Loss",
          "4% ($200)",
          "4% ($400)",
          "4% ($1000)",
          "4% ($2000)",
          "4% ($4000)",
          "4% ($4000)",
          "4% ($4000)",
          "4% ($4000)",
        ],
        [
          "Maximum Overall Loss",
          "8% ($400)",
          "8% ($800)",
          "8% ($2000)",
          "8% ($4000)",
          "8% ($8000)",
          "8% ($8000)",
          "8% ($8000)",
          "8% ($8000)",
        ],
        [
          "Minimum Trading Days",
          "5 Days",
          "5 Days",
          "5 Days",
          "5 Days",
          "5 Days",
          "5 Days",
          "5 Days",
          "5 Days",
        ],
      ],
    },
    {
      heading: [
        { head: "Precious Metals" }
      ],
      paragraph: [
        { para: "The 'Spread' and 'Commission' columns are provided for reference only." }
      ],
      headerData: [
        { label: "Symbol" },
        { label: "Description" },
        { label: "Contract Size" },
        { label: "Commission" },
        { label: "Spread" },
        { label: "Min volume per click" },
        { label: "Max volume per click" },
        { label: "Leverage" },
        { label: "Trading hours (GMT+2)" },
      ],
      tableData: [
        [
          "Profit Target",
          "P1: 10%, P2: 5%",
          "P1: 10%, P2: 5%",
          "P1: 10%, P2: 5%",
          "P1: 10%, P2: 5%",
          "P1: 10%, P2: 5%",
          "P1: 10%, P2: 5%",
          "P1: 10%, P2: 5%",
          "P1: 10%, P2: 5%",
        ],
        [
          "Time Limit",
          "P1: 4 weeks, P2: 8 weeks",
          "P1: 4 weeks, P2: 8 weeks",
          "P1: 4 weeks, P2: 8 weeks",
          "P1: 4 weeks, P2: 8 weeks",
          "P1: 4 weeks, P2: 8 weeks",
          "P1: 4 weeks, P2: 8 weeks",
          "P1: 4 weeks, P2: 8 weeks",
          "P1: 4 weeks, P2: 8 weeks",
        ],
        [
          "Maximum Daily Loss",
          "5% ($300)",
          "5% ($750)",
          "5% ($1,250)",
          "5% ($2,500)",
          "5% ($5,000)",
          "5% ($10,000)",
          "5% ($10,000)",
          "5% ($10,000)",
        ],
        [
          "Maximum Overall Loss",
          "10% ($600)",
          "10% ($1,500)",
          "10% ($2,500)",
          "10% ($5,000)",
          "10% ($10,000)",
          "10% ($20,000)",
          "10% ($20,000)",
          "10% ($20,000)",
        ],
      ],
    },
    {
      heading: [
        { head: "Soft Commodities" }
      ],
      paragraph: [
        { para: "The 'Spread' and 'Commission' columns are provided for reference only." }
      ],
      headerData: [
        { label: "Symbol" },
        { label: "Description" },
        { label: "Contract Size" },
        { label: "Commission" },
        { label: "Spread" },
        { label: "Min volume per click" },
        { label: "Max volume per click" },
        { label: "Leverage" },
        { label: "Trading hours (GMT+2)" },
      ],
      tableData: [
        [
          "Maximum Daily Loss",
          "5% ($300)",
          "5% ($750)",
          "5% ($1,250)",
          "5% ($2,500)",
          "5% ($5,000)",
          "5% ($10,000)",
          "5% ($10,000)",
          "5% ($10,000)",
        ],
        [
          "Maximum Overall Loss",
          "10% ($600)",
          "10% ($1,500)",
          "10% ($2,500)",
          "10% ($5,000)",
          "10% ($10,000)",
          "10% ($20,000)",
          "10% ($20,000)",
          "10% ($20,000)",
        ],
        [
          "Minimum Trading Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
        ],
        [
          "Minimum Trading Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
        ],
      ],
    },
    {
      heading: [
        { head: "Energy" }
      ],
      paragraph: [
        { para: "The 'Spread' and 'Commission' columns are provided for reference only." }
      ],
      headerData: [
        { label: "Symbol" },
        { label: "Description" },
        { label: "Contract Size" },
        { label: "Commission" },
        { label: "Spread" },
        { label: "Min volume per click" },
        { label: "Max volume per click" },
        { label: "Leverage" },
        { label: "Trading hours (GMT+2)" },
      ],
      tableData: [
        [
          "Maximum Daily Loss",
          "5% ($300)",
          "5% ($750)",
          "5% ($1,250)",
          "5% ($2,500)",
          "5% ($5,000)",
          "5% ($10,000)",
          "5% ($10,000)",
          "5% ($10,000)",
        ],
        [
          "Maximum Overall Loss",
          "10% ($600)",
          "10% ($1,500)",
          "10% ($2,500)",
          "10% ($5,000)",
          "10% ($10,000)",
          "10% ($20,000)",
          "10% ($20,000)",
          "10% ($20,000)",
        ],
        [
          "Minimum Trading Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
        ],
        [
          "First Reward",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
        ],
      ],
    },
    {
      heading: [
        { head: "ETFs" }
      ],
      paragraph: [
        { para: "The 'Spread' and 'Commission' columns are provided for reference only." }
      ],
      headerData: [
        { label: "Symbol" },
        { label: "Description" },
        { label: "Contract Size" },
        { label: "Commission" },
        { label: "Spread" },
        { label: "Min volume per click" },
        { label: "Max volume per click" },
        { label: "Leverage" },
        { label: "Trading hours (GMT+2)" },
      ],
      tableData: [
        [
          "Maximum Daily Loss",
          "5% ($300)",
          "5% ($750)",
          "5% ($1,250)",
          "5% ($2,500)",
          "5% ($5,000)",
          "5% ($10,000)",
          "5% ($10,000)",
          "5% ($10,000)",
        ],
        [
          "Maximum Overall Loss",
          "10% ($600)",
          "10% ($1,500)",
          "10% ($2,500)",
          "10% ($5,000)",
          "10% ($10,000)",
          "10% ($20,000)",
          "10% ($20,000)",
          "10% ($20,000)",
        ],
        [
          "Minimum Trading Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
        ],
        [
          "First Reward",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
        ],
      ],
    },
    {
      heading: [
        { head: "Share CFDs" }
      ],
      paragraph: [
        { para: "The 'Spread' and 'Commission' columns are provided for reference only." }
      ],
      headerData: [
        { label: "Symbol" },
        { label: "Description" },
        { label: "Contract Size" },
        { label: "Commission" },
        { label: "Spread" },
        { label: "Min volume per click" },
        { label: "Max volume per click" },
        { label: "Leverage" },
        { label: "Trading hours (GMT+2)" },
      ],
      tableData: [
        [
          "Maximum Daily Loss",
          "5% ($300)",
          "5% ($750)",
          "5% ($1,250)",
          "5% ($2,500)",
          "5% ($5,000)",
          "5% ($10,000)",
          "5% ($10,000)",
          "5% ($10,000)",
        ],
        [
          "Maximum Overall Loss",
          "10% ($600)",
          "10% ($1,500)",
          "10% ($2,500)",
          "10% ($5,000)",
          "10% ($10,000)",
          "10% ($20,000)",
          "10% ($20,000)",
          "10% ($20,000)",
        ],
        [
          "Minimum Trading Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
        ],
        [
          "First Reward",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
        ],
      ],
    },
    {
      heading: [
        { head: "Bonds" }
      ],
      paragraph: [
        { para: "The 'Spread' and 'Commission' columns are provided for reference only." }
      ],
      headerData: [
        { label: "Symbol" },
        { label: "Description" },
        { label: "Contract Size" },
        { label: "Commission" },
        { label: "Spread" },
        { label: "Min volume per click" },
        { label: "Max volume per click" },
        { label: "Leverage" },
        { label: "Trading hours (GMT+2)" },
      ],
      tableData: [
        [
          "Maximum Daily Loss",
          "5% ($300)",
          "5% ($750)",
          "5% ($1,250)",
          "5% ($2,500)",
          "5% ($5,000)",
          "5% ($10,000)",
          "5% ($10,000)",
          "5% ($10,000)",
        ],
        [
          "Maximum Overall Loss",
          "10% ($600)",
          "10% ($1,500)",
          "10% ($2,500)",
          "10% ($5,000)",
          "10% ($10,000)",
          "10% ($20,000)",
          "10% ($20,000)",
          "10% ($20,000)",
        ],
        [
          "Minimum Trading Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
          "10 Days",
        ],
        [
          "First Reward",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
          "Monthly",
        ],
      ],
    },
  ];

  return (
    <>
      <Header />
      <section className="section_space contact_us">
        <div className="container">
          <div className="row align-items-center text-center justify-content-center">
            <div className="col-lg-9 text-section">
              <h2 className="main_heading">
                <span>All Trading Instruments</span>
              </h2>
              <p className="font_16">
                Explore our extensive range of products available for you to trade.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section_space">
        <div className="container">
          {/* Tab Navigation */}
          <ul className="nav nav-tabs justify-content-center mb-5 border-0 flex-wrap gap-2 pt-2">
            <li className={`nav-item nav-link tabs_back text-dark ${activeTab === "forex" ? "active" : ""}`}
              onClick={() => setActiveTab("forex")}>
              <div>
                <img src="assets/images/instruments/tab1.svg" alt="" className="img_tab" />
                <div className="d-flex align-items-center justify-content-center">
                  <p className="font_14">
                    Forex
                  </p>
                </div>
              </div>
            </li>
            <li className={`nav-item nav-link tabs_back text-dark ${activeTab === "indices" ? "active" : ""}`}
              onClick={() => setActiveTab("indices")}>
              <div>
                <img src="assets/images/instruments/tab2.svg" alt="" className="img_tab" />
                <div className="d-flex align-items-center justify-content-center">
                  <p className="font_14">
                    Indices
                  </p>
                </div>
              </div>
            </li>
            <li className={`nav-item nav-link tabs_back text-dark ${activeTab === "metals" ? "active" : ""}`}
              onClick={() => setActiveTab("metals")}>
              <div>
                <img src="assets/images/instruments/tab3.svg" alt="" className="img_tab"/>
                <div className="d-flex align-items-center justify-content-center">
                  <p className="font_14">
                    Precious Metals
                  </p>
                </div>
              </div>
            </li>
            <li className={`nav-item nav-link tabs_back text-dark ${activeTab === "commodities" ? "active" : ""}`}
              onClick={() => setActiveTab("commodities")}>
              <div>
                <img src="assets/images/instruments/tab4.svg" alt="" className="img_tab"/>
                <div className="d-flex align-items-center justify-content-center">
                  <p  className="font_14">
                    Soft Commodities
                  </p>
                </div>
              </div>
            </li>
            <li className={`nav-item nav-link tabs_back text-dark ${activeTab === "energy" ? "active" : ""}`}
              onClick={() => setActiveTab("energy")}>
              <div>
                <img src="assets/images/instruments/tab5.svg" alt="" className="img_tab"/>
                <div className="d-flex align-items-center justify-content-center">
                  <p className="font_14">
                    Energy
                  </p>
                </div>
              </div>
            </li>
            <li className={`nav-item nav-link tabs_back text-dark ${activeTab === "etfs" ? "active" : ""}`}
              onClick={() => setActiveTab("etfs")}>
              <div>
                <img src="assets/images/instruments/tab6.svg" alt="" className="img_tab" />
                <div className="d-flex align-items-center justify-content-center">
                  <p className="font_14">
                    ETFs
                  </p>
                </div>
              </div>
            </li>
            <li className={`nav-item nav-link tabs_back text-dark ${activeTab === "bonds" ? "active" : ""}`}
              onClick={() => setActiveTab("bonds")}>
              <div>
                <img src="assets/images/instruments/tab7.svg" alt="" className="img_tab"/>
                <div className="d-flex align-items-center justify-content-center">
                  <p className="font_14">
                    Share CFDs
                  </p>
                </div>
              </div>
            </li>
            <li className={`nav-item nav-link tabs_back text-dark ${activeTab === "bond" ? "active" : ""}`}
              onClick={() => setActiveTab("bond")}>
              <div>
                <img src="assets/images/instruments/tab8.svg" alt="" className="img_tab"/>
                <div className="d-flex align-items-center justify-content-center">
                  <p className="font_14">
                    Bonds
                  </p>
                </div>
              </div>
            </li>
          </ul>
          {/* Tab Content */}
          {tabsData.map((tab, index) => {
            const tabName = ["forex", "indices", "metals", "commodities", "energy", "etfs", "bonds", "bond"][index]; // Map tab index to the correct tab name
        
            return activeTab === tabName && (
              <div>
                <div className="d-xl-flex justify-content-xl-between align-items-center">
                  <div className="d-flex align-items-center gap-2">
                    <img src="assets/images/instruments/table1.svg" alt="table1" className="tab1 mb-1" />
                    {tab.heading.map((header, headerIndex) => (
                      <h2 key={headerIndex} className="sub_heading">
                        {header.head}
                      </h2>
                    ))}
                  </div>
                  <div className="d-flex align-items-center gap-1 mb-xl-0 mb-2">
                    <input
                      type="text"
                      placeholder="Enter text"
                      className="form-control instrument_btn"
                    />
                    <button className="btn btn_blue px-lg-5 px-2 table_button font_16">SEARCH</button>
                  </div>
                </div>
                <div className="mt-lg-0 mt-3">{tab.paragraph.map((header, headerIndex) => (
                  <p key={headerIndex} className="font_16">
                    {header.para}
                  </p>
                ))}</div>
                <div className="table-responsive table_shadow">
                  <table className="table table-round container simple-pricing-table pricing-table p-0 my-0 text-nowrap" key={tabName}>
                    <thead>
                      <tr className="font_16 text-wrap">
                        {tab.headerData.map((header, headerIndex) => (
                          <th key={headerIndex} className="font_16">
                            {header.label}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {tab.tableData.map((row, rowIndex) => (
                        <tr className="design font_16" key={rowIndex}>
                          {row.map((cell, cellIndex) => (
                            <td className="design_right" key={cellIndex}>
                              {cell === true ? "yes" : cell === false ? "no" : cell}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            );
          })}
          
        </div>
      </section>

      <section className="section_space pt-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="trad_bg">
                <div className="title text-center mb-lg-5 mb-4">
                  <h2 className="sub_heading">Try Any of Our Trading Accounts</h2>
                </div>
                <div className="d-flex justify-content-center gap-3 align-items-center flex-wrap flex-md-nowrap flex-column flex-md-row">
                  <a href="#/" className="btn btn_blue px-lg-5 fw-semibold font_16 trade_btn">TRADE NOW</a>
                  <a href="#/" className="btn btn_blue px-lg-5 fw-semibold font_16 trade_btn">LEARN TO TRADE</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
