import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";


export default function WhyTradsify() {
    const cardsData = [
        {
            title: "Persistently Trusted",
            description:
                "Tradsify fully complies with the strict financial regulations set out by the jurisdictions, rest assured to trade with the safest trading broker.",
        },
        {
            title: "Highly Efficient",
            description:
                "Tradsify is a nimble trade platform that provided trading of multiple assets trading.",
        },
        {
            title: "Simply Intuitive",
            description:
                "Get all market opportunities by trading forex, commodities, indices, share CFDs and more.",
        },
        {
            title: "Seamlessly Connected",
            description:
                "Trade anytime, anywhere with our trading tools.",
        },
        {
            title: "Human-Centric",
            description:
                "Tradsify provided user-friendly apps and platforms for clients.",
        },
    ];
    const bgColors = ["#027ec8", "#000", "#027ec8", "#000", "#027ec8"];

    return (
        <>
            <ScrollToTop>
                <Header />
                <section className="section_space about_view_hero">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 class="main_heading text-center text-white">Always One Step Ahead <br /> With Tradsify</h2>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space">
                    <div className="container">
                        <div className="row justify-content-center text-center">
                            <div className="col-lg-7">
                                <div className="main_market">
                                    <h2 class="sub_heading text-center mb-4">WHO IS TRADSIFY</h2>
                                    <p className="font_16">At Tradsify, we hold ourselves to the highest security measures and operational standards. You can invest with confidence knowing that we uphold industry-leading practices to safeguard your investments.</p>
                                    <p className="font_16">Our trading platform and app provide access to a diverse range of over 1,000 CFD products, ensuring simplicity and intuitive use for traders on the move. We focus on delivering ultra-fast execution and reliable performance, complemented by round-the-clock customer service support. These features enhance your trading experience with enhanced reliability and convenience.</p>
                                    <p className="font_16">Trade smarter @tradsify.</p>
                                    <div className="about_value d-flex justify-content-center text-center gap-5 mt-4 mb-4">
                                        <div className="about_number">
                                            <p className="roll sub_heading m-0">1,000+</p>
                                            <p className="font_16 text-center">CFD Products <br /> Available</p>
                                        </div>
                                    </div>
                                    {/* <div className="d-flex justify-content-center">
                                        <Link to="" className="btn btn_blue fw-semibold font_16 z-0">MORE ON TRADSIFY
                                        </Link>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space text-center bg-ofwhite">
                    <div className="container">
                        <div className="row my-xl-0 my-3 overflow-hidden">
                            <div className="col-lg-12 h-100">
                                <div className="my-xl-0 mt-lg-5 mt-0 my-4  wow animate__animated animate__zoomIn">
                                    <Swiper
                                        modules={[Navigation, Pagination, Autoplay]}
                                        spaceBetween={25}
                                        slidesPerView={4}
                                        navigation={{
                                            nextEl: ".swiper-button-leader-next",
                                            prevEl: ".swiper-button-leader-prev",
                                        }}
                                        loop={true}
                                        autoplay={{
                                            delay: 8000,
                                        }}
                                        breakpoints={{
                                            1440: {
                                                slidesPerView: 4,
                                            },
                                            1024: {
                                                slidesPerView: 3,
                                            },
                                            600: {
                                                slidesPerView: 2,
                                            },
                                            0: {
                                                slidesPerView: 1,
                                            },
                                        }}
                                    >
                                        {cardsData.map((card, index) => (
                                            <>
                                                {cardsData.map((card, index) => (
                                                    <SwiperSlide key={index}>
                                                        <div className="About_cards text-white" style={{ backgroundColor: bgColors[index % bgColors.length] }}>
                                                            <h3 className="sub_small_heading mb-lg-4 mb-2">
                                                                {card.title}
                                                            </h3>
                                                            <p className="font_15">{card.description}</p>
                                                        </div>
                                                    </SwiperSlide>
                                                ))}
                                            </>
                                        ))}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space">
                    <div className="container">
                        <div className="row justify-content-center g-4">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="aoverview_card_main text-center">
                                    <div className="aoverview_card_img">
                                        <img src="assets/images/aboutoverview/card1.svg" alt="" />
                                    </div>
                                    <div className="aoverview_card_text">
                                        <p className="font_20 aoverview_title">ESG</p>
                                        <p className="font_16">Tradsify is deeply committed to social responsibility. Our charity arm, the Tradsify Foundation, extends a helping hand to the less privileged and works towards enriching communities through proactive initiatives.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="aoverview_card_main text-center">
                                    <div className="aoverview_card_img">
                                        <img src="assets/images/aboutoverview/card2.svg" alt="" />
                                    </div>
                                    <div className="aoverview_card_text">
                                        <p className="font_20 aoverview_title">Press Releases</p>
                                        <p className="font_16">Stay informed about Tradsify's newest product releases, promotions, partnerships, and more right here.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="aoverview_card_main text-center">
                                    <div className="aoverview_card_img">
                                        <img src="assets/images/aboutoverview/card3.svg" alt="" />
                                    </div>
                                    <div className="aoverview_card_text">
                                        <p className="font_20 aoverview_title">Media Centre</p>
                                        <p className="font_16">Access all our brand-related materials, media features, announcements, and other resources here.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space about_confidenc">
                    <div className="container">
                        <div className="row align-items-center g-3">
                            <div className="col-lg-6">
                                <div className="col-lg-10">
                                    <h2 class="sub_heading">Trade Tradsify Markets with Confidence</h2>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="confidence_img mt-5">
                                            <img src="assets/images/aboutoverview/confidence-icon1.svg" alt="" />
                                        </div>
                                        <div className="confidence_text">
                                            <p className="font_20 fw-bold mt-3">Diversification</p>
                                            <p className="font_16">Trade Forex, Indices, Commodities, Shares, ETFs, Metals, and Bonds with ultra-low spreads.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="confidence_img mt-5">
                                            <img src="assets/images/aboutoverview/confidence-icon2.svg" alt="" />
                                        </div>
                                        <div className="confidence_text">
                                            <p className="font_20 fw-bold mt-3">A Fully Regulated Provider</p>
                                            <p className="font_16">Since 2024, we've offered a transparent trading environment, ensuring fund safety through segregated accounts with top-tier banks, audits, and indemnity insurance.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                <div className="col-lg-6 col-md-6">
                                        <div className="confidence_img mt-5">
                                            <img src="assets/images/aboutoverview/confidence-icon3.svg" alt="" />
                                        </div>
                                        <div className="confidence_text">
                                            <p className="font_20 fw-bold mt-3">Low Spreads</p>
                                            <p className="font_16">Enjoy competitive spreads from as low as 0.0 pips.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="confidence_img mt-5">
                                            <img src="assets/images/aboutoverview/confidence-icon5.svg" alt="" />
                                        </div>
                                        <div className="confidence_text">
                                            <p className="font_20 fw-bold mt-3">Fast Order Execution</p>
                                            <p className="font_16">We rely on high capacity, fibre-optic network and global servers connecting its trading terminals to advanced smart order routing and aggregation engines.</p>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="col-lg-6">
                                    <Link to="" className="btn btn_blue fw-semibold font_16 hero_copytrading_btn z-0">OPEN LIVE ACCOUNT
                                        <span><img src="assets/images/copytrading/hero-btn-icon.svg" alt="" className="hero_btn_icon" /></span>
                                    </Link>
                                </div>

                            </div>
                            <div className="col-lg-6">
                                <div>
                                    <img src="assets/images/aboutoverview/about-confidence-banner.webp" alt="" className="w-100"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </ScrollToTop>
        </>
    )
}