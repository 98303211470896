export default function Comparison() {
  return (
    <section className="section_space">
      <div className="container">
        <h2 className="sub_heading text-center mb-xl-5 mb-3">
          Trading Account Comparison
        </h2>
        <div className="trading-features-content animates animate__zoomIn">
          <div className="trading-features-left">
            <div className="trading-features-thead" />
            <div className="table-first-row font_14">
              <p className="mb-0">Minimum Deposit</p>
            </div>
            <div className="table-cell">
              <p className="mb-0">  
                <a href="" className="text-black">
                  Commission
                </a>{" "}
                / per lot per side
              </p>
            </div>
            <div className="table-cell">
              <p className="mb-0">
                <span className="font_20">Free</span> Access to Tradsify
                ProTrader Platform
              </p>
            </div>
            <div className="table-cell">
              <p className="mb-0">Competitve Prices</p>
            </div>
            <div className="table-cell">
              <p className="mb-0">Exclusive Expert Support</p>
            </div>
            <div className="table-cell">
              <p className="mb-0">
                <span className="font_20">Free</span> Technical Analysis
              </p>
            </div>
            <a href="" />
          </div>
          <div className="trading-features-right font_14">
            <div className="trading-features-swiper swiper-initialized swiper-horizontal swiper-backface-hidden overflow-x-auto">
              <div
                className="swiper-wrapper table_auto"
                id="swiper-wrapper-3fa84d59b50db090"
                aria-live="polite"
              >
                <div
                  className="trading-features-right-item-box trading-features-right-item-raw"
                  role="group"
                  aria-label="1 / 4"
                >
                  <div className="trading-features-right-item">
                    <div className="trading-features-thead">
                      <div className="trading-popular">
                        <p>Popular</p>
                      </div>{" "}
                      <a className="trading-features-thead-content" href="">
                        <div className="trading-features-thead-img mb-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Group_17830"
                            data-name="Group 17830"
                            width={154}
                            height={21}
                            viewBox="0 0 92.131 21"
                            data-inject-url="https://www.Tradsifymarkets.com/wp-content/themes/Tradsify/images/trading-accounts-rawecn1.svg?v=3"
                            className="svg-inject-img"
                          >
                            <g
                              id="Group_14070--inject-21"
                              data-name="Group 14070"
                              transform="translate(56.358 4.49)"
                            >
                              <path
                                id="Union_30--inject-21"
                                data-name="Union 30"
                                d="M4.754,16.51V16.3l-.017.212H0L4.754,3.579V3.046A2.993,2.993,0,0,1,5.78.787L6.07,0,6.021.6A2.986,2.986,0,0,1,7.753.047H35.772V16.51Z"
                                transform="translate(0 0)"
                                fill="red"
                              />
                            </g>
                            <path
                              className="svg-change-img"
                              id="PRO--inject-21"
                              d="M17.81,7.244A6.074,6.074,0,0,0,11.853,0H3.682L0,20.953H4.131l1.287-7.274H8.86l2.9,7.274h4.191l-3.173-7.812A7.633,7.633,0,0,0,17.81,7.244Zm-4.1-.3A3.605,3.605,0,0,1,10.3,10.057H6.046l1.078-6.2h4.041A2.468,2.468,0,0,1,13.709,6.944ZM31.879,20.953h4.46L32.657,0H27.538L16.493,20.953h4.52l1.946-3.772H31.34ZM24.964,13.32l4.55-8.74,1.257,8.74Zm16.254,7.633h4.7L52.263,7.124l1.527,13.829h4.7L68.038,0h-4.46L56.813,15.535,55.136,0H51.6L44.48,15.535,43.133,0h-4.1Z"
                              transform="translate(0 0)"
                            />
                            <path
                              id="Lorem_ipsum_dolor_sit_amet_consectetur_adipiscing_elit_eiusmod_tempor_incididunt_ut_labore_et_dolore_maLorem_ipsum_dolor_sit_amet_consectetur_adipi--inject-21"
                              data-name="Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt ut labore et dolore maLorem ipsum dolor sit amet, consectetur adipi"
                              d="M5.689,1.573,5.937.151H1.325L0,7.693H4.665l.248-1.422H1.735l.3-1.692H4.881l.259-1.39H2.273l.291-1.616ZM9.3,7.844a4.373,4.373,0,0,0,3.5-1.767L11.583,5.29A2.59,2.59,0,0,1,9.4,6.389,1.9,1.9,0,0,1,7.467,3.922a2.873,2.873,0,0,1,2.845-2.478,1.832,1.832,0,0,1,1.8,1.056l1.358-.711A3.115,3.115,0,0,0,10.452,0,4.531,4.531,0,0,0,5.991,3.814,3.257,3.257,0,0,0,9.3,7.844Zm4.127-.151h1.487l.83-4.709,2.414,4.709h1.121L20.6.151H19.114L18.3,4.859,15.871.151H14.751Z"
                              transform="translate(66.433 8.98)"
                              fill="#fff"
                            />
                          </svg>
                        </div>
                        <span className="font_14 fw-normal text-white">
                          Tight spreads & <br />
                          lowest commissions
                        </span>
                        <br />
                        <div className="trading-thead-redirect text-white mt-2 ">
                          <p className="trading-features-thead-spreads mt-2">
                            0.0 <span>pip</span>
                          </p>
                          <span className="arrow">
                            <img
                              src="assets/images/trading/rightarrow.svg"
                              alt="Trading"
                            />
                          </span>
                        </div>
                      </a>
                    </div>
                    <div className="table-first-row font_14">
                      <p>$ 50</p>
                    </div>
                    <div className="table-cell">
                      <p>From $3.00</p>
                    </div>
                    <div className="table-cell">
                      <p>
                        <img src="assets/images/trading/yes.svg" alt="" />
                      </p>
                    </div>
                    <div className="table-cell">
                      <p>
                        <img src="assets/images/trading/yes.svg" alt="" />
                      </p>
                    </div>
                    <div className="table-cell">
                      <p>
                        <img src="assets/images/trading/yes.svg" alt="" />
                      </p>
                    </div>
                    <div className="table-cell">
                      <p>
                        <img src="assets/images/trading/yes.svg" alt="" />
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="trading-features-right-item-box trading-features-right-item-standard swiper-slide swiper-slide-next"
                  role="group"
                  aria-label="2 / 4"
                  style={{ width: 230 }}
                >
                  <div className="trading-features-right-item">
                    <div className="trading-features-thead">
                      <a className="trading-features-thead-content" href="">
                        <div className="trading-features-thead-img mb-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width={154}
                            height={21}
                            viewBox="0 0 213.334 29.279"
                            data-inject-url="https://www.Tradsifymarkets.com/wp-content/themes/Tradsify/images/trading-accounts-standardecn.svg?v=3"
                            className="svg-inject-img"
                          >
                            <g
                              id="Group_17827--inject-22"
                              data-name="Group 17827"
                              transform="translate(-1379.93 -1354.899)"
                            >
                              <g
                                className="svg-change-img"
                                id="Group_17827-2--inject-22"
                                data-name="Group 17827"
                              >
                                <path
                                  id="Path_68819--inject-22"
                                  data-name="Path 68819"
                                  d="M21.945.513l-.9,5.222h7.424L24.517,28.191h5.451L33.914,5.735h7.478L42.308.513Z"
                                  transform="translate(1379.896 1355.487)"
                                  stroke="rgba(0,0,0,0)"
                                  strokeWidth={1}
                                />
                                <path
                                  id="Path_68820--inject-22"
                                  data-name="Path 68820"
                                  d="M52.718.51H45.96L31.374,28.188h5.963l2.573-4.982H50.974l.719,4.982H57.58ZM42.559,18.1,48.565,6.56,50.222,18.1Z"
                                  transform="translate(1380.827 1355.49)"
                                  stroke="rgba(0,0,0,0)"
                                  strokeWidth={1}
                                />
                                <path
                                  id="Path_68821--inject-22"
                                  data-name="Path 68821"
                                  d="M76.29.5,73.281,17.783,64.386.5H60.265L55.414,28.183h5.451L63.906,10.9l8.852,17.278h4.121L81.74.5Z"
                                  transform="translate(1381.903 1355.495)"
                                  stroke="rgba(0,0,0,0)"
                                  strokeWidth={1}
                                />
                                <path
                                  id="Path_68822--inject-22"
                                  data-name="Path 68822"
                                  d="M94.226.51H83.238L78.365,28.188h11a15.965,15.965,0,0,0,15.763-13.441C106.523,6.953,101.5.51,94.226.51m5.69,13.681c-.9,5.1-4.862,8.775-9.964,8.775H84.731L87.784,5.732H93.31c4.786,0,7.478,3.358,6.606,8.459"
                                  transform="translate(1382.881 1355.49)"
                                  stroke="rgba(0,0,0,0)"
                                  strokeWidth={1}
                                />
                                <path
                                  id="Path_68823--inject-22"
                                  data-name="Path 68823"
                                  d="M120.217.51h-6.759L98.873,28.188h5.963l2.573-4.982h11.076l.709,4.982h5.887ZM110.058,18.1,116.075,6.56,117.732,18.1Z"
                                  transform="translate(1383.639 1355.49)"
                                  stroke="rgba(0,0,0,0)"
                                  strokeWidth={1}
                                />
                                <path
                                  id="Path_68824--inject-22"
                                  data-name="Path 68824"
                                  d="M148.022,10.081A8.019,8.019,0,0,0,141.7.663,8.189,8.189,0,0,0,140.151.51H129.359L124.5,28.188h5.451l1.7-9.615h4.546l3.837,9.615h5.538l-4.2-10.323a10.089,10.089,0,0,0,6.65-7.783m-5.418-.4a4.766,4.766,0,0,1-4.5,4.121h-5.614L133.9,5.612h5.342A3.258,3.258,0,0,1,142.7,8.664a3.177,3.177,0,0,1-.1,1.014"
                                  transform="translate(1383.768 1355.49)"
                                  stroke="rgba(0,0,0,0)"
                                  strokeWidth={1}
                                />
                                <path
                                  id="Path_68825--inject-22"
                                  data-name="Path 68825"
                                  d="M162.065.51H151.077l-4.862,27.678H157.2a15.988,15.988,0,0,0,15.785-13.441C174.362,6.953,169.336.51,162.065.51m5.69,13.681c-.9,5.1-4.862,8.775-9.953,8.775h-5.222l3.041-17.235h5.538c4.786,0,7.467,3.358,6.6,8.459"
                                  transform="translate(1383.545 1355.49)"
                                  stroke="rgba(0,0,0,0)"
                                  strokeWidth={1}
                                />
                                <g
                                  id="Group_17829--inject-22"
                                  data-name="Group 17829"
                                  transform="translate(1379.93 1354.899)"
                                  clipPath="url(#clip-path--inject-22)"
                                >
                                  <path
                                    id="Path_72061--inject-22"
                                    data-name="Path 72061"
                                    d="M13.8,12.057c-3.085-1.1-5.134-2.017-4.7-4.153.349-1.581,1.853-2.649,4.11-2.649A4.933,4.933,0,0,1,18.15,8.7L22.936,6.17C21.551,2.333,18.227,0,13.648,0,9.179,0,4.513,2.289,3.565,7.587c-.948,5.342,2.845,7.478,7.151,8.939,3.161,1.068,5.374,1.908,4.905,4.121-.316,1.613-1.9,2.878-4.905,2.878-3.434,0-5.178-1.7-5.767-4.11L0,21.944c.992,4.11,4.557,6.835,10.28,6.835,5.658,0,10.007-2.605,10.912-7.663,1.068-5.93-3.477-7.674-7.391-9.059"
                                    transform="translate(0 0)"
                                    stroke="rgba(0,0,0,0)"
                                    strokeWidth={1}
                                  />
                                </g>
                              </g>
                              <g
                                id="standard_stp_btn--inject-22"
                                data-name="standard stp / btn"
                                transform="translate(1378 1355.719)"
                              >
                                <g
                                  id="Group_14026--inject-22"
                                  data-name="Group 14026"
                                  transform="translate(174.963 6.304)"
                                >
                                  <rect
                                    id="Rectangle_5608--inject-22"
                                    data-name="Rectangle 5608"
                                    width="40.334"
                                    height={22}
                                    transform="translate(-0.033 -0.023)"
                                    fill="red"
                                  />
                                  <path
                                    id="Lorem_ipsum_dolor_sit_amet_consectetur_adipiscing_elit_eiusmod_tempor_incididunt_ut_labore_et_dolore_maLorem_ipsum_dolor_sit_amet_consectetur_adipi--inject-22"
                                    data-name="Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt ut labore et dolore maLorem ipsum dolor sit amet, consectetur adipi"
                                    d="M4.1,1.119c2.183,0,3.863-1.008,4.214-2.962.412-2.29-1.344-2.962-2.855-3.5-1.191-.427-1.985-.779-1.817-1.6A1.464,1.464,0,0,1,5.225-7.965,1.907,1.907,0,0,1,7.134-6.637l1.847-.977A3.579,3.579,0,0,0,5.393-10,3.687,3.687,0,0,0,1.5-7.065C1.134-5,2.6-4.179,4.263-3.614c1.221.412,2.076.733,1.893,1.588C6.034-1.4,5.424-.912,4.263-.912A2.037,2.037,0,0,1,2.034-2.5L.126-1.523C.508.065,1.882,1.119,4.1,1.119Zm13.725-10.9H9.958L9.607-7.767h2.87L10.95.9h2.107l1.527-8.672H17.47Zm5.008,0H19L17.118.9h2.107l.611-3.511h1.786a4.077,4.077,0,0,0,4.351-3.481A3.022,3.022,0,0,0,22.828-9.782ZM23.882-6.24a1.953,1.953,0,0,1-2.046,1.664H20.187l.565-3.237h1.756C23.622-7.813,24.065-7.172,23.882-6.24Z"
                                    transform="translate(6.841 15.415)"
                                    fill="#f5f5f5"
                                  />
                                </g>
                              </g>
                            </g>
                          </svg>
                        </div>
                        <span className="trading-features-thead-introduction">
                          No commission
                          <br /> trades
                        </span>
                        <br />
                        <div className="trading-thead-redirect">
                          <p className="trading-features-thead-spreads mt-2">
                            1.1 <span>pip</span>
                          </p>
                          <span className="arrow">
                            <img
                              src="assets/images/trading/rightarrow.svg"
                              alt="Trading"
                            />
                          </span>
                        </div>
                      </a>
                    </div>
                    <div className="table-first-row font_14">
                      <p>$ 50</p>
                    </div>
                    <div className="table-cell">
                      <p>$ 0</p>
                    </div>
                    <div className="table-cell">
                      <p>
                        <span>
                          Available for clients who meet the requirements
                        </span>
                      </p>
                    </div>
                    <div className="table-cell">
                      <p>
                        <span>Refer to account type description</span>
                      </p>
                    </div>
                    <div className="table-cell">
                      <p>
                        <img src="assets/images/trading/wrong.svg" alt="" />
                      </p>
                    </div>
                    {/* <div class="table-cell"><p></p></div> */}
                    <div className="table-cell">
                      <p>
                        <span>
                          Available for clients who meet the requirements
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="trading-features-right-item-box trading-features-right-item-pro swiper-slide"
                  role="group"
                  aria-label="3 / 4"
                  style={{ width: 230 }}
                >
                  <div className="trading-features-right-item">
                    <div className="trading-features-thead">
                      <a className="trading-features-thead-content" href="">
                        <div className="trading-features-thead-img mb-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="PRO_ECN"
                            data-name="PRO ECN"
                            width={154}
                            height={21}
                            viewBox="0 0 86.697 21"
                            data-inject-url="https://www.Tradsifymarkets.com/wp-content/themes/Tradsify/images/trading-accounts-proecn1.svg?v=3"
                            className="svg-inject-img"
                          >
                            <path
                              className="svg-change-img"
                              id="PRO--inject-24"
                              d="M10.788.4H3.548L0,20.6H3.981l1.154-6.635H8.51c4.673,0,7.673-2.8,8.221-6.577C17.336,3.462,14.913.4,10.788.4ZM12.779,7.1A3.689,3.689,0,0,1,8.913,10.24H5.8L6.865,4.125h3.317C12.288,4.125,13.125,5.337,12.779,7.1Zm21.4.288A5.854,5.854,0,0,0,28.442.4H20.567L17.019,20.6H21l1.24-7.01h3.317l2.8,7.01h4.038l-3.058-7.529A7.355,7.355,0,0,0,34.182,7.385ZM30.231,7.1a3.474,3.474,0,0,1-3.288,3h-4.1l1.038-5.971h3.894A2.378,2.378,0,0,1,30.231,7.1ZM44.106,21A12.124,12.124,0,0,0,56.279,10.817C57.317,4.933,53.336,0,47.567,0A12.149,12.149,0,0,0,35.394,10.211C34.327,16.1,38.25,21,44.106,21Zm.462-3.894c-3.692,0-5.913-2.74-5.221-6.606a7.847,7.847,0,0,1,7.615-6.635c3.692,0,6.029,2.8,5.365,6.663A7.92,7.92,0,0,1,44.567,17.106Z"
                              transform="translate(0 0)"
                            />
                            <g
                              id="Group_14026--inject-24"
                              data-name="Group 14026"
                              transform="translate(54.882 4.975)"
                            >
                              <rect
                                id="Rectangle_5608--inject-24"
                                data-name="Rectangle 5608"
                                width="31.815"
                                height="16.024"
                                transform="translate(0 0)"
                                fill="red"
                              />
                              <path
                                id="Lorem_ipsum_dolor_sit_amet_consectetur_adipiscing_elit_eiusmod_tempor_incididunt_ut_labore_et_dolore_maLorem_ipsum_dolor_sit_amet_consectetur_adipi--inject-24"
                                data-name="Lorem ipsum dolor sit amet, consectetur adipiscing elit, eiusmod tempor incididunt ut labore et dolore maLorem ipsum dolor sit amet, consectetur adipi"
                                d="M5.769,1.6,6.02.153H1.344L0,7.8H4.731l.251-1.442H1.759l.306-1.715H4.949l.262-1.409H2.305L2.6,1.6Zm3.66,6.359A4.434,4.434,0,0,0,12.98,6.162l-1.235-.8A2.626,2.626,0,0,1,9.527,6.479a1.927,1.927,0,0,1-1.956-2.5,2.913,2.913,0,0,1,2.884-2.513,1.858,1.858,0,0,1,1.825,1.071l1.377-.721A3.159,3.159,0,0,0,10.6,0,4.6,4.6,0,0,0,6.075,3.868,3.3,3.3,0,0,0,9.429,7.954ZM13.614,7.8h1.508l.841-4.775L18.41,7.8h1.136L20.89.153H19.382l-.83,4.775L16.094.153H14.957Z"
                                transform="translate(5.462 4.035)"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </div>
                        <span className="font_14 fw-normal">
                          Tight spreads &amp; <br /> lowest commissions
                        </span>
                        <br />
                        <div className="trading-thead-redirect">
                          <p className="trading-features-thead-spreads mt-2">
                            0.0 <span>pip</span>
                          </p>
                          <span className="arrow">
                            <img
                              src="assets/images/trading/rightarrow.svg"
                              alt="Trading"
                            />
                          </span>
                        </div>
                      </a>
                    </div>
                    <div className="table-first-row font_14">
                      <p>
                        $ 10,000 <br />
                        <span>
                          Refer to your assigned sales representative for terms
                          and conditions
                        </span>
                      </p>
                    </div>
                    <div className="table-cell">
                      <p>From $1.50</p>
                    </div>
                    <div className="table-cell">
                      <p>
                        <img src="assets/images/trading/yes.svg" alt="" />
                      </p>
                    </div>
                    <div className="table-cell">
                      <p>
                        <img src="assets/images/trading/yes.svg" alt="" />
                      </p>
                    </div>
                    <div className="table-cell">
                      <p>
                        <img src="assets/images/trading/yes.svg" alt="" />
                      </p>
                    </div>
                    <div className="table-cell">
                      <p>
                        <img src="assets/images/trading/yes.svg" alt="" />
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="trading-features-right-item-box trading-features-right-item-cent swiper-slide"
                  role="group"
                  aria-label="4 / 4"
                  style={{ width: 230 }}
                >
                  <div className="trading-features-right-item">
                    <div className="trading-features-thead">
                      <a className="trading-features-thead-content" href="">
                        <div className="trading-features-thead-img mb-3 mb-3">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={154}
                            height={21}
                            viewBox="0 0 71.926 21"
                            data-inject-url="https://www.Tradsifymarkets.com/wp-content/themes/Tradsify/images/trading-accounts-centecn.svg?v=3"
                            className="svg-inject-img"
                          >
                            <path
                              className="svg-change-img"
                              id="CENT--inject-25"
                              d="M9.054,21a11.761,11.761,0,0,0,9.408-4.731l-3.271-2.106a6.964,6.964,0,0,1-5.876,2.942c-3.734,0-5.876-2.712-5.181-6.606a7.71,7.71,0,0,1,7.642-6.635,4.923,4.923,0,0,1,4.834,2.827l3.647-1.9C18.925,1.875,16.117,0,12.152,0A12.161,12.161,0,0,0,.168,10.212C-.845,16.067,2.8,21,9.054,21ZM35.425,4.212,36.09.4H23.7L20.141,20.6H32.675l.666-3.808H24.8l.81-4.529h7.642l.695-3.721h-7.7l.782-4.327ZM35.483,20.6h3.995L41.706,7.99,48.19,20.6H51.2L54.761.4H50.766l-2.2,12.606L42.053.4h-3.01ZM71.926.4H57.019l-.666,3.808h5.442L58.9,20.6h3.995L65.789,4.212H71.26Z"
                              transform="translate(0 0)"
                            />
                          </svg>
                        </div>
                        <span className="font_14 fw-normal">
                          Small <br /> deposits
                        </span>
                        <br />
                        <div className="trading-thead-redirect">
                          <p className="trading-features-thead-spreads mt-2">
                            0.0 <span>pip</span>
                          </p>
                          <span className="arrow">
                            <img
                              src="assets/images/trading/rightarrow.svg"
                              alt="Trading"
                            />
                          </span>
                        </div>
                      </a>
                    </div>
                    <div className="table-first-row font_14">
                      <p>$ 50</p>
                    </div>
                    <div className="table-cell">
                      <p>From $0 - $3.00</p>
                    </div>
                    <div className="table-cell">
                      <p>
                        <img src="assets/images/trading/wrong.svg" alt="" />
                      </p>
                    </div>
                    <div className="table-cell">
                      <p>
                        <img src="assets/images/trading/wrong.svg" alt="" />
                      </p>
                    </div>
                    <div className="table-cell">
                      <p>
                        <img src="assets/images/trading/wrong.svg" alt="" />
                      </p>
                    </div>
                    <div className="table-cell">
                      <p>
                        <img src="assets/images/trading/wrong.svg" alt="" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <span
              className="swiper-notification"
              aria-live="assertive"
              aria-atomic="true"
            /> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
