import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link } from "react-router-dom";

export default function Markets() {
    return (
        <>
            <ScrollToTop>
                <Header />
                <section className="section_space market_herobg">
                    <div className="container">
                        <div className="row g-3">
                            <div className="col-12">
                                <h2 class="main_heading text-white">(FX) FOREX TRADING WITH TRADSIFY</h2>
                                <div className="markets_herotext">
                                    <p className="font_16 position-relative text-white">Open a forex trading account with Tradsify today and take adtradsify of the forex market.</p>
                                    <a class="btn btn_blue fw-semibold font_16 z-0" href="#/">TRADE NOW</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space">
                    <div className="container">
                        <div className="row g-4">
                            <div className="col-12">
                                <h2 class="sub_heading text-center mb-xl-5 mb-3">Why Trade Forex with Tradsify?</h2>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="market_cardbody text-center">
                                    <div class="market_cardimg mb-4">
                                        <img src="assets/images/markets/marketcard1.svg" alt="" />
                                    </div>
                                    <h3 className="sub_title">40+ Currency Pairs<br /> Available</h3>
                                    <span className="font_16 more">see more</span>
                                    <div className="mask-layer ">
                                        <span></span>
                                    </div>
                                    <div className="hover-text d-flex justify-content-center text-white">
                                        <p className="font_16">Trade forex with over 40+ currency pairs, including majors, minors and exotic pairs, through our liquid global foreign exchange markets.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="market_cardbody text-center">
                                    <div class="market_cardimg mb-4">
                                        <img src="assets/images/markets/marketcard2.svg" alt="" />
                                    </div>
                                    <h3 className="sub_title">Tight Spreads on  Tradsify Raw ECN  Accounts</h3>
                                    <span className="font_16 more">see more</span>
                                    <div className="mask-layer ">
                                        <span></span>
                                    </div>
                                    <div className="hover-text d-flex justify-content-center text-white">
                                        <p className="font_16">With competitive spreads from 0.0, trade the world's most traded forex pairs at minimum cost.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="market_cardbody text-center">
                                    <div class="market_cardimg mb-4">
                                        <img src="assets/images/markets/marketcard3.svg" alt="" />
                                    </div>
                                    <h3 className="sub_title">Trade Forex On The Go</h3>
                                    <span className="font_16 more">see more</span>
                                    <div className="mask-layer ">
                                        <span></span>
                                    </div>
                                    <div className="hover-text d-flex justify-content-center text-white">
                                        <p className="font_16">Buy and sell anytime. React swiftly to news on our trading platform and mobile app.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="market_cardbody text-center">
                                    <div class="market_cardimg mb-4">
                                        <img src="assets/images/markets/marketcard4.svg" alt="" />
                                    </div>
                                    <h3 className="sub_title">Low & Transparent<br /> Costs</h3>
                                    <span className="font_16 more">see more</span>
                                    <div className="mask-layer ">
                                        <span></span>
                                    </div>
                                    <div className="hover-text d-flex justify-content-center text-white">
                                        <p className="font_16">Explore the potential of taking both long and short positions across a variety of CFD products, starting from just $0 per trade. Learn about our competitive commission fees by visiting our "All Instruments" page.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="market_cardbody text-center">
                                    <div class="market_cardimg mb-4">
                                        <img src="assets/images/markets/marketcard5.svg" alt="" />
                                    </div>
                                    <h3 className="sub_title">Free Educational <br /> Materials</h3>
                                    <span className="font_16 more">see more</span>
                                    <div className="mask-layer ">
                                        <span></span>
                                    </div>
                                    <div className="hover-text d-flex justify-content-center text-white">
                                        <p className="font_16">Equip yourself with forex trading knowledge through free educational materials on our academy.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="market_cardbody text-center">
                                    <div class="market_cardimg mb-4">
                                        <img src="assets/images/markets/marketcard6.svg" alt="" />
                                    </div>
                                    <h3 className="sub_title">Trade Bull & <br /> Bear Markets</h3>
                                    <span className="font_16 more">see more</span>
                                    <div className="mask-layer ">
                                        <span></span>
                                    </div>
                                    <div className="hover-text d-flex justify-content-center text-white">
                                        <p className="font_16">Flexibility to trade in both rising and falling forex markets.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="market_cardbody text-center">
                                    <div class="market_cardimg mb-4">
                                        <img src="assets/images/markets/marketcard7.svg" alt="" />
                                    </div>
                                    <h3 className="sub_title">Risk Management  <br /> Tools</h3>
                                    <span className="font_16 more">see more</span>
                                    <div className="mask-layer ">
                                        <span></span>
                                    </div>
                                    <div className="hover-text d-flex justify-content-center text-white">
                                        <p className="font_16">Tradsify offers negative balance protection, price alerts and stop loss tools.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="market_cardbody text-center">
                                    <div class="market_cardimg mb-4">
                                        <img src="assets/images/markets/marketcard8.svg" alt="" />
                                    </div>
                                    <h3 className="sub_title">MT5 <br /> Account</h3>
                                    <span className="font_16 more">see more</span>
                                    <div className="mask-layer ">
                                        <span></span>
                                    </div>
                                    <div className="hover-text d-flex justify-content-center text-white">
                                        <p className="font_16">Get access to forex markets with powerful MetaTrader 4 and MetaTrader 5 trading platforms.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="d-flex justify-content-center mt-4">
                                    <Link target="_blank" to="https://my.tradsify.com/en/auth/sign-up" className="btn btn_blue fw-semibold font_16 hero_demotrading_btn z-0">OPEN LIVE ACCOUNT
                                        <span><img src="assets/images/copytrading/hero-btn-icon.svg" alt="" className="hero_btn_icon" /></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space overflow-hidden">
                    <div className="container">
                        <div className="row g-3">
                            <div className="col-12">
                                <h2 class="sub_heading text-center mb-xl-5 mb-3">Open A Forex Trading Account With Tradsify</h2>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="market_acc_card position-relative">
                                    <div className="market_step step1 position-relative d-flex justify-content-center align-items-center mb-3">1</div>
                                    <p className="market_text font_20 mb-3">Any level</p>
                                    <div className="market_detail detail1 text-white">
                                        <div className="img-box mb-4">
                                            <img src="assets/images/markets/cent-logo.svg" alt="Forex Trading" />
                                        </div>
                                        <p className="font_16">For traders who would like to experience the power of trading in the cents.</p>
                                        <ul>
                                            <li class="list-unstyled"></li>
                                            <li class="list-unstyled"></li>
                                        </ul>
                                        <a className="text-decoration-none text-white font_16" href="/#">TRY NOW &gt;</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="market_acc_card position-relative">
                                    <div className="market_step step2 position-relative d-flex justify-content-center align-items-center mb-3">2</div>
                                    <p className="market_text font_20 mb-3">Novice</p>
                                    <div className="market_detail detail2 text-white">
                                        <div className="img-box mb-4">
                                            <img src="assets/images/markets/cent-logo2.svg" alt="Forex Trading" />
                                        </div>
                                        <p className="font_16">For beginner traders looking for direct market access with no commissions.</p>
                                        <ul>
                                            <li class="none">Tight spreads from 1.1 pip</li>
                                            <li class="none">No extra commissions on trading volume.</li>
                                        </ul>
                                        <a className="text-decoration-none text-white font_16" href="/#">TRY NOW &gt;</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="market_acc_card position-relative">
                                    <div className="market_step step3 position-relative d-flex justify-content-center align-items-center mb-3">3</div>
                                    <p className="market_text font_20 mb-3">Experienced</p>
                                    <div className="market_detail detail3 text-white">
                                        <div className="img-box mb-4">
                                            <img src="assets/images/markets/cent-logo3.svg" alt="Forex Trading" />
                                        </div>
                                        <p className="font_16">Offering seasoned traders razor-sharp spreads, low commissions, and deep liquidity.</p>
                                        <ul>
                                            <li class="none">Tight spreads from 0.0 pip Commissions from</li>
                                            <li class="none">USD$3.00 per standard lot, per side</li>
                                        </ul>
                                        <a className="text-decoration-none text-white font_16" href="/#">TRY NOW &gt;</a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="market_acc_card position-relative">
                                    <div className="step4 position-relative d-flex justify-content-center align-items-center mb-3">4</div>
                                    <p className="market_text font_20 mb-3">Professional</p>
                                    <div className="market_detail detail4 text-white">
                                        <div className="img-box mb-4">
                                            <img src="assets/images/markets/cent-logo4.svg" alt="Forex Trading" />
                                        </div>
                                        <p className="font_16">For professional traders and money managers who trade large volumes.</p>
                                        <ul>
                                            <li class="none">Tight spreads from 0.0 pip</li>
                                            <li class="none">Commissions from USD$1.50 per standard lot, per side</li>
                                        </ul>
                                        <a className="text-decoration-none text-white font_16" href="/#">TRY NOW &gt;</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space overflow-hidden">
                    <div className="container">
                        <div className="section_part_main">
                            <div className="row g-5">
                                <div className="col-lg-12">
                                    <div className="part_main">
                                        <h2 class="medium_heading text-white">How to Become A Tradsify Partner</h2>
                                        <Link to="" className="btn btn_blue fw-semibold font_16 hero_demotrading_btn z-0">OPEN LIVE ACCOUNT
                                            <span><img src="assets/images/copytrading/hero-btn-icon.svg" alt="" className="hero_btn_icon" /></span>
                                        </Link>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div className="market_up">
                                        <div class="step-circle">1</div>
                                        <h5 className="sub_small_heading part_refer">Sign Up</h5>
                                        <p className="font_16">Join as an IB with one of the largest global multi-asset brokers.</p>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div className="market_up">
                                        <div class="step-circle">2</div>
                                        <h5 className="sub_small_heading part_refer">Refer</h5>
                                        <p className="font_16">Expand your referral network by introducing new clients to trade with Tradsify.</p>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <div className="market_up">
                                        <div class="step-circle">3</div>
                                        <h5 className="sub_small_heading part_refer">Earn</h5>
                                        <p className="font_16">Earn a volume-based commission for each trade made by clients you’ve referred.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space">
                    <div className="container">
                        <div className="text-center">
                            <h2 className="sub_heading mb-xl-5 mb-3">FREQUENTLY ASKED QUESTIONS</h2>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="false"
                                            aria-controls="collapseOne"
                                        >
                                            1 .  Is forex trading good for beginners?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            Forex (FX) trading can be an appealing option for beginners who are eager to learn and understand the currency financial markets. This is because the forex markets can be traded with small amounts of capital, as the use of leverage allows traders to control larger positions, with limited capital. Traders can also trade small lot sizes, starting from 0.01 lot.
                                        </div>
                                        <div className="accordions-body text-start font_16">
                                            Moreover, the forex market is easily accessible online to countries like Phillipines, Thailand, or other locations around the world. This allows beginners to trade conveniently from anywhere with an internet connection. Forex brokers like Tradsify also provide educational resources ranging from online courses, webinars and educational articles. These resources can help beginners to learn about trading fundamentals, technical analysis as well as the tools and indicators used in forex (FX) trading.
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                            By dedicating time to learning and practising, beginners can build a solid foundation and improve their forex trading skills. However, it is important for beginners to exercise caution and conduct their own due diligence before engaging in any trades. Like any form of trading, forex trading carries inherent risks, particularly if you trade with leverage. Here’s a beginner’s guide to trading forex.
                                        </div>
                                    </div>

                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            2 . How can a beginner trade in forex?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse "
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            Beginner can start forex trading with just a few simple steps:
                                        </div>
                                        <div className="accordions-body text-start font_16">
                                            1. Find a reliable broker to trade with <br />As a beginner, you can do your own research on the offerings of different forex brokers, including their account options and the features of their trading platforms. This will help you to find the one that best suits your needs and preferences.
                                            Some key factors to look out for in a broker include:<br />
                                            - Low fees<br />
                                            - Wide variety of trading products<br />
                                            - Fast execution speed
                                        </div>
                                        <div className="accordions-body text-start font_16">
                                            2. Open a demo account<br />
                                            Practise trading forex on the demo account offered by the broker. This allows beginners to gain trading experience by making trades using virtual credit.<br />
                                            Alternatively, if you’re a seasoned trader, you can go straight to opening a forex (FX) trading account with Tradsify.
                                        </div>
                                        <div className="accordions-body text-start font_16">
                                            3. Educate yourself on forex trading<br />
                                            Take adtradsify of online courses, webinars, and educational articles offered by the broker. Use this opportunity to learn and enhance your knowledge about forex trading. Visit Tradsify Academy for the latest forex trading articles.
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                            4. Open a live account and start trading<br />
                                            Once you feel confident enough to trade the live markets, open a live account with the broker and fund your account to start forex (FX) trading.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                        >
                                            3 .   Is forex trading a good idea?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            Forex trading can be a good idea for short term traders who are looking to diversify their portfolio and have multiple sources of income.
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                            However, this is also largely dependent on your risk appetite. It's important to carefully consider your risk appetite, as forex trading can be highly volatile and may not be suitable for everyone. Proper research, risk management, and a clear trading strategy are essential to navigate this market successfully.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour"
                                        >
                                            4 . What are the benefits of forex trading?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFour"
                                        className="accordion-collapse collapse "
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            Forex trading offers some unique adtradsifys:
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                            a) 24-hour market<br />
                                            <p className="font_16 m-0 market_enjoy">Enjoy the convenience of round-the-clock trading with the forex market, whether you are in Thailand, Phillipines or other nations worldwide. Unlike stock or commodity markets, it operates 24 hours a day, five days a week, allowing you to trade forex (FX) at your preferred time, whether it's morning or night.</p>
                                            b) High liquidity<br />
                                            <p className="font_16 m-0 market_enjoy">The large trading volume in the forex market represents high liquidity for traders. This also means there are a large number of buyers and sellers at any time of the day. So, under any usual market conditions, you can instantly open or close your FX trade.</p>
                                            c) Low trade costs<br />
                                            <p className="font_16 m-0 market_enjoy">Forex markets typically have very narrow spreads – the difference between the bid price and the ask price and what a broker will charge. This reduces the costs you incur for your trade, leading to potential greater profit margins.</p>
                                            d) Leverage<br />
                                            <p className="font_16 m-0 market_enjoy">Forex traders have the option to utilise leverage, which allows them to amplify their forex trading potential beyond their initial deposit. Leverage enables traders to open positions in the forex market by providing only a fraction of the total value of the position upfront.</p>
                                            <p className="font_16 m-0 market_enjoy">Read more about the adtradsifys of trading forex here.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive"
                                            aria-expanded="false"
                                            aria-controls="collapseFive"
                                        >
                                            5 .   How does leverage work?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFive"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingFive"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            Leverage is a tool that allows traders to control larger positions using a smaller amount of capital. This is usually expressed as a ratio, such as 50:1, where the first number represents the amount you can control, and the second number represents the capital you have.                                        </div>
                                        <div className="accordions-body text-start font_16">
                                            To illustrate this concept, let's have a look at an example:<br />
                                            Imagine you have $100 and use a leverage of 50:1. With this leverage, your $100 investment can control a position valued at $5,000. This means that you can potentially take adtradsify of the price movements of a much larger position than you could not have accessed with your initial capital alone.
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                            However, please bear in mind that leverage is a double-edged sword, as it not only magnifies potential profits but also amplifies potential losses. To gain a deeper understanding of how leverage operates in forex trading, you can learn more here.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </ScrollToTop>
        </>
    )
}