// import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link } from "react-router-dom";
import React, { useState, useRef, useEffect } from "react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";



export default function WhyTradsify() {
    const [swiperRef, setSwiperRef] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);

    // const swiperRef = useRef(null);
    const prevRef = useRef(null);
    const nextRef = useRef(null);
    useEffect(() => {
        // Ensure Swiper initializes after the buttons are available
        const swiper = document.querySelector(".swiper").swiper;
        swiper.params.navigation.prevEl = prevRef.current;
        swiper.params.navigation.nextEl = nextRef.current;
        swiper.navigation.init();
        swiper.navigation.update();
    }, []);

    const slides = [
        {
            title: "Tradsify App",
            description: "Access global markets anywhere, anytime",
            buttonText: "JOIN NOW",
        },
        {
            title: "MetaTrader 5",
            description: "Experience the cutting-edge MT5 platform trading platform",
            buttonText: "JOIN NOW",
        },
        {
            title: "Copy Trading",
            description: "Replicate trades like a master with the tap of a button",
            buttonText: "JOIN NOW",
        },
        {
            title: "Demo Trading",
            description: "Practise your trading skills with virtual funds",
            buttonText: "JOIN NOW",
        },
    ];

    return (
        <>
            <ScrollToTop>
                <Header />
                <section className="section_space why_trad_hero">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h2 class="main_heading text-white">Why Tradsify?</h2>
                                <p className="font_16 text-white">We are a leading CFD provider within the industry, having built a trusted community of traders who have chosen us as their preferred platform for their trading needs. In addition, Tradsify is regulated in various jurisdictions including tier-1 regulatory bodies in the global financial domain.</p>
                                <div className="trade_btn_main d-flex gap-2">
                                    <Link to="https://my.tradsify.com/en/auth/sign-in" target="_blank">
                                        <button className="btn btn_blue text-uppercase mt-lg-4 mt-3">TRADE NOW</button>
                                    </Link>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space better_trade">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <h2 class="sub_heading text-center">Trade Better With Tradsify</h2>
                                <p className="font_16 text-center mb-0">One of the world’s leading CFD brokers with more than Tradsify provides  <br />traders with access to 1,000+ CFD products, including forex, indices, commodities, shares, ETFs, and bonds.</p>
                                <p className="font_16 text-center">Trade CFDs with ease on desktop or mobile using our variety of advanced trading tools and features.</p>
                            </div>
                            <div className="col-lg-12">
                                <ul className="nav nav-tabs mt-4 justify-content-center d-flex flex-wrap mb-4" id="myTab" role="tablist">
                                    <li className="nav-item deposit_item" role="presentation">
                                        <button className="nav-link deposit_tab_button font_20 active" id="new-user-tab" data-bs-toggle="tab" data-bs-target="#new-user" type="button" role="tab" aria-controls="new-user" aria-selected="true">Products</button>
                                    </li>
                                    <li className="nav-item deposit_item" role="presentation">
                                        <button className="nav-link deposit_tab_button font_20" id="existing-user-tab" data-bs-toggle="tab" data-bs-target="#existing-user" type="button" role="tab" aria-controls="existing-user" aria-selected="false">Trading Platforms</button>
                                    </li>
                                    <li className="nav-item deposit_item" role="presentation">
                                        <button className="nav-link deposit_tab_button font_20" id="option-3-tab" data-bs-toggle="tab" data-bs-target="#option-3" type="button" role="tab" aria-controls="option-3" aria-selected="false">Accounts</button>
                                    </li>
                                    {/* <li className="nav-item deposit_item" role="presentation">
                                        <button className="nav-link deposit_tab_button font_20" id="option-4-tab" data-bs-toggle="tab" data-bs-target="#option-4" type="button" role="tab" aria-controls="option-4" aria-selected="false">Learn</button>
                                    </li> */}
                                    <li className="nav-item deposit_item" role="presentation">
                                        <button className="nav-link deposit_tab_button font_20" id="option-5-tab" data-bs-toggle="tab" data-bs-target="#option-5" type="button" role="tab" aria-controls="option-5" aria-selected="false">Analysis</button>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content mt-4" id="myTabContent">
                                <div className="tab-pane fade show active" id="new-user" role="tabpanel" aria-labelledby="new-user-tab">
                                    <div className="row justify-content-center g-4">
                                        <div className="col-lg-11">
                                            <p className="font_16 text-center">Experience the benefits of trading markets with us. <br />Our commitment to transparency means you'll enjoy  low-cost trading  <br /> conditions, access to cutting-edge technology, and outstanding customer support.</p>
                                        </div>
                                        <Swiper
                                             onSwiper={setSwiperRef}
                                             centeredSlides={true}
                                             spaceBetween={20}
                                             modules={[Pagination, Navigation]}
                                             className="mySwiper trade_forex"
                                             breakpoints={{
                                                 320: { slidesPerView: 1, spaceBetween: 10 },  // Mobile
                                                 480: { slidesPerView: 1.5, spaceBetween: 15 }, // Small tablets
                                                 768: { slidesPerView: 2, spaceBetween: 20 },  // Tablets
                                                 1024: { slidesPerView: 3, spaceBetween: 25 }, // Desktops
                                                 1280: { slidesPerView: 3.5, spaceBetween: 30 } // Large screens
                                             }}
                                        >
                                            <SwiperSlide>
                                                <div className="trade_card_main">
                                                    <div className="trade_card_text position-relative">
                                                        <h2 className="font_30">Forex</h2>
                                                        <Link to="" className="trade_right_arrow">
                                                        </Link>
                                                        <p className="font_16 mt-4">Trade 40 major, minor, and exotic currency pairs with competitive trading conditions.</p>
                                                    </div>
                                                    <div className="trade_card_img">
                                                        <img src="assets/images/whytradsify/why-icon-forex.svg" alt="" />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="trade_card_main">
                                                    <div className="trade_card_text position-relative">
                                                        <h2 className="font_30">Indices</h2>
                                                        <Link to="" className="trade_right_arrow">
                                                        </Link>
                                                        <p className="font_16 mt-4">Trade major and minor Index CFDs Spot and Futures from around the world.</p>
                                                    </div>
                                                    <div className="trade_card_img">
                                                        <img src="assets/images/whytradsify/why-icon-Indices.svg" alt="" />
                                                    </div>
                                                </div>

                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="trade_card_main">
                                                    <div className="trade_card_text position-relative">
                                                        <h2 className="font_30">Commodities</h2>
                                                        <Link to="" className="trade_right_arrow">
                                                        </Link>
                                                        <p className="font_16 mt-4">Execute trades across a diverse spectrum of both hard and soft commodity categories.</p>
                                                    </div>
                                                    <div className="trade_card_img">
                                                        <img src="assets/images/whytradsify/why-icon-Commodities.svg" alt="" />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="trade_card_main">
                                                    <div className="trade_card_text position-relative">
                                                        <h2 className="font_30">ETFs</h2>
                                                        <Link to="" className="trade_right_arrow">
                                                        </Link>
                                                        <p className="font_16 mt-4">Exchange-traded fund (ETF) is a type of investment fund that tracks underlying assets, such as stocks, metals, etc. and divides ownership of those assets into shares. Investing in an ETF offers a cost-effective way to diversify your portfolio.</p>
                                                    </div>
                                                    <div className="trade_card_img">
                                                        <img src="assets/images/whytradsify/why-icon-ETFs.svg" alt="" />
                                                    </div>
                                                </div>

                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="trade_card_main">
                                                    <div className="trade_card_text position-relative">
                                                        <h2 className="font_30">Shares</h2>
                                                        <Link to="" className="trade_right_arrow">
                                                        </Link>
                                                        <p className="font_16 mt-4">Venture into the stock markets with shares of renowned global corporations, such as Amazon, Google, and Netflix. Trade the biggest listed companies in the US, UK, EU, and Australia via CFDs.</p>
                                                    </div>
                                                    <div className="trade_card_img">
                                                        <img src="assets/images/whytradsify/why-icon-cfds.svg" alt="" />
                                                    </div>
                                                </div>

                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="trade_card_main">
                                                    <div className="trade_card_text position-relative">
                                                        <h2 className="font_30">Bonds</h2>
                                                        <Link to="" className="trade_right_arrow">
                                                        </Link>
                                                        <p className="font_16 mt-4">Trade bonds issued by the world’s leading economies via CFDs.</p>
                                                    </div>
                                                    <div className="trade_card_img">
                                                        <img src="assets/images/whytradsify/why-icon-Bonds.svg" alt="" />
                                                    </div>
                                                </div>

                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="existing-user" role="tabpanel" aria-labelledby="existing-user-tab">
                                    <div className="row justify-content-center position-relative g-4">
                                        <div className="col-lg-11">
                                            <p className="font_16 text-center">At the forefront of fintech, Tradsify offers a contemporary, innovative ,  <br /> and intuitive trading experience that sets new standards. Seamlessly blending  <br /> advanced  functionalities  with user-friendly design, it caters to both novices and seasoned traders alike.</p>
                                        </div>
                                        <div className="col-12 col-md-10 col-lg-6">
                                            <div className="contact_us">
                                                <div ref={prevRef} className="platforms-swiper-button-prev">
                                                    ❮
                                                </div>
                                                <Swiper
                                                    ref={swiperRef}
                                                    modules={[Navigation, Autoplay]}
                                                    spaceBetween={30}
                                                    slidesPerView={1}
                                                    autoplay={{ delay: 3000, disableOnInteraction: false }}
                                                    loop={true}
                                                    navigation={{ prevEl: prevRef.current, nextEl: nextRef.current }}
                                                    breakpoints={{
                                                        768: { slidesPerView: 1 },
                                                        1024: { slidesPerView: 1 },
                                                    }}
                                                    onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
                                                >
                                                    {slides.map((slide, index) => (
                                                        <SwiperSlide key={index}>
                                                            <div className="text-center p-3 p-md-5">
                                                                <p className="font_16 fw-bold">Live Trading Platform</p>
                                                                <h2 className="medium_heading">{slide.title}</h2>
                                                                <p className="font_16">{slide.description}</p>
                                                                <button className="btn btn_blue rounded-pill px-3 px-md-4 py-2">
                                                                    {slide.buttonText}
                                                                </button>
                                                            </div>
                                                        </SwiperSlide>
                                                    ))}
                                                </Swiper>
                                                <div ref={nextRef} className="platforms-swiper-button-next">
                                                    ❯
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="option-3" role="tabpanel" aria-labelledby="option-3-tab">
                                    <div className="row justify-content-center g-4">
                                        <div className="col-lg-11">
                                            <p className="font_16 text-center">Discover the ideal trading account at Tradsify and unleash the potential of trading in thousands, regardless of your experience level.</p>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-6">
                                            <div className="pro_traders">
                                                <div className="pro_heading">
                                                    <img src="assets/images/trandingaccounts/pro1.svg" alt="" />
                                                    <span className="right_arrow">
                                                        <img src="assets/images/trandingaccounts/rightarrow.svg" alt="" />
                                                    </span>
                                                </div>
                                                <div className="pro_para">
                                                    <p class="font_16">Professional Traders</p>
                                                    <p class="font_16">For professional traders and money managers who trade large volumes.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-8 col-lg-8 col-md-6">
                                            <div className="pro_exp">
                                                <div className="pro_heading">
                                                    <img src="assets/images/trandingaccounts/pro2.svg" alt="" />
                                                    <span className="right_arrow">
                                                        <img src="assets/images/trandingaccounts/rightarrow.svg" alt="" />
                                                    </span>
                                                </div>
                                                <div className="pro_para">
                                                    <p class="font_16">Experienced Traders</p>
                                                    <p class="font_16">Offering seasoned traders razor-sharp spreads, low commissions, and deep liquidity.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-8 col-lg-8 col-md-6">
                                            <div className="stan_traders">
                                                <div className="pro_heading">
                                                    <img src="assets/images/trandingaccounts/pro3.svg" alt="" />
                                                    <span className="right_arrow">
                                                        <img src="assets/images/trandingaccounts/rightarrow.svg" alt="" />
                                                    </span>
                                                </div>
                                                <div className="pro_para">
                                                    <p class="font_16">Novice Traders</p>
                                                    <p class="font_16">For beginner traders looking for direct market access with no commissions.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-6">
                                            <div className="stan_exp">
                                                <div className="pro_heading">
                                                    <img src="assets/images/trandingaccounts/pro4.svg" alt="" />
                                                    <span className="right_arrow">
                                                        <img src="assets/images/trandingaccounts/rightarrow.svg" alt="" />
                                                    </span>
                                                </div>
                                                <div className="pro_para">
                                                    <p class="font_16">Any Level</p>
                                                    <p class="font_16">Ideal for traders with religious beliefs that prohibit receiving or paying swaps.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="option-4" role="tabpanel" aria-labelledby="option-4-tab">
                                    <div className="row justify-content-center g-4">
                                        <div className="col-lg-11">
                                            <p className="font_16 text-center">Hone your trading skills with our comprehensive array of free educational tools and resources. Explore specialised trading webinars, educational courses, insights from our market analysts, platform tutorials, and a wide range of enriching articles to improve your trading strategy.</p>
                                        </div>
                                        <Swiper
                                               onSwiper={setSwiperRef}
                                               centeredSlides={true}
                                               spaceBetween={20}
                                               modules={[Pagination, Navigation]}
                                               className="mySwiper trade_forex"
                                               breakpoints={{
                                                   320: { slidesPerView: 1, spaceBetween: 10 },  // Mobile
                                                   480: { slidesPerView: 1.5, spaceBetween: 15 }, // Small tablets
                                                   768: { slidesPerView: 2, spaceBetween: 20 },  // Tablets
                                                   1024: { slidesPerView: 3, spaceBetween: 25 }, // Desktops
                                                   1280: { slidesPerView: 3.5, spaceBetween: 30 } // Large screens
                                               }}
                                        >
                                            <SwiperSlide>
                                                <div className="learn_card_main">
                                                    <div className="learn_card_text position-relative">
                                                        <h2 className="font_30">Articles</h2>
                                                        <Link to="" className="learn_right_arrow">
                                                        </Link>
                                                        <p className="font_16 mt-4">Stay ahead in your financial journey. Explore our latest articles.</p>
                                                    </div>
                                                    <div className="learn_card_img">
                                                        <img src="assets/images/whytradsify/learnblack.webp" alt="" className="learnblack" />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="learn_card_main">
                                                    <div className="learn_card_text position-relative">
                                                        <h2 className="font_30">Articles</h2>
                                                        <Link to="" className="learn_right_arrow">
                                                        </Link>
                                                        <p className="font_16 mt-4">Stay ahead in your financial journey. Explore our latest articles.</p>
                                                    </div>
                                                    <div className="learn_card_img2">
                                                        <img src="assets/images/whytradsify/learnblack.webp" alt="" className="learnblack" />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            
                                            <SwiperSlide>
                                                <div className="learn_card_main">
                                                    <div className="learn_card_text position-relative">
                                                        <h2 className="font_30">Articles</h2>
                                                        <Link to="" className="learn_right_arrow">
                                                        </Link>
                                                        <p className="font_16 mt-4">Stay ahead in your financial journey. Explore our latest articles.</p>
                                                    </div>
                                                    <div className="learn_card_img4">
                                                        <img src="assets/images/whytradsify/learnblack.webp" alt="" className="learnblack" />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide>
                                                <div className="learn_card_main">
                                                    <div className="learn_card_text position-relative">
                                                        <h2 className="font_30">Articles</h2>
                                                        <Link to="" className="learn_right_arrow">
                                                        </Link>
                                                        <p className="font_16 mt-4">Stay ahead in your financial journey. Explore our latest articles.</p>
                                                    </div>
                                                    <div className="learn_card_img5">
                                                        <img src="assets/images/whytradsify/learnblack.webp" alt="" className="learnblack" />
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="option-5" role="tabpanel" aria-labelledby="option-5-tab">
                                    <div className="row justify-content-center g-4">
                                        <div className="col-lg-11">
                                            <p className="font_16 text-center">Stay informed with real-time market insights, actionable trade ideas, and professional guidance.</p>
                                        </div>
                                        <Swiper
                                            onSwiper={setSwiperRef}
                                            centeredSlides={true}
                                            spaceBetween={20}
                                            modules={[Pagination, Navigation]}
                                            className="mySwiper trade_forex"
                                            breakpoints={{
                                                320: {
                                                    slidesPerView: 1,
                                                    spaceBetween: 10
                                                },
                                                480: {
                                                    slidesPerView: 1.5,
                                                    spaceBetween: 15
                                                },
                                                768: {
                                                    slidesPerView: 2,
                                                    spaceBetween: 20
                                                },
                                                1024: {
                                                    slidesPerView: 3,
                                                    spaceBetween: 25
                                                },
                                                1280: {
                                                    slidesPerView: 3.5,
                                                    spaceBetween: 30
                                                }
                                            }}
                                        >
                                            <SwiperSlide className="analysis">
                                                <div className="analy_card_main">
                                                    <div className="analy_card_text position-relative">
                                                        <h2 className="font_30">Market Analysis</h2>
                                                        <Link to="" className="analy_right_arrow">
                                                        </Link>
                                                        <p className="font_16 mt-4">Stay updated with all the significant economic events, data releases and announcements with the Tradsify Economic Calendar.</p>
                                                    </div>
                                                    <div className="analy_card_img">
                                                        <img src="assets/images/whytradsify/analy1.webp" alt="" className="analy_image"/>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className="analysis">
                                                <div className="analy_card_main">
                                                    <div className="analy_card_text position-relative">
                                                        <h2 className="font_30">Client Sentiment</h2>
                                                        <Link to="" className="analy_right_arrow">
                                                        </Link>
                                                        <p className="font_16 mt-4">Analyse client sentiment to understand the majority consensus, whether it's a bullish or bearish market, thereby providing insights into trading opportunities.</p>
                                                    </div>
                                                    <div className="analy_card_img">
                                                        <img src="assets/images/whytradsify/analy2.webp" alt="" className="analy_image"/>
                                                    </div>
                                                </div>

                                            </SwiperSlide>
                                            <SwiperSlide className="analysis">
                                                <div className="analy_card_main">
                                                    <div className="analy_card_text position-relative">
                                                        <h2 className="font_30">Economic Calendar</h2>
                                                        <Link to="" className="analy_right_arrow">
                                                        </Link>
                                                        <p className="font_16 mt-4">Allows traders to track important, potentially market-moving events and assist them in anticipating when major movements will happen in the market.</p>
                                                    </div>
                                                    <div className="analy_card_img">
                                                        <img src="assets/images/whytradsify/analy3.webp" alt="" className="analy_image"/>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className="analysis">
                                                <div className="analy_card_main">
                                                    <div className="analy_card_text position-relative">
                                                        <h2 className="font_30">Forex VPS</h2>
                                                        <Link to="" className="analy_right_arrow">
                                                        </Link>
                                                        <p className="font_16 mt-4">The Forex VPS is specifically designed to run forex trading applications 24 hours a day without interruption, ensuring faster trade execution and reduced latency.</p>
                                                    </div>
                                                    <div className="analy_card_img">
                                                        <img src="assets/images/whytradsify/analy4.webp" alt="" className="analy_image"/>
                                                    </div>
                                                </div>

                                            </SwiperSlide>
                                            <SwiperSlide className="analysis">
                                                <div className="analy_card_main">
                                                    <div className="analy_card_text position-relative">
                                                        <h2 className="font_30">Trading Signals</h2>
                                                        <Link to="" className="analy_right_arrow">
                                                        </Link>
                                                        <p className="font_16 mt-4">Stay ahead of the markets with our daily forex trading signals to spot the latest market opportunities.</p>
                                                    </div>
                                                    <div className="analy_card_img">
                                                        <img src="assets/images/whytradsify/analy5.webp" alt="" className="analy_image"/>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space">
                    <div className="container">
                        <div className="row g-4 justify-content-center">
                            <div className="col-lg-12">
                                <h2 class="sub_heading text-center mb-3">Corporate Governance</h2>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="fund_main text-center">
                                    <div className="fund_img mb-3">
                                        <img src="assets/images/whytradsify/fund1.png" alt="" />
                                    </div>
                                    <div className="fund_text">
                                        <h3 className="sub_small_heading">Security of Fund</h3>
                                        <p className="font_16">Clients’ funds are held in a segregated account with a top-tier bank.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="fund_main text-center">
                                    <div className="fund_img mb-3">
                                        <img src="assets/images/whytradsify/fund2.png" alt="" />
                                    </div>
                                    <div className="fund_text">
                                        <h3 className="sub_small_heading">Licensed and Regulated</h3>
                                        <p className="font_16">Tradsify fully complies with the strict financial regulations set out by the jurisdictions in which we are regulated. Tradsify strives to provide transparent and fair access to global forex markets.</p>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-lg-3 col-md-4 col-sm-6">
                                <div className="fund_main text-center">
                                    <div className="fund_img mb-3">
                                        <img src="assets/images/whytradsify/fund3.webp" alt="" />
                                    </div>
                                    <div className="fund_text">
                                        <h3 className="sub_small_heading">Indemnity Insurance</h3>
                                        <p className="font_16">Tradsify has Professional Indemnity Insurance in place which covers the work done by our representatives, employees and other authorised representatives.</p>
                                    </div>
                                </div>
                            </div> */}
                            <div className="col-lg-4 col-md-6">
                                <div className="fund_main text-center">
                                    <div className="fund_img mb-3">
                                        <img src="assets/images/whytradsify/fund40.png" alt="" />
                                    </div>
                                    <div className="fund_text">
                                        <h3 className="sub_small_heading">Negative balance protection</h3>
                                        <p className="font_16">Our Negative Balance Protection ensures you’ll never owe more than your deposited funds. If your account goes negative, the system automatically resets your balance to zero and deducts any credits to offset the negative amount.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space overflow-hidden touch_bg">
                    <div className="container">
                        <div className="row align-items-center text-center g-4">
                            <div className="col-lg-6 order-lg-1 order-2">
                                <h2 class="sub_heading">Get In Touch With Us, 24 Hours A Day</h2>
                                <p className="font_16">Call, live chat, or email us!</p>
                                <Link to="/contact" >
                                    <button className="btn btn_blue px-lg-5 deposit_button font_16">CONTACT US</button>
                                </Link>
                            </div>
                            <div className="col-lg-6 order-lg-2 order-1">
                                <img src="assets/images/whytradsify/message.webp" alt="" className="why_image" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space">
                    <div className="container">
                        <div className="row justify-content-center g-4">
                            <div className="col-12">
                                <h2 class="sub_heading text-center mb-xl-5 mb-3">Be a CFD trader here with Tradsify</h2>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="market_acc_card position-relative">
                                    <div className="trad_step step1 position-relative d-flex justify-content-center align-items-center mb-3">1</div>
                                    <p className="market_text font_20 mb-3">Register</p>
                                    <div className="trad_detail detail1 text-white">
                                        <p className="font_16">Seamless account opening process.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="market_acc_card position-relative">
                                    <div className="trad_step step2 position-relative d-flex justify-content-center align-items-center mb-3">2</div>
                                    <p className="market_text font_20 mb-3">Fund</p>
                                    <div className="trad_detail detail2 text-white">
                                        <p className="font_16">Fund your trading account with an extensive choice of deposit methods.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6">
                                <div className="market_acc_card position-relative">
                                    <div className="trad_step step3 position-relative d-flex justify-content-center align-items-center mb-3">3</div>
                                    <p className="market_text font_20 mb-3">Trade</p>
                                    <div className="trad_detail detail3 text-white">
                                        <p className="font_16">Trade with spreads starting as low as 0.0 and gain access to over 1,000+ CFD instruments.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <div className="trade_button d-flex justify-content-center gap-2">
                                    <Link to="https://my.tradsify.com/en/auth/sign-in" target="_blank">
                                        <button className="btn btn_blue px-lg-5 deposit_button font_16 rounded-2">TRADE NOW</button>
                                    </Link>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </ScrollToTop>
        </>
    )
}