import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import Header from "../component/Header";
import Footer from "../component/Footer";

export default function Support() {

    return (
        <>
            <ScrollToTop>
                <Header />
                <section className="section_space support_hero_bg">
                        <div className="container">
                            <div className="row text-center">
                                <div className="hero_heading">
                                    <h2 className="main_heading text-center text-white">Trading Support</h2>
                                    <p className="font_16 text-center text-white">Your Trading Queries, Resolved Here.</p>
                                </div>
                                {/* <div className="col-lg-4 col-md-4 col-sm-4">
                                    <div className="hero_img">
                                        <img src="assets/images/support/award1.svg" alt="" />
                                    </div>
                                    <div className="hero_text">
                                        <p className="font_16 text-white">Excellence in <br/> Customer Service</p>
                                        <p className="font_16 text-white">Fintech Awards</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <div className="hero_img">
                                        <img src="assets/images/support/award2.svg" alt="" />
                                    </div>
                                    <div className="hero_text">
                                        <p className="font_16 text-white">Excellence in <br/> Customer Service</p>
                                        <p className="font_16 text-white">EMG – Global 100 Awards</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-4">
                                    <div className="hero_img">
                                        <img src="assets/images/support/award3.svg" alt="" />
                                    </div>
                                    <div className="hero_text">
                                        <p className="font_16 text-white">Best CFD Broker <br/> – APAC</p>
                                        <p className="font_16 text-white">Global Business and Finance Magazine</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                </section>
                <section className="section_space main_bank">
                    <div className="container">
                            <div className="row">
                                <div className="inner position-relative">
                                    <div className="texts">
                                        <p className="mt-5">
                                            *In addition, international bank wire transfers may incur fees charged
                                            by your financial institution. Please check with your financial
                                            institution for more information.
                                        </p>
                                        <p>
                                            ¹Subject to, among others, 3-D Secure (3DS) authentication, risk
                                            management and daily limit measures.
                                        </p>
                                    </div>
                                    <div className="main_content mt-5">
                                        <ul className="d-flex justify-content-center list-unstyled main_ul">
                                            <li className="main_li">
                                                <div className="img_box">
                                              <img src="assets/images/support/shape1.png" alt="." />
                                                </div>
                                                <div className="text-content">
                                                    <div className="title-box">
                                                        <h2 className="small_heading text-center">
                                                            How to Make Withdrawal?
                                                        </h2>
                                                        <p className="font_16 text-start">
                                                            Navigate and withdraw your funds from the Tradsify client
                                                            portal or app. Select from our array of convenient withdrawal
                                                            options that cater to all client preferences, regardless of
                                                            your location worldwide.
                                                        </p>
                                                    </div>
                                                    <div className="text-box">
                                                        <p className="font_16 text-start">
                                                            Please note: It is the client’s responsibility to ensure there
                                                            are sufficient funds available for the withdrawal request to
                                                            be processed. Failing to maintain sufficient balance in the
                                                            account may result in the rejection of the withdrawal request.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="button-box d-flex justify-content-center">
                                                    <a className="btn btn_blue px-lg-5 fw-semibold font_16 trade_btn live_button" href="">MORE FAQS ON WITHDRAWAL</a>
                                                </div>
                                            </li>
                                            <li className="main_lis">
                                                <div className="img_box">
                                                <img src="assets/images/support/shape2.png" alt="." />
                                                </div>
                                                <div className="text-content">
                                                    <div className="title-box">
                                                        <h2 className="small_heading text-center">
                                                            What are Tradsify<br/> Trading Hours ?
                                                        </h2>
                                                        <p className="font_16 text-start mb-0">
                                                            With round the clock trading, you can trade all our
                                                            instruments 24/7, whether you are from Thailand, Phillipines,
                                                            or beyond. Check out our trading times for various instruments
                                                            here.
                                                        </p>
                                                    </div>
                                                    <div className="text-box">
                                                        <p className="font_16 text-start mb-0">
                                                            *Trading on some instruments like digital coin is available
                                                            throughout the week, even during weekends. Please check
                                                            timings for individual instruments before you start trading.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="button-box d-flex justify-content-center">
                                                    <a className="btn btn_blue px-lg-5 fw-semibold font_16 trade_btn live_button" href="">ALL INSTRUMENTS</a>
                                                </div>
                                            </li>
                                        </ul>
                                        </div>
                                </div>
                            </div>
                    </div>
                </section>
                <section className="section_space topics">
                    <div className="container">
                        <div className="section-title mb-5">
                            <h2 className="sub_heading text-center">Frequently Asked<br /> Trading Support Topics</h2>
                            <p className="font_16 text-center">Have a question? Here's a one-stop solution to all your trading queries.</p>
                        </div>
                        <div className="row">
                            <div className="main_topics">
                                <div className="col">
                                    <div className="topics_card">
                                        <img src="assets/images/support/card1.svg" alt="" />
                                        <div className="topics_body">
                                            <h5 className="topics_titles">Trading with Tradsify</h5>
                                            <a href="#" className="view-more">view more</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="topics_card">
                                        <img src="assets/images/support/card2.svg" alt="" />
                                        <div className="topics_body">
                                            <h5 className="topics_titles">Opening an Account</h5>
                                            <a href="#" className="view-more">view more</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="topics_card">
                                        <img src="assets/images/support/card3.svg" alt="" />
                                        <div className="topics_body">
                                            <h5 className="topics_titles">Manage Your Account</h5>
                                            <a href="#" className="view-more">view more</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="topics_card">
                                        <img src="assets/images/support/card4.svg" alt="" />
                                        <div className="topics_body">
                                            <h5 className="topics_titles">Manage Your Fund</h5>
                                            <a href="#" className="view-more">view more</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="topics_card">
                                        <img src="assets/images/support/card5.svg" alt="" />
                                        <div className="topics_body">
                                            <h5 className="topics_title">Trading Charges & Payments</h5>
                                            <a href="#" className="view-more">view more</a>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col">
                                    <div className="topics_card">
                                        <img src="assets/images/support/card5.svg" alt="" />
                                        <div className="topics_body">
                                            <h5 className="topics_title">Using Our Trading Platforms</h5>
                                            <a href="#" className="view-more">view more</a>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space testimonials">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 text-center mb-5">
                                <h2 class="sub_heading">Testimonials</h2>
                                <p class="font_16">Hear what our users have to say.</p>
                            </div>
                            <div class="col-lg-6 col-md-12 mb-4">
                                <div class="testimonials_card p-3">
                                    <h2 class="font_20">Hanafi Lesmana</h2>
                                    <p class="font_16 mb-0">Used several years and ok</p>
                                    <p class="font_16 mb-0">Already used several years, all still ok.</p>
                                    <p class="font_16 mb-0">Thanks for good account manager</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 mb-4">
                                <div class="testimonials_card p-3">
                                    <h2 class="font_20">Mehran Sivari</h2>
                                    <p class="font_16 mb-0">I like it reasonable spreads, no delay...</p>
                                    <p class="font_16 mb-0">Good mobile application, reasonable spreads, no delay in open/closing contracts and perfect support.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 mb-4">
                                <div class="testimonials_card p-3">
                                    <h2 class="font_20">Md Mahedi Hasan</h2>
                                    <p class="font_16 mb-0">Very very good customer services.</p>
                                    <p class="font_16 mb-0">I made a mistake while depositing; they solved my problem.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 mb-4">
                                <div class="testimonials_card p-3">
                                    <h2 class="font_20">Kelkouma Abaye</h2>
                                    <p class="font_16 mb-0">I had again a good and quick...</p>
                                    <p class="font_16">I had a quick conversation with the support. I congratulate their reactivity.</p>
                                    <p class="font_16 mb-0">Quick deposit and quick withdrawal. It's great.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 mb-4">
                                <div class="testimonials_card p-3">
                                    <h2 class="font_20">Thisismj</h2>
                                    <p class="font_16 mb-0">Not many companies have such good service. Thank you Grace.</p>
                                    <p class="font_16 mb-0">Grace made my day. Her responses were quick and professional. My withdrawal request wasn't successful initially because I forgot some details. She ensured the request was successful on the second attempt and increased the priority. Not many companies have such good service.</p>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 mb-4">
                                <div class="testimonials_card p-3">
                                    <h2 class="font_20">Mr Simon Dobra</h2>
                                    <p class="font_16 mb-0">The best platform for trading!</p>
                                    <p class="font_16 mb-0">Started trading with Tradsify for a couple of months. Customer service is spot on—quick answers, quick email replies. I like the option to select the desired leverage, instant account deposit via debit card, and withdraw profit back on your card within 10 minutes at the week's end. Easy access to META, very low overnight fee. I switched 100% to TRADSIFY. Highly recommended!!!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="trad_bg">
                                    <div className="text-center mb-lg-5 mb-4">
                                        <h2 className="sub_heading text-white">Try Our Account For Free</h2>
                                    </div>
                                    <div className="d-flex justify-content-center gap-3 align-items-center flex-wrap flex-md-nowrap flex-column flex-md-row">
                                        <a target="_blank" href="https://my.tradsify.com/en/auth/sign-in" className="btn btn_blue px-lg-5 fw-semibold font_16 trade_btn">OPEN LIVE ACCOUNT</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </ScrollToTop>
        </>
    )
}