import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import Header from "../component/Header";
import Footer from "../component/Footer";



export default function DemoTrading() {
    return (
        <>
            <ScrollToTop>
                <Header />
                <section className="section_space demotrading_herobg">
                    <div className="container">
                        <div className="row">
                            <div className="hero_heading">
                                <h2 className="main_heading text-white">Demo Trading</h2>
                                <p className="font_16 text-white">Practise trading and test your strategies using virtual funds in our Demo Account before trading live markets.</p>
                                <a href="#/" className="btn btn_blue fw-semibold font_16 hero_demotrading_btn z-0">Trade Now
                                    <span><img src="assets/images/copytrading/hero-btn-icon.svg" alt="" className="hero_demotrading_img"/></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space">
                    <div className="container">
                        <div className="row text-center justify-content-center">
                            <div className="col-10 col-lg-6">
                                <div className="demo_social d-flex justify-content-center gap-3">
                                    <img src="assets/images/demotrading/google-play-demo.svg" alt="." />
                                    <img src="assets/images/demotrading/apple-store-demo.svg" alt="." />
                                </div>
                                <div className="demo_content mt-4">
                                    <h2 className="sub_heading mb-3">What is Demo Trading?</h2>
                                    <p className="font_16">Demo trading allows aspiring traders to trade forex, stocks, commodities and more,
                                        without any initial capital outlay or deposits required. A Demo Account comes with virtual funds provided, allowing traders to test out trading strategies and refine their skills in a safe, risk-free environment.</p>
                                    <p className="font_16">At Tradsify, we understand that mastery in trading is a journey that takes time. Demo trading plays a crucial role, as it simulates an environment that allows traders to experiment with different strategies, analyse market trends, and build confidence in their trades before transitioning to trading with real funds.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space trading_demo">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="sub_heading text-center">Why Trade Demo?</h2>
                            </div>
                            <div className="why_trade">
                                <div className="demo_main">
                                    <div className="main-content d-lg-flex justify-content-center gap-3">
                                        <div className="left">
                                            <div className="demo_content">
                                                <h3 className="small_heading">Trade With Virtual Funds</h3>
                                                <p className="font_16">A Demo Account with Tradsify comes equipped with virtual funds of $100,000. These virtual funds are not real money and can only be used for paper trades in the Demo Account.<br />
                                                    Gain hands-on experience trading in a risk-free environment where you can practise trading and test out the platform without having to risk real money. Familiarise yourself with different trading tools, techniques, and market dynamics before stepping into the real market.</p>
                                            </div>
                                            <div className="trade_img">
                                                <img width="520" height="460" src="assets/images/demotrading/why-trade-demo.png" alt="." className="w-100"/>
                                            </div>
                                        </div>
                                        <div className="right">
                                            <div className="demo_contents top">
                                                <div className="">
                                                    <h3 className="small_heading text-white">Try New Strategies and Products</h3>
                                                    <p className="font_16 text-white">Experiment with various strategies and analyse their outcomes without risking your own capital. Learn from both successes and failures to refine your approach before diving into live trading. You can also practise trading different asset classes such as forex, shares, commodities, and more, to prepare yourself for trading with real cash.</p>
                                                </div>
                                            </div>
                                            <div className="demo_contents btm">
                                                <div className="">
                                                    <h3 className="small_heading">Test new robots/algorithms</h3>
                                                    <p className="font_16">Our Demo Account provides you with a platform to explore and test out new robots and algorithms, all without the need for any financial commitment. Take adtradsify of this valuable resource to delve into their performance, fine-tune their settings, and optimise their behaviour according to your trading strategies. With the freedom to experiment, you can gain valuable insights and improve your overall trading approach.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space trading_demo2 overflow-hidden">
                    <div className="container">
                        <div className="row text-center g-5">
                            <div className="col-12">
                                <h2 className="sub_heading text-white">Demo Trade With These Instruments</h2>
                                <p className="font_20 text-white">Diversify your trading with forex, stocks, commodities and more, without any initial capital outlay or deposits required.</p>
                                <a href="#/" className="btn btn_blue fw-semibold font_16 hero_demotrading_btn z-0">OPEN DEMO ACCOUNT
                                    <span><img src="assets/images/copytrading/hero-btn-icon.svg" alt="" className="hero_demotrading_img"/></span>
                                </a>
                            </div>
                            <div className="col-xl-12 wow animate__animated animate__fadeInUp">
                                <ul className="nav nav-tabs justify-content-center border-0 flex-wrap gap-xl-4 gap-2">
                                    <li
                                        className={`nav-item nav-link tabs_back text-dark trading_cards`}
                                    >
                                        <div>
                                            <img
                                                src="assets/images/instruments/tab1.svg"
                                                alt=""
                                                className="img_tab"
                                            />
                                            <div className="d-flex align-items-center justify-content-center">
                                                <p className="font_14 fw-semibold">Forex</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li
                                        className={`nav-item nav-link tabs_back text-dark trading_cards`}
                                    >
                                        <div>
                                            <img
                                                src="assets/images/instruments/tab2.svg"
                                                alt=""
                                                className="img_tab"
                                            />
                                            <div className="d-flex align-items-center justify-content-center">
                                                <p className="font_14 fw-semibold">Indices</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li
                                        className={`nav-item nav-link tabs_back text-dark trading_cards`}
                                    >
                                        <div>
                                            <img
                                                src="assets/images/instruments/tab4.svg"
                                                alt=""
                                                className="img_tab"
                                            />
                                            <div className="d-flex align-items-center justify-content-center">
                                                <p className="font_14 fw-semibold">Soft Commodities</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li
                                        className={`nav-item nav-link tabs_back text-dark trading_cards`}
                                    >
                                        <div>
                                            <img
                                                src="assets/images/instruments/tab6.svg"
                                                alt=""
                                                className="img_tab"
                                            />
                                            <div className="d-flex align-items-center justify-content-center">
                                                <p className="font_14 fw-semibold">ETFs</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li
                                        className={`nav-item nav-link tabs_back text-dark trading_cards`}
                                    >
                                        <div>
                                            <img
                                                src="assets/images/instruments/tab7.svg"
                                                alt=""
                                                className="img_tab"
                                            />
                                            <div className="d-flex align-items-center justify-content-center">
                                                <p className="font_14 fw-semibold">Share CFDs</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li
                                        className={`nav-item nav-link tabs_back text-dark trading_cards`}
                                    >
                                        <div>
                                            <img
                                                src="assets/images/instruments/tab8.svg"
                                                alt=""
                                                className="img_tab"
                                            />
                                            <div className="d-flex align-items-center justify-content-center">
                                                <p className="font_14 fw-semibold">Bonds</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space">
                    <div className="container">
                        <div className="text-center">
                            <h2 className="sub_heading mb-xl-5 mb-3">FREQUENTLY ASKED QUESTIONS</h2>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="false"
                                            aria-controls="collapseOne"
                                        >
                                            1 .  What is demo trading?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            Demo trading refers to trading with virtual funds on a Demo Account. Demo trading allows traders of all levels to learn and explore trading without having to deposit or use real money.
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                            Traders can trade a wide variety of markets with a Demo Account, and test out different strategies or techniques while analysing the market. Whether you're a beginner or an experienced trader, demo trading can help you improve your trading skills before entering the real market.
                                        </div>
                                    </div>

                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            2 . Is demo trading free?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse "
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            Yes, demo trading is free. When you open a Demo Account with Tradsify, you can access and use the platform at zero cost. The account is preloaded with virtual funds of $100,000, enabling you to practise trading and test strategies without risking real funds.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                        >
                                            3 .   How long should I use the demo trade?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            There is no specific time limit for traders to trade on a Demo Account. Traders are free to trade on the demo account for as long as they need. If the virtual funds in your Demo Account are depleted, you can simply reset the account to start trading again.
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                            When you are ready to trade the live markets with real capital, you can open a <a href="#/">Live Account here</a>.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour"
                                        >
                                            4 . Is trading with a Demo Account worth it?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFour"
                                        className="accordion-collapse collapse "
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            Using a Demo Account for trading offers value to both beginners and experienced traders. It presents a risk-free setting for traders to learn and practise trading strategies, explore different markets before starting to trade using real funds. There are also no fees charged for the opening and usage of a Demo Account.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive"
                                            aria-expanded="false"
                                            aria-controls="collapseFive"
                                        >
                                            5 .   Can you make money with a Demo Account?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFive"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingFive"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                        Traders cannot make real money using a Demo Account as it is a demonstration platform that uses simulated trading to allow traders to test out trading with virtual funds. These virtual funds are not real money and can only be used for paper trades in the Demo Account. Paper profits earned in the Demo Account are therefore virtual, and is not actual money.
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                        To make real money that can be withdrawn would require trading the live markets using real funds, with a <a href="#/">Live Account</a>.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </ScrollToTop>
        </>
    )
}