import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import { Link, redirect, useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const redirect = useNavigate()
  return (
    <>
      <ScrollToTop>
        <section className="section_space commingsoon_bg">
          <div className="container">
            <div className="text-center">
              <div className="my-5">
                <Link to="/" className="navbar-brand">
                  <img
                    src="/assets/images/logo/Tradsify.svg"
                    alt=""
                    className="img-fluid h-auto"
                  />
                </Link>
              </div>
              <div className="">
               <h1 className="page_not_found">
                404
               </h1>
              </div>
              <div>
                <h4 className="font_30 mt-xl-4 mt-3 fw-semibold mb-2">
                  We can't find that page
                </h4>
                <p className="font_14 text-secondary">
                  Sorry, it looks like the page you're looking for is no longer available or might never have existed. <br />
                   We apologise for any inconvenience and appreciate your understanding. <br />
                   Please select one of the options below:
                </p>
                <button className="btn btn_black me-2 mt-lg-2 mt-0" onClick={() => redirect("/")}>

                  Go To Home
                </button>
              </div>
            </div>
          </div>
        </section>
      </ScrollToTop>
    </>
  );
}
