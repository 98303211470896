export default function Service() {
  return (
    <>
      <section className="section_space pt-0">
        <div className="container">
          <div className="text-center">
            <h2 className="sub_heading mb-xl-5 mb-3  animate__fadeInUp"
             style={{
              animationDuration: '2s', // Customize duration
              animationDelay: '0.5s', // Customize delay
            }} >
              Trade Without Tradeoffs
            </h2>
          </div>
          <div className="row">
            <div className="col-xl-3 col-md-6">
              <div className="border-image card1_img">
                <h3 className="sub_small_heading  mb-md-4 mb-3 ">
                  Low Spreads
                  From 0.2 Pips
                </h3>
                <p className="font_15">
                  Spreads affect your profits the most. The lower the spreads, the higher the profits.
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="border-image card1_img card2_img">
                <h3 className="sub_small_heading mb-md-4 mb-3 ">
                  Quick Withdrawals
                </h3>
                <p className="font_15">
                  Easy withdrawals and deposits via Netbanking, e-wallets, or digital currencies.
                </p>
              </div>
            </div>

            <div className="col-xl-3 col-md-6">
              <div className="border-image card1_img card3_img">
                <h3 className="sub_small_heading  mb-md-4 mb-3 ">
                  Caring Customer Support 24/7
                </h3>
                <p className="font_15">
                  We speak English and 10+ other languages. Whatever question you might have, drop us a line.
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-md-6">
              <div className="border-image card1_img card4_img">
                <h3 className="sub_small_heading  mb-md-4 mb-3 ">
                  No Commission
                </h3>
                <p className="font_15">
                  Pay no trading fees, swaps, or payment method charges. We cover everything.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

    </>
  );
}
