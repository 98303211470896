export default function Faq() {
    return (
        <>
            <section className="section_space tradsify_faq">
                <div className="container">
                    <div className="text-center">
                        <h2 className="sub_heading mb-xl-5 mb-3
                         wow animate__animated animate__fadeInUp
                        ">Frequently Asked Questions</h2>
                        <div className="accordion" id="accordionExample">
                            <div className="accordion-item border-0 mb-md-4 mb-3">
                                <h2 className="accordion-header" id="headingOne">
                                    <button
                                        className="accordion-button collapsed font_20"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseOne"
                                        aria-expanded="false"
                                        aria-controls="collapseOne"
                                    >
                                      1 .  What is tradsify?
                                    </button>
                                </h2>
                                <div
                                    id="collapseOne"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body font_16">
                                        tradsify is a trading platform designed to simplify and elevate your trading experience.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item border-0 mb-md-4 mb-3">
                                <h2 className="accordion-header" id="headingTwo">
                                    <button
                                        className="accordion-button collapsed font_20"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseTwo"
                                        aria-expanded="false"
                                        aria-controls="collapseTwo"
                                    >
                                       2 .  Is tradsify secure?
                                    </button>
                                </h2>
                                <div
                                    id="collapseTwo"
                                    className="accordion-collapse collapse "
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body font_16">
                                        Yes, we take security very seriously and have implemented robust measures to protect your account and data.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item border-0 mb-md-4 mb-3">
                                <h2 className="accordion-header" id="headingThree">
                                    <button
                                        className="accordion-button collapsed font_20"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseThree"
                                        aria-expanded="false"
                                        aria-controls="collapseThree"
                                    >
                                      3 .   What services does tradsify offer?
                                    </button>
                                </h2>
                                <div
                                    id="collapseThree"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body font_16">
                                        We offer auto trading, portfolio management, and trade export services.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item border-0">
                                <h2 className="accordion-header" id="headingFour">
                                    <button
                                        className="accordion-button collapsed font_20"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#collapseFour"
                                        aria-expanded="false"
                                        aria-controls="collapseFour"
                                    >
                                       4 .  How do I get started with tradsify?
                                    </button>
                                </h2>
                                <div
                                    id="collapseFour"
                                    className="accordion-collapse collapse"
                                    aria-labelledby="headingFour"
                                    data-bs-parent="#accordionExample"
                                >
                                    <div className="accordion-body font_16">
                                        Simply sign up, deposit funds, and choose your service. Our step-by-step guide will walk you through the process.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
