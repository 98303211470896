import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { useState, useRef } from "react";
import { Link } from "react-router-dom";

export default function DepositBonus() {
    const [selectedCountry, setSelectedCountry] = useState("");
    const containerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - containerRef.current.offsetLeft);
        setScrollLeft(containerRef.current.scrollLeft);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - containerRef.current.offsetLeft;
        const walk = (x - startX) * 1.5; // Adjust scroll speed
        containerRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUpOrLeave = () => {
        setIsDragging(false);
    };
    const countries = [
        "United States",
        "Canada",
        "United Kingdom",
        "Australia",
        "India",
        "Germany",
        "France",
        "Japan",
        "China",
        "Brazil",
    ];

    return (
        <>
            <ScrollToTop>
                <Header />
                <section className="section_space deposit_bonus">
                    <div className="container">
                        <div className="row align-items-center g-4">
                            <div className="col-lg-6 col-md-6 order-1 order-md-1 text-center text-md-start">
                                <h2 className="main_heading mb-2">
                                    Power up with Deposit Bonus
                                </h2>
                                <Link target="_blank" to="https://my.tradsify.com/en/auth/sign-in">
                                <button className="btn btn_blue px-lg-5 deposit_button font_16">DEPOSIT NOW</button>
                                </Link>
                                <p className="font_16 mt-3">*Terms & Conditions apply.</p>
                            </div>
                            <div className="col-lg-6 col-md-6 order-2 order-md-2 text-center">
                                <img
                                    src="assets/images/depositbonus/deposit-bonus.webp"
                                    alt="Deposit Bonus"
                                    className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space">
                    <div className="container">
                        <div className="row g-4 text-center">
                            <div className="main_trade text-center mb-4">
                                <h2 className="sub_heading mb-4">Trade More with Deposit Bonus</h2>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="trades_img mb-3">
                                    <img src="assets/images/depositbonus/trade-with-deposit-bonus-img1.webp" alt="" className="img-fluid" />
                                </div>
                                <div className="trade_text text-center">
                                    <span className="d-flex align-items-center gap-2 mb-2 justify-content-center">
                                        <img src="assets/images/depositbonus/reward-get-check.svg" alt="" />
                                        <p className="sub_small_heading mb-0">Increase Trading Power</p>
                                    </span>
                                    <p className="font_16">Boost your trading potential with additional funds.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="trades_img mb-3">
                                    <img src="assets/images/depositbonus/trade-with-deposit-bonus-img2.webp" alt="" className="img-fluid" />
                                </div>
                                <div className="trade_text text-center">
                                    <span className="d-flex align-items-center gap-2 mb-2 justify-content-center">
                                        <img src="assets/images/depositbonus/reward-get-check.svg" alt="" />
                                        <p className="sub_small_heading mb-0">Opportunity for Diversification</p>
                                    </span>
                                    <p className="font_16">Utilise the extra bonus to explore different trading instruments.</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="trades_img mb-3">
                                    <img src="assets/images/depositbonus/trade-with-deposit-bonus-img3.webp" alt="" className="img-fluid" />
                                </div>
                                <div className="trade_text text-center">
                                    <span className="d-flex align-items-center gap-2 mb-2 justify-content-center">
                                        <img src="assets/images/depositbonus/reward-get-check.svg" alt="" />
                                        <p className="sub_small_heading mb-0">Withdraw Anytime</p>
                                    </span>
                                    <p className="font_16">The returns earned from trading can be withdrawn anytime, without additional requirements.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space deposit_formbg">
                    <div className="container">
                        <div className="row g-4">
                            <div className="col-lg-6">
                                <h2 className="sami_medium_heading mb-3">Check your promotional eligibility</h2>
                                <p className="font_16">Check the promotional eligibility for your country of residency to see how much bonus you can earn.</p>
                            </div>
                            <div className="col-lg-6">
                                <form className="country_form text-center">
                                    <div className="deposit_email position-relative mb-4">
                                        <input className="deposit_medium" type="email" name="email" id="email" autocomplete="off" placeholder="Email" />
                                    </div>
                                    <div className="mb-4">
                                        <select
                                            id="countrySelect"
                                            className="form-select deposit_country"
                                            value={selectedCountry}
                                            onChange={(e) => setSelectedCountry(e.target.value)}
                                        >
                                            <option value="" disabled>
                                                -- Select Country --
                                            </option>
                                            {countries.map((country, index) => (
                                                <option key={index} value={country}>
                                                    {country}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <button type="submit" className="btn btn_blue px-lg-5 deposits_button mb-4">CHECK NOW</button>
                                    <p className="font_16">Not a client? 
                                        <a target="_blank" href="https://my.tradsify.com/en/auth/sign-in">Open live account</a> now.</p>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space">
                    <div class="container">
                        <div className="row g-3">
                            <h1 className="sub_heading text-center">How to Get Your Credit Bonus?</h1>
                            <div className="col-lg-12">
                                <ul class="nav nav-tabs mt-4 justify-content-center mb-4" id="myTab" role="tablist">
                                    <li class="nav-item deposit_item" role="presentation">
                                        <button class="nav-link deposit_tab_button font_20 active" id="new-user-tab" data-bs-toggle="tab" data-bs-target="#new-user" type="button" role="tab" aria-controls="new-user" aria-selected="true">New User</button>
                                    </li>
                                    <li class="nav-item deposit_item" role="presentation">
                                        <button class="nav-link deposit_tab_button font_20" id="existing-user-tab" data-bs-toggle="tab" data-bs-target="#existing-user" type="button" role="tab" aria-controls="existing-user" aria-selected="false">Existing User</button>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content mt-4" id="myTabContent">
                                <div class="tab-pane fade show active" id="new-user" role="tabpanel" aria-labelledby="new-user-tab">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6 g-3">
                                            <div class="step-circle">1</div>
                                            <h5 className="sub_small_heading">Open Live Trading Account</h5>
                                            <p className="font_16">Sign up for a live account to begin your trading journey.</p>
                                        </div>
                                        <div class="col-lg-4 col-md-6 g-3">
                                            <div class="step-circle">2</div>
                                            <h5 className="sub_small_heading">Opt-In to Promotion</h5>
                                            <p className="font_16">Opt-in via the client portal to activate the deposit bonus promotion.</p>
                                        </div>
                                        <div class="col-lg-4 col-md-6 g-3">
                                            <div class="step-circle">3</div>
                                            <h5 className="sub_small_heading">Deposit and Get Rewarded</h5>
                                            <p className="font_16">Make your first deposit and claim your deposit bonus from us.</p>
                                        </div>
                                    </div>
                                    <div class="text-center d-flex justify-content-center mt-4">
                                        <button class="btn btn_blue px-lg-5" type="button">OPEN LIVE ACCOUNT</button>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="existing-user" role="tabpanel" aria-labelledby="existing-user-tab">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6 g-3">
                                            <div class="step-circle">1</div>
                                            <h5 className="sub_small_heading">Log in to Your Account</h5>
                                            <p className="font_16">Log in to the Tradsify client portal.</p>
                                        </div>
                                        <div class="col-lg-4 col-md-6 g-3">
                                            <div class="step-circle">2</div>
                                            <h5 className="sub_small_heading">Opt-In to Promotion</h5>
                                            <p className="font_16">Opt-in via the client portal to activate the deposit bonus promotion.</p>
                                        </div>
                                        <div class="col-lg-4 col-md-6 g-3">
                                            <div class="step-circle">3</div>
                                            <h5 className="sub_small_heading">Deposit and Get Rewarded</h5>
                                            <p className="font_16">Make your first deposit and claim your deposit bonus from us.</p>
                                        </div>
                                    </div>
                                    <div class="text-center d-flex justify-content-center mt-4">
                                        <button class="btn btn_blue px-lg-5" type="button">Opt-in Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space testimonials mb-5">
                    <h2 className="sub_heading text-center mb-4">
                        Here's What Our Users Have To Say
                    </h2>
                    <div
                        className="testimonials-container"
                        ref={containerRef}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUpOrLeave}
                        onMouseLeave={handleMouseUpOrLeave}
                    >
                        {[
                            {
                                title: "Trustworthy, quality customer relations, patience and understanding when interacting with customers.F",
                                content:
                                    "I loved how they gave me a bonus that I have been trading on until I withdraw my profits. Customer care is top-notch and I really loved how they took me step by step on customer service. Would recommend all the time.",
                                user: "@Grace betty",
                            },
                            {
                                title: "They are very good and responsive they...",
                                content:
                                    "They are very good and responsive. They got back to me within an hour, and the withdrawal process has been fast—no longer than a day! I received mine. They also gave me an amazing bonus! Great experience, thank you.",
                                user: "@Sami Hashim",
                            },
                            {
                                title: "Good broker",
                                content:
                                    "Good broker, low spreads, fast execution, regulated broker. Clients' funds are held in a segregated account with a lot of functionality like copy trading and PAMM accounts.",
                                user: "@Dan",
                            },
                            {
                                title: "I never had a problem with this...",
                                content:
                                    "I never had a problem with this company! I approve them for withdrawals and deposits. They have good bonuses to use and are very fast to reply to any question.",
                                user: "@Crk Forex Signals",
                            },
                            {
                                title: "What i love about tradsify is that…",
                                content:
                                    "What i love about tradsify is that there are transparent, i fill like my money is safe with them. What do i love the most,:when you withdraw more then your initial deposit, they transfer money to your bank account.There is also deposit bonus when you open your account.",
                                user: "@Joel Du Burundi",
                            },
                            {
                                title: "They can rescue you with free money",
                                content:
                                    "I have not yet made a withdrawal but so far its the best place for me. you can trade and also win free voucher rebate, cash and trade loss which i rarely see anywhere. my review is based on my experience so far keep it up",
                                user: "@Joel Du Burundi",
                            },
                            {
                                title: "I never had a problem with this...",
                                content:
                                    "I never had a problem with this company! I approve them for withdrawals and deposits. They have good bonuses to use and are very fast to reply to any question.",
                                user: "@Emmanuel Amartey",
                            },
                            {
                                title: "Efficient and user friendly",
                                content:
                                    "Withdrawed once before and it was super efficient. Besides, it is super user friendly and provides lots of cash back too.",
                                user: "@Nic Yong",
                            },
                            {
                                title: "Recommended",
                                content:
                                    "Excellent service and very easy to navigate around the client portal. My queries through webchat were resolved very quickly. Highly recommended.",
                                user: "@Mr C Jethwa",
                            },
                        ].map((testimonial, index) => (
                            <div key={index} className="testimonial-card">
                                <h3 className="sub_small_heading deposit_test">{testimonial.title}</h3>
                                <p className="font_16 deposit_test">{testimonial.content}</p>
                                <span className="font_16 deposit_test">{testimonial.user}</span>
                            </div>
                        ))}
                    </div>

                </section>
                {/* <section className="section_space">
                    <div className="container">
                        <div className="deposit_parent">
                            <div className="deposit_left">
                                <h2 className="small_heading text-white" >Trade Anywhere, Anytime</h2>
                                <p className="font_16 text-white">Gain instant access to 1,000+ CFD products with a Tradsify live account, including forex, shares, indices, gold and more.</p>
                                <p className="font_16 text-white">Trade on both desktop or mobile with Tradsify, giving you direct and powerful access to global markets. Stay connected with the markets on the move, download the App today.</p>
                                <div className="deposit_icon d-flex gap-3">
                                    <a href="#/">
                                        <img width="195.75" height="58" src="assets/images/depositbonus/deposit_icon1.png" alt="download" />
                                    </a>
                                    <a href="#/">
                                        <img width="195.75" height="58" src="assets/images/depositbonus/deposit_icon2.svg" alt="download" />
                                    </a>
                                </div>
                            </div>
                            <div className="deposit_right"></div>
                        </div>
                    </div>
                </section> */}
                <Footer />
            </ScrollToTop>
        </>
    )
}