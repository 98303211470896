import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { useState, useRef, useEffect } from 'react';
import { Autoplay, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";


export default function PlatformsOverview() {
    const [activeIndex, setActiveIndex] = useState(0);
    const swiperRef = useRef(null);
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    // Handle image click to change Swiper slide
    // const handleImageClick = (index) => {
    //     setActiveIndex(index);
    //     if (swiperRef.current && swiperRef.current.swiper) {
    //         swiperRef.current.swiper.slideToLoop(index);
    //     }
    // };
    useEffect(() => {
        // Ensure Swiper initializes after the buttons are available
        const swiper = document.querySelector(".swiper").swiper;
        swiper.params.navigation.prevEl = prevRef.current;
        swiper.params.navigation.nextEl = nextRef.current;
        swiper.navigation.init();
        swiper.navigation.update();


    }, []);

    const slides = [
        {
            title: "Tradsify App",
            description: "Access global markets anywhere, anytime",
            buttonText: "JOIN NOW",
        },
        {
            title: "MetaTrader 5",
            description: "Experience the cutting-edge MT5 platform trading platform",
            buttonText: "JOIN NOW",
        },
        {
            title: "Copy Trading",
            description: "Replicate trades like a master with the tap of a button",
            buttonText: "JOIN NOW",
        },
        {
            title: "Demo Trading",
            description: "Practise your trading skills with virtual funds",
            buttonText: "JOIN NOW",
        },
    ];
    // const images = [
    //     "assets/images/platforms-overview/tabimg1.webp",
    //     "assets/images/platforms-overview/tabimg2.webp",
    //     "assets/images/platforms-overview/tabimg3.webp",
    //     "assets/images/platforms-overview/tabimg4.webp",
    //     "assets/images/platforms-overview/tabimg5.webp",
    //     "assets/images/platforms-overview/tabimg6.webp",
    // ];

    return (
        <>
            <ScrollToTop>
                <Header />
                <section className="section_space contact_us">
                    <div className="container">
                    <div className="row position-relative justify-content-center g-4">
                {/* <div className="col-12">
                    <div className="trading-platform-banner-top">
                        <div className="swiper-box d-flex flex-wrap justify-content-center">
                            {images.map((img, index) => (
                                <div
                                    key={index}
                                    className={`imgList ${index === activeIndex ? "active" : ""}`}
                                    onClick={() => handleImageClick(index)}
                                    style={{ cursor: "pointer" }}
                                >
                                    <img src={img} className="platform-img" alt="Trading Platform" />
                                </div>
                            ))}
                        </div>
                    </div>
                </div> */}
                <div className="col-12 col-md-10 col-lg-6">
                    <div ref={prevRef} className="platforms-swiper-button-prev">
                        ❮
                    </div>
                    <Swiper
                        ref={swiperRef}
                        modules={[Navigation, Autoplay]}
                        spaceBetween={30}
                        slidesPerView={1}
                        autoplay={{ delay: 3000, disableOnInteraction: false }}
                        loop={true}
                        navigation={{ prevEl: prevRef.current, nextEl: nextRef.current }}
                        breakpoints={{
                            768: { slidesPerView: 1 },
                            1024: { slidesPerView: 1 },
                        }}
                        onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
                    >
                        {slides.map((slide, index) => (
                            <SwiperSlide key={index}>
                                <div className="text-center p-3 p-md-5">
                                    <p className="font_16 fw-bold">Live Trading Platform</p>
                                    <h2 className="main_heading">{slide.title}</h2>
                                    <p className="font_16">{slide.description}</p>
                                    <button className="btn btn_blue rounded-pill px-3 px-md-4 py-2">
                                        {slide.buttonText}
                                    </button>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    <div ref={nextRef} className="platforms-swiper-button-next">
                        ❯
                    </div>
                </div>
            </div>


                    </div>
                </section>

                <section className="section_space">
                    <div className="container">
                        <div className="row g-3">
                            <h1 className="sub_heading text-center">How to Get Your Credit Bonus?</h1>
                            <div className="col-lg-12">
                                <ul className="nav nav-tabs mt-4 justify-content-center d-flex flex-wrap mb-4" id="myTab" role="tablist">
                                    <li className="nav-item deposit_item" role="presentation">
                                        <button className="nav-link deposit_tab_button font_20 active" id="new-user-tab" data-bs-toggle="tab" data-bs-target="#new-user" type="button" role="tab" aria-controls="new-user" aria-selected="true">Tradsify App</button>
                                    </li>
                                    <li className="nav-item deposit_item" role="presentation">
                                        <button className="nav-link deposit_tab_button font_20" id="option-3-tab" data-bs-toggle="tab" data-bs-target="#option-3" type="button" role="tab" aria-controls="option-3" aria-selected="false">MetaTrader 5</button>
                                    </li>
                                    <li className="nav-item deposit_item" role="presentation">
                                        <button className="nav-link deposit_tab_button font_20" id="option-5-tab" data-bs-toggle="tab" data-bs-target="#option-5" type="button" role="tab" aria-controls="option-5" aria-selected="false">Copy Trading</button>
                                    </li>
                                    <li className="nav-item deposit_item" role="presentation">
                                        <button className="nav-link deposit_tab_button font_20" id="option-6-tab" data-bs-toggle="tab" data-bs-target="#option-6" type="button" role="tab" aria-controls="option-6" aria-selected="false">Demo Trading</button>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content mt-4" id="myTabContent">
                                <div className="tab-pane fade show active" id="new-user" role="tabpanel" aria-labelledby="new-user-tab">
                                    <div className="row justify-content-center g-4">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="tab1_img">
                                                <img src="assets/images/platforms-overview/tab1.webp" alt="" className="tab_image" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="tab1_text">
                                                <p className="small_heading">Download our all-in-one mobile app to trade on the go, accessible from the Philippines or beyond. Here's what our app offers.</p>
                                                <p className="font_16 postion-relative tab_paragraph"><span className="tab1_icon d-flex justify-content-around align-items-center">1</span>Trade at your convenience and gain easy access to over 1,000 tradable instruments.</p>
                                                <p className="font_16 postion-relative tab_paragraph"><span className="tab1_icon d-flex justify-content-around align-items-center">2</span>Stay updated with our daily market commentary, global financial news, and comprehensive market analysis.</p>
                                                <p className="font_16 postion-relative tab_paragraph"><span className="tab1_icon d-flex justify-content-around align-items-center">3</span>Practise trading and refine your strategies using the Demo Account with $100,000 virtual credit.</p>
                                                <p className="font_16 postion-relative tab_paragraph"><span className="tab1_icon d-flex justify-content-around align-items-center">4</span>Explore copy trading within the app to replicate trades and learn from high-performing traders.</p>
                                                <p className="font_16 postion-relative tab_paragraph"><span className="tab1_icon d-flex justify-content-around align-items-center">5</span>Access all live webinars and send questions directly to our professional educators.</p>
                                                <a href="#/"><button className="btn btn_blue tab1_btn">DISCOVER MORE</button></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="option-3" role="tabpanel" aria-labelledby="option-3-tab">
                                    <div className="row justify-content-center g-4">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="tab1_img">
                                                <img src="assets/images/platforms-overview/tab2.webp" alt="" className="tab_image" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="tab1_text">
                                                <p className="small_heading">Download popular trading platform MetaTrader 5 (MT5) today</p>
                                                <p className="font_16 postion-relative tab_paragraph"><span className="tab1_icon d-flex justify-content-around align-items-center">1</span>Utilise over 44 graphical objects such as geometric shapes, channels, Gann, Fibonacci, and Elliott tools to improve your charting.</p>
                                                <p className="font_16 postion-relative tab_paragraph"><span className="tab1_icon d-flex justify-content-around align-items-center">2</span>Tap on 38 built-in technical indicators and access 2,000+ free custom indicators for comprehensive market analysis and price assessment.</p>
                                                <p className="font_16 postion-relative tab_paragraph"><span className="tab1_icon d-flex justify-content-around align-items-center">3</span>Enhance your trading analysis with 21-time frames, allowing you to examine quote dynamics, merge indicators, and utilise objects across various periods.</p>
                                                <p className="font_16 postion-relative tab_paragraph"><span className="tab1_icon d-flex justify-content-around align-items-center">4</span>Unleash your trading potential by creating your very own Expert Advisor (EA) using the robust MQL5 language.</p>
                                                <p className="font_16 postion-relative tab_paragraph"><span className="tab1_icon d-flex justify-content-around align-items-center">5</span>Test trading strategies using multiple threads for parallel processing.</p>
                                                <a href="#/"><button className="btn btn_blue tab1_btn">DISCOVER MORE</button></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="option-5" role="tabpanel" aria-labelledby="option-5-tab">
                                    <div className="row justify-content-center g-4">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="tab1_img">
                                                <img src="assets/images/platforms-overview/tab3.webp" alt="" className="tab_image" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="tab1_text">
                                                <p className="small_heading">Replicate the trades of high-performing traders when you try copy trading in our mobile app</p>
                                                <p className="font_16 postion-relative tab_paragraph"><span className="tab1_icon d-flex justify-content-around align-items-center">1</span>Filter and find signal providers to copy by using popular filter combinations, or applying filters that align with your preferences and requirements.</p>
                                                <p className="font_16 postion-relative tab_paragraph"><span className="tab1_icon d-flex justify-content-around align-items-center">2</span>Expand your trading horizons by copying and learning from more experienced signal providers, to gain exposure to a wider range of trading ideas.</p>
                                                <p className="font_16 postion-relative tab_paragraph"><span className="tab1_icon d-flex justify-content-around align-items-center">3</span>Save time by replicating the strategies of seasoned signal providers to automate your trading activities.</p>
                                                <a href="#/"><button className="btn btn_blue tab1_btn">DISCOVER MORE</button></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="option-6" role="tabpanel" aria-labelledby="option-6-tab">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-6 col-md-6">
                                            <div className="tab1_img">
                                                <img src="assets/images/platforms-overview/tab4.webp" alt="" className="tab_image" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6">
                                            <div className="tab1_text">
                                                <p className="small_heading">Test out your trading strategies and practise with our free Demo Account</p>
                                                <p className="font_16 postion-relative tab_paragraph"><span className="tab1_icon d-flex justify-content-around align-items-center">1</span>Access up to $100,000 in virtual funds to refine your trading skills under demo conditions, familiarising yourself with the platform and gaining confidence before transitioning to live trading.</p>
                                                <p className="font_16 postion-relative tab_paragraph"><span className="tab1_icon d-flex justify-content-around align-items-center">2</span>Refine your trading skills with a demo account, offering a risk-free environment for traders of all levels.</p>
                                                <p className="font_16 postion-relative tab_paragraph"><span className="tab1_icon d-flex justify-content-around align-items-center">3</span>Gain a deeper understanding of the ever-changing market landscape and products without having to risk any real funds.</p>
                                                <a href="#/"><button className="btn btn_blue tab1_btn">DISCOVER MORE</button></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space">
                    <div className="container">
                        <div className="text-center">
                            <h2 className="sub_heading mb-xl-5 mb-3">Frequently Asked Questions</h2>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="false"
                                            aria-controls="collapseOne"
                                        >
                                            1 .  What is the Tradsify app?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            The Tradsify app is a mobile app that allows users to gain access to a variety of trading assets. It features advanced tools for every trading style, a user-friendly interface on all live trading platforms, swift account setup, and seamless fund management.
                                        </div>
                                    </div>

                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            2 . What is the minimum deposit for the Tradsify app?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse "
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            The minimum deposit required by Tradsify to start trading on the app is $50. This minimum deposit threshold remains consistent across all types of accounts we offer, ensuring an affordable start for every trader with Tradsify.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                        >
                                            3 .   How do I withdraw from Tradsify?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            Clients can securely withdraw their funds with just four simple steps:<br />
                                            1. Log in to the Tradsify app and navigate to the profile tab.<br />
                                            2. Click on the withdraw button to initiate the process.<br />
                                            3. Enter the withdrawal amount and select your preferred withdrawal method.<br />
                                            4. Finally, click proceed to finalise the withdrawal process.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour"
                                        >
                                            4 . How do I get Protrader?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFour"
                                        className="accordion-collapse collapse "
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            1. Register. Open a live account with Tradsify. Register a Protrader account and submit your application.
                                            2. Fund. Fund your account using a wide range of funding methods.
                                            3. Trade. Access 1,000+ CFD instruments across all asset types on MT5.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive"
                                            aria-expanded="false"
                                            aria-controls="collapseFive"
                                        >
                                            5 .   What can you do with the Protrader live trading platform?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFive"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingFive"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            The Tradsify Protrader live trading platform, powered by TradingView, offers all clients access to hundreds of the most popular indicators, more than 50 technical drawing tools, and a comprehensive suite of tools for in-depth market analysis that covers the most popular trading systems and strategies.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </ScrollToTop>
        </>
    )
}