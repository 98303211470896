import React, { useEffect, useRef } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import Faq from "../component/Faq";
import Service from "../component/Service";
import Ourcommunity from "../component/Ourcommunity";
import Hero from "../component/Hero";
import Get_Apps from "../component/Get_apps";
import ScrollToTop from "../component/ScrollToTop";
import { Link } from "react-router-dom";
import Swipercomponent from "../component/Swipercomponent";
import HeroSwiper from "../component/HeroSwiper";
// import { Controller, Scene } from "react-scrollmagic";
import WOW from 'wowjs';
import $ from 'jquery';
import { debounce } from 'lodash';
import TradeSmarterSection from "../component/TradeSmarterSection";
import NewYearPopup from "../component/NewYearPopup";

export default function Home() {

  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, [])
  // useEffect(() => {
  //   const windowWidth = $(window).width();


  //   if (windowWidth >= 1440) {
  //     $(document).ready(function () {
  //       const $contentSection = $('.trade-smarter');
  //       const $imageElement = $('.my_hard_img');
  //       const $tradesmartercontent = $('.trade-smarter-content');
  //       const $tradesmarterbar = $('.trade-smarter-bar');

  //       const stickyClass = 'position-sticky';

  //       const startImage = "assets/images/home-trade-smarter/icon-0001.webp"; 
  //       const endImage = "assets/images/home-trade-smarter/icon-0031.webp";  // Final Image

  //       function checkVisibility() {
  //         const contentRect = $contentSection[0].getBoundingClientRect();
  //         const windowHeight = $(window).height();

  //         if (contentRect.top <= windowHeight && contentRect.bottom >= 0) {
  //           $contentSection.addClass(stickyClass);
  //         } 
  //       }

  //       $(window).scroll(function () {
  //         const windowWidth = $(window).width();

  //         if (windowWidth >= 992 && windowWidth < 1200 || windowWidth >= 1200 || windowWidth >= 1400) {
  //           const contentRect = $contentSection[0].getBoundingClientRect();
  //           const windowHeight = $(window).height();

  //           if (contentRect.top <= windowHeight && contentRect.bottom >= 1200) {
  //             const scrollProgress = Math.min(Math.max((windowHeight - contentRect.top) / (contentRect.height + windowHeight), 0), 1);
  //             console.log(scrollProgress, "scrollProgress")
  //             const imageIndex = Math.floor(scrollProgress * 30);

  //             $imageElement.attr('src', `assets/images/home-trade-smarter/icon-00${String(imageIndex + 1).padStart(2, '0')}.webp`);

  //             const translateValue = scrollProgress * -900;  
  //             $tradesmartercontent.css('transform', `translateY(${translateValue}px)`);
  //             $tradesmarterbar.css({
  //               "transform": `translateY(${translateValue}px)`,
  //               "height": "1301.88px"
  //             })
  //           }
  //           checkVisibility();
  //         }

  //       });
  //     });
  //   }

  // }, [])

  const faqData = [
    {
      id: 1,
      question: "What is tradsify?",
      answer:
        "tradsify is a trading platform designed to simplify and elevate your trading experience.",
    },
    {
      id: 2,
      question: "Is tradsify secure?",
      answer:
        "Yes, we take security very seriously and have implemented robust measures to protect your account and data.",
    },
    {
      id: 3,
      question: "What services does tradsify offer?",
      answer:
        "We offer auto trading, portfolio management, and trade export services.",
    },
    {
      id: 4,
      question: "How do I get started with tradsify?",
      answer:
        "Simply sign up, deposit funds, and choose your service. Our step-by-step guide will walk you through the process.",
    },
  ];
  return (
    <>
      <ScrollToTop>
        <Header />
        <Hero />
        <HeroSwiper />
        <Service />
        <section className="section_space pt-0">
          <div className="container">
            <div className="row align-items-center g-xl-3 g-5">
              <div className="col-lg-6 overflow-hidden">
                <img
                  src="assets/images/hero/extra2.svg"
                  alt=""
                  className="img-fluid w-100 trend_img  wow animate__animated animate__fadeInUp"
                />
              </div>
              <div className="col-lg-6">
                <h2 className="sami_medium_heading mb-xl-3 mb-2
                 wow animate__animated animate__fadeInUp">
                  Trend Indicators And
                  <br /> Social Metrics
                </h2>
                <p className="font_15  wow animate__animated animate__fadeInUp">
                  provides unique indicators like social metrics and our
                  proprietary tradsify Trend Indicator. Many major market
                  movements are dictated by socio-cultural movements in the
                  space. Social signals are one of the most important factors in
                  this space as value is driven by perception first and
                  technical factors second.
                </p>
                <p className="font_15 mb-0  wow animate__animated animate__fadeInUp">
                  provides unique indicators like social metrics and our
                  proprietary tradsify Trend Indicator. Many major market
                  movements are dictated by socio-cultural movements in the
                  space. Social signals are one of the most important factors in
                  this space as value is driven by perception first and
                  technical factors second.
                </p>
              </div>
            </div>
          </div>
        </section>
        <Swipercomponent />
        <section className="section_space">
          <div class="container">
            <div className="mb-xl-5 mb-lg-4 mb-3">
              <h2 className="sub_heading text-center wow animate__animated animate__fadeInUp">
                Get Real-Time Updates on <br className="d-lg-block d-none" />
                Our CFD Markets
              </h2>
              <p className="font_16 text-center mb-0 wow animate__animated animate__fadeInUp">
                Trade 1,000+ CFDs on forex, shares, indices, commodities, ETFs,
                bonds <br className="d-lg-block d-none" />
                and more with a single CFD trading account.
              </p>
            </div>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="forex"
                role="tabpanel"
                aria-labelledby="forex-tab"
              >
                <div className="container">
                  <div className="row align-items-strech g-lg-5 g-md-3 g-0">
                    <div className="col-lg-6 mb-4 mb-lg-0">
                      <div className="position-relative h-100  wow animate__animated animate__fadeInLeft">
                        <div className="custom-table-wrapper table-responsive h-100">
                          <iframe
                            src="https://in.widgets.investing.com/live-currency-cross-rates?theme=lightTheme&cols=bid,change,changePerc&pairs=1,3,2,5,10"
                            width="100%"
                            height="100%"
                            frameBorder={0}
                            allowTransparency="true"
                            marginWidth={0}
                            marginHeight={0}
                          />
                        </div>
                        <div className="text-muted font_14 mt-xl-4">
                          LIVE (INDICATIVE) UPDATED: 16:13:35 GMT+5.5
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 pt-3 pt-lg-0">
                      <div className=" wow animate__animated animate__fadeInRight">
                        <h5 className="small_heading fw-bold">FOREX</h5>
                        <h3 className="font_20 mb-lg-4 mb-2 fw-base">
                          Trade the world's most popular forex and currency pairs
                          with a trusted forex broker.
                        </h3>

                        <p className="font_15">
                          Access 40+ major, minor, and exotic forex pairs with low
                          spreads from 0.01. Start trading the world’s leading CFD
                          products such as Forex in EUR/USD, GBP/USD, and USD/JPY.
                        </p>
                        <p className="font_15">
                          To trade CFD products such as Forex, you will need to
                          open a live trading account to access the global forex
                          markets whether you are from Thailand, Philippines, or
                          beyond.
                        </p>
                        <button className="btn btn_black text-uppercase mt-lg-4 mt-3">
                          learn more
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="indices"
                role="tabpanel"
                aria-labelledby="indices-tab"
              >
                <div className="container">
                  <div className="row align-items-center g-lg-5 g-md-3 g-0">
                    <div className="col-lg-6 mb-4 mb-lg-0">
                      <div className="custom-table-wrapper table-responsive">
                        <table className="table text-center mb-0">
                          <thead>
                            <tr className="table-header font_15">
                              <th className="trade_th">Symbol</th>
                              <th className="trade_th">Buy</th>
                              <th className="trade_th">Change</th>
                              <th className="trade_th">%</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">NAS100</td>
                              <td className="trade_td">22195.750</td>
                              <td className="text-success trade_td">+15.900</td>
                              <td className="text-success trade_td">
                                ▲ +0.070%
                              </td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">DJ30</td>
                              <td className="trade_td">43878.350</td>
                              <td className="text-success trade_td">+31.150</td>
                              <td className="text-success trade_td">
                                ▲ 0.070%
                              </td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">DAX40</td>
                              <td className="trade_td"></td>
                              <td className="text-success trade_td"></td>
                              <td className="text-success trade_td">▲</td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">SP500</td>
                              <td className="trade_td">6096.470</td>
                              <td className="text-success trade_td">+3.220</td>
                              <td className="text-success trade_td">
                                ▲ 0.050%
                              </td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">HK500</td>
                              <td className="trade_td">19733.000</td>
                              <td className="text-success trade_td">+57.000</td>
                              <td className="text-success trade_td">
                                ▲ 0.280%
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="text-muted font_14 mt-xl-4">
                        LIVE (INDICATIVE) UPDATED: 16:13:35 GMT+5.5
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <h5 className="small_heading fw-bold">INDICES</h5>
                      <h3 className="font_20 mb-lg-4 mb-2 fw-base">
                        Open positions and diversify your portfolio with global
                        indices.
                      </h3>

                      <p className="font_15">
                        Leverage on the volatility of the largest stock market
                        indices in the world like SP500, DAX, NAS100, and DJ30.
                        Try CFD product trading in indices with Tradsify today.
                      </p>
                      <p className="font_15">
                        To trade CFD product such as indices, you will need to
                        open a live trading account to access the global indices
                        markets whether you are from Thailand, Philippines or
                        beyond.
                      </p>
                      <button className="btn btn_black text-uppercase mt-lg-4 mt-3">
                        learn more
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="gold"
                role="tabpanel"
                aria-labelledby="gold-tab"
              >
                <div className="container">
                  <div className="row align-items-center g-lg-5 g-md-3 g-0">
                    <div className="col-lg-6 mb-4 mb-lg-0">
                      <div className="custom-table-wrapper table-responsive">
                        <table className="table text-center mb-0">
                          <thead>
                            <tr className="table-header font_15">
                              <th className="trade_th">Symbol</th>
                              <th className="trade_th">Buy</th>
                              <th className="trade_th">Change</th>
                              <th className="trade_th">%</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">XAUEUR</td>
                              <td className="trade_td">2525.740</td>
                              <td className="text-success trade_td">+2.790</td>
                              <td className="text-success trade_td">
                                ▲ 0.110%
                              </td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">XAGUSD</td>
                              <td className="trade_td">30.506</td>
                              <td className="text-success trade_td">+0.008</td>
                              <td className="text-success trade_td">
                                ▲ 0.020%
                              </td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">XAUUSD</td>
                              <td className="trade_td">2654.660</td>
                              <td className="text-success trade_td">+2.190</td>
                              <td className="text-success trade_td">
                                ▲ 0.080%
                              </td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">XAUAUD</td>
                              <td className="trade_td">4171.300</td>
                              <td className="text-success trade_td">+9.050</td>
                              <td className="text-success trade_td">
                                ▲ 0.210%
                              </td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">XAGAUD</td>
                              <td className="trade_td">47.930</td>
                              <td className="text-success trade_td">+0.118</td>
                              <td className="text-success trade_td">
                                ▲ 0.240%
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="text-muted font_14 mt-xl-4">
                        LIVE (INDICATIVE) UPDATED: 16:13:35 GMT+5.5
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <h5 className="small_heading fw-bold">GOLD & SILVER</h5>
                      <h3 className="font_20 mb-lg-4 mb-2 fw-base">
                        Buy or sell the world's most highly traded precious
                        metals — gold and silver.
                      </h3>
                      <p className="font_15">
                        Trade gold and silver via CFDs, the world's most popular
                        precious metals, which have historically been used as a
                        hedge against inflation and a store of value during
                        uncertain economic times.
                      </p>
                      <p className="font_15">
                        To trade gold and silver, you will need to open a live
                        trading account to access the global markets whether you
                        are from Thailand, Philippines or beyond.
                      </p>
                      <button className="btn btn_black text-uppercase mt-lg-4 mt-3">
                        learn more
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="energy"
                role="tabpanel"
                aria-labelledby="energy-tab"
              >
                <div className="container">
                  <div className="row align-items-center g-lg-5 g-md-3 g-0">
                    <div className="col-lg-6 mb-4 mb-lg-0">
                      <div className="custom-table-wrapper table-responsive">
                        <table className="table text-center mb-0">
                          <thead>
                            <tr className="table-header font_15">
                              <th className="trade_th">Symbol</th>
                              <th className="trade_th">Buy</th>
                              <th className="trade_th">Change</th>
                              <th className="trade_th">%</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">CL-OIL</td>
                              <td className="trade_td">70.218</td>
                              <td className="text-success trade_td">+0.063</td>
                              <td className="text-success trade_td">
                                ▲ 0.080%
                              </td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">USOUSD</td>
                              <td className="trade_td">70.622</td>
                              <td className="text-success trade_td">-0.005</td>
                              <td className="text-success trade_td">
                                ▲ 0.000%
                              </td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">UKOUSD</td>
                              <td className="trade_td">74.056</td>
                              <td className="text-success trade_td">-0.068</td>
                              <td className="text-success trade_td">
                                ▲-0.090%
                              </td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">NG-C</td>
                              <td className="trade_td">3.094</td>
                              <td className="text-success trade_td">-0.015</td>
                              <td className="text-success trade_td">
                                ▲ 0.480%
                              </td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">UKOUSDft</td>
                              <td className="trade_td">73.843</td>
                              <td className="text-success trade_td">+0.043</td>
                              <td className="text-success trade_td">
                                ▲ 0.050%
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="text-muted font_14 mt-xl-4">
                        LIVE (INDICATIVE) UPDATED: 16:13:35 GMT+5.5
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <h5 className="small_heading fw-bold">ENERGY</h5>
                      <h3 className="font_20 mb-lg-4 mb-2 fw-base">
                        Trade energy instruments including crude oil, natural
                        gas, and Brent oil.
                      </h3>
                      <p className="font_15">
                        Tradsify offers a broad range of energy commodities,
                        providing traders with access to the highly liquid
                        global energy markets. Our CFDs energy trading options
                        include popular commodities, such as crude oil, natural
                        gas, and Brent oil.
                      </p>
                      <p className="font_15">
                        To trade energy, you will need to open a live trading
                        account to access the global forex markets whether you
                        are from Thailand, Philippines or beyond.
                      </p>
                      <button className="btn btn_black text-uppercase mt-lg-4 mt-3">
                        learn more
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              {/* ------------------ */}
              <div
                class="tab-pane fade"
                id="etfs"
                role="tabpanel"
                aria-labelledby="etfs-tab"
              >
                <div className="container">
                  <div className="row align-items-center g-lg-5 g-md-3 g-0">
                    <div className="col-lg-6 mb-4 mb-lg-0">
                      <div className="custom-table-wrapper table-responsive">
                        <table className="table text-center mb-0">
                          <thead>
                            <tr className="table-header font_15">
                              <th className="trade_th">Symbol</th>
                              <th className="trade_th">Buy</th>
                              <th className="trade_th">Change</th>
                              <th className="trade_th">%</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">SDS</td>
                              <td className="trade_td">18.820</td>
                              <td className="text-success trade_td">-0.050</td>
                              <td className="text-success trade_td">
                                {" "}
                                ▲ 0.260%
                              </td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">QQQ</td>
                              <td className="trade_td">538.680</td>
                              <td className="text-success trade_td">+5.080</td>
                              <td className="text-success trade_td">
                                ▲ 0.950%
                              </td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">SPY</td>
                              <td className="trade_td">606.980</td>
                              <td className="text-success trade_td">+0.670</td>
                              <td className="text-success trade_td">
                                ▲ +0.110%
                              </td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">GLD</td>
                              <td className="trade_td">244.840</td>
                              <td className="text-success trade_td">-0.410</td>
                              <td className="text-success trade_td">
                                ▲ -0.160%
                              </td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">GDX</td>
                              <td className="trade_td">36.410</td>
                              <td className="text-success trade_td">-0.110</td>
                              <td className="text-success trade_td">
                                ▲ -0.300%
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="text-muted font_14 mt-xl-4">
                        LIVE (INDICATIVE) UPDATED: 16:13:35 GMT+5.5
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <h5 className="small_heading fw-bold">ETFS</h5>
                      <h3 className="font_20 mb-lg-4 mb-2 fw-base">
                        Diversify your portfolio and gain exposure to more
                        markets with ETFs.
                      </h3>
                      <p className="font_15">
                        ETFs track the performance of a pool of underlying
                        assets – including commodities, stocks, currencies,
                        indices and more. Start trading popular ETFs like the
                        SPDR S&P 500 ETF and Invesco QQQ Trust.
                      </p>
                      <p className="font_15">
                        To trade ETFs, you will need to open a live trading
                        account to access the global forex markets whether you
                        are from Thailand, Philippines or beyond.
                      </p>
                      <button className="btn btn_black text-uppercase mt-lg-4 mt-3">
                        learn more
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="softcommodities"
                role="tabpanel"
                aria-labelledby="softcommodities-tab"
              >
                <div className="container">
                  <div className="row align-items-center g-lg-5 g-md-3 g-0">
                    <div className="col-lg-6 mb-4 mb-lg-0">
                      <div className="custom-table-wrapper table-responsive">
                        <table className="table text-center mb-0">
                          <thead>
                            <tr className="table-header font_15">
                              <th className="trade_th">Symbol</th>
                              <th className="trade_th">Buy</th>
                              <th className="trade_th">Change</th>
                              <th className="trade_th">%</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">COPPER-C</td>
                              <td className="trade_td">4.129</td>
                              <td className="text-success trade_td">-0.015</td>
                              <td className="text-success trade_td">
                                ▲-0.360%
                              </td>
                              ▼
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">COFFEE-C</td>
                              <td className="trade_td">3.263</td>
                              <td className="text-success trade_td">+0.083</td>
                              <td className="text-success trade_td">
                                ▲ +2.600%
                              </td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">SUGAR-C</td>
                              <td className="trade_td">0.205</td>
                              <td className="text-success trade_td">-0.001</td>
                              <td className="text-success trade_td">
                                ▲ -0.480%
                              </td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">COTTON-C</td>
                              <td className="trade_td">0.677</td>
                              <td className="text-success trade_td">+0.001</td>
                              <td className="text-success trade_td">
                                ▲ +0.140%
                              </td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">COCOA-C</td>
                              <td className="trade_td">12709.700</td>
                              <td className="text-success trade_td">
                                +325.500
                              </td>
                              <td className="text-success trade_td">
                                ▲ +2.620%
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="text-muted font_14 mt-xl-4">
                        LIVE (INDICATIVE) UPDATED: 16:13:35 GMT+5.5
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <h5 className="small_heading  fw-bold">
                        SOFT COMMODITIES
                      </h5>
                      <h3 className="font_20 mb-lg-4 mb-2 fw-base">
                        Trade soft commodities or agricultural goods, such as
                        sugar, coffee, cotton and more.
                      </h3>
                      <p className="font_15">
                        Soft commodities refer to commodities that are grown
                        rather than mined. When trading soft commodities CFDs,
                        traders will have to consider factors including weather
                        conditions, global demand, and geopolitical events that
                        impact their production.
                      </p>
                      <p className="font_15">
                        To trade soft commodities, you will need to open a live
                        trading account to access the global forex markets
                        whether you are from Thailand, Philippines or beyond.
                      </p>
                      <button className="btn btn_black text-uppercase mt-lg-4 mt-3">
                        learn more
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="shares"
                role="tabpanel"
                aria-labelledby="shares-tab"
              >
                <div className="container">
                  <div className="row align-items-center g-lg-5 g-md-3 g-0">
                    <div className="col-lg-6 mb-4 mb-lg-0">
                      <div className="custom-table-wrapper table-responsive">
                        <table className="table text-center mb-0">
                          <thead>
                            <tr className="table-header font_15">
                              <th className="trade_th">Symbol</th>
                              <th className="trade_th">Buy</th>
                              <th className="trade_th">Change</th>
                              <th className="trade_th">%</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">TSLA</td>
                              <td className="trade_td">460.100</td>
                              <td className="text-success trade_td">+19.680</td>
                              <td className="text-success trade_td">▲4.460%</td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">NVIDIA</td>
                              <td className="trade_td">132.150</td>
                              <td className="text-success trade_td">-1.750</td>
                              <td className="text-success trade_td">
                                ▲ -1.300%
                              </td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">NFLX</td>
                              <td className="trade_td">922.730</td>
                              <td className="text-success trade_td">+3.630</td>
                              <td className="text-success trade_td">
                                ▲ 0.390%
                              </td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">META</td>
                              <td className="trade_td">628.750</td>
                              <td className="text-success trade_td">+0.470</td>
                              <td className="text-success trade_td">
                                ▲ 0.070%
                              </td>
                            </tr>
                            <tr className="font_15 text-nowrap">
                              <td className="trade_td">AAPL</td>
                              <td className="trade_td">250.190</td>
                              <td className="text-success trade_td">+2.280</td>
                              <td className="text-success trade_td">
                                ▲ 0.910%
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="text-muted font_14 mt-xl-4">
                        LIVE (INDICATIVE) UPDATED: 16:13:35 GMT+5.5
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <h5 className="small_heading fw-bold">SHARES</h5>
                      <h3 className="font_20 mb-lg-4 mb-2 fw-base">
                        Choose from the hundreds of individual stocks available
                        in US and more.
                      </h3>
                      <p className="font_15">
                        You can invest in the likes of Tesla, Netflix, Meta, and
                        other listed companies through shares CFD. Get access to
                        international share markets including US, Europe,
                        Australia and Asia.
                      </p>
                      <p className="font_15">
                        To trade shares, you will need to open a live trading
                        account to access the global forex markets whether you
                        are from Thailand, Philippines or beyond.
                      </p>
                      <button className="btn btn_black text-uppercase mt-lg-4 mt-3">
                        learn more
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_space bg-ofwhite">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8 text-center">
                <h2 className="sub_heading  wow animate__animated animate__fadeInUp">Our CFD Trading Platforms</h2>
                <p className="font_15 mb-0  wow animate__animated  animate__fadeInUp">
                  Whether you are from India, Philippines or beyond, discover
                  the power of CFD trading <br className="d-lg-block d-none" />
                  with our top-rated platforms: Metatrader 5.
                </p>
                {/* <button className="btn btn_black text-uppercase mt-lg-4 mt-3  wow  animate__animated animate__fadeInUp">
                  OPEN LIVE ACCOUNT
                </button> */}
              </div>
              <div className="col-12 d-flex justify-content-center">
                <img
                  src="assets/images/home/video1_img.png"
                  alt=""
                  className="img-fluid mt-xl-5 mt-4 wow animate__animated animate__fadeInUpBig"
                />
              </div>
            </div>
          </div>
        </section>
        {/* <section className="section_space trade-smarter pb-0" >
          <div className="container">
            <div className="row g-xl-4 g-3">
              <div className="col-12">
                <h2 className="sub_heading text-center mb-lg-5 mb-3
                       wow animate__animated  animate__fadeInUp">
                  Trade Smarter with Our Trading Tools
                </h2>

              </div>
              <div className="col-lg-6 col-md-12 col-12 ">
                <section className="trade-smarter" style={{ height: "3083.31px" }}>
                  <div className="inner">
                    <div className="trade-smarter-bgbox pb-0">

                      <div
                        className="trade-smarter-box animates animate__fadeInUp"
                        data-wow-duration="0.8s"
                        style={{
                          visibility: "visible",
                          animationDuration: "0.8s",
                          animationName: "fadeInUp"
                        }}
                      >
                        <div className="trade-smarter-text" style={{ height: "596.438px" }}>
                          <div
                            className="trade-smarter-bar"
                            style={{ height: "1301.88px", transform: "translateY(-442px)" }}
                          >

                          </div>
                          <div className="trade-smarter-content">
                            <div className="position-relative tools_card trade-smarter-content-item active">
                              <img src="assets/images/trade_mobile/academy.webp" alt="" className="img-fluid d-block d-lg-none  wow animate__animated  animate__fadeInRight" />
                              <div>
                                <h2 className="sami_medium_heading mb-xl-3 mb-2  wow animate__animated  animate__fadeInRight">
                                  ACADEMY
                                  <img
                                    src="assets/images/home/trade-smarter-vector.svg"
                                    alt=""
                                  />
                                </h2>
                                <p className="font_16 mb-0  wow animate__animated  animate__fadeInRight">
                                  Get all the free education you need to become a better trader.
                                  Whether you’re a beginner or an expert, we’ll have something
                                  for you. From articles, to educational webinars, videos, and
                                  courses; you name it, we’ll have it.
                                  Get all the free education you need to become a better
                                  trader. Whether you’re a beginner or an expert, we’ll have
                                  something for you. From articles, to educational webinars,
                                  videos, and courses; you name it, we’ll have it.
                                </p>
                              </div>
                            </div>
                            <div className="position-relative tools_card trade-smarter-content-item ">
                              <div className="order-2 order-md-1">
                                <h2 className="sami_medium_heading mb-xl-3 mb-2
                               wow animate__animated  animate__fadeInRight">
                                  Economic Calendar
                                  <img
                                    src="assets/images/home/trade-smarter-vector.svg"
                                    alt=""
                                  />
                                </h2>
                                <p className="font_16 mb-0  wow animate__animated  animate__fadeInRight">
                                  Stay ahead of the game with our comprehensive economic calendar. Track important, market-moving events effortlessly and anticipate major movements in the market. Our calendar keeps you informed about key dates and times, so you can make smarter trading decisions with confidence.
                                </p>
                              </div>
                              <img src="assets/images/trade_mobile/guides.webp" alt="" className="order-1 order-md-2 img-fluid d-block d-lg-none  wow animate__animated  animate__fadeInRight" />
                            </div>
                            <div className="position-relative tools_card trade-smarter-content-item ">
                              <img src="assets/images/trade_mobile/analysis.webp" alt="" className="img-fluid d-block d-lg-none  wow animate__animated  animate__fadeInRight" />
                              <div>
                                <h2 className="sami_medium_heading mb-xl-3 mb-2
                               wow animate__animated  animate__fadeInRight">
                                  MARKET ANALYSIS
                                  <img
                                    src="assets/images/home/trade-smarter-vector.svg"
                                    alt=""
                                  />
                                </h2>
                                <p className="font_16 mb-0
                               wow animate__animated  animate__fadeInRight">
                                  With our expert market analysis, we help traders break down what’s happening in the markets and stay informed about the latest trends. Our analysis includes daily market commentary, and technical analysis reports.
                                </p>
                              </div>
                            </div>
                            <div className="position-relative tools_card trade-smarter-content-item">
                              <div className="order-2 order-md-1">
                                <h2 className="sami_medium_heading mb-xl-3 mb-2
                               wow animate__animated  animate__fadeInRight">
                                  CUSTOMER SUPPORT
                                  <img
                                    src="assets/images/home/trade-smarter-vector.svg"
                                    alt=""
                                  />
                                </h2>
                                <p className="font_16 mb-0
                               wow animate__animated  animate__fadeInRight">
                                  We provide support and detailed account information at every stage of your journey. Our dedicated customer support team is available 24/7 to provide assistance with any trading-related issues or questions.
                                </p>
                              </div>
                              <img src="assets/images/trade_mobile/support.webp" alt="" className="order-1 order-md-2 img-fluid d-block d-lg-none  wow animate__animated  animate__fadeInRight" />
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </section>

              </div>

              <div className="col-md-6 col-12 d-none d-lg-block">
                <img src="assets/images/home-trade-smarter/icon-0001.webp" alt="" className="my_hard_img img-fluid" />
              </div>
            </div>
          </div>
        </section> */}
          <section className="section_space">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-4 text-center">
                <h2 className="sub_heading mb-4  wow animate__animated animate__fadeInUp">
                  Get Started <br className="d-none d-lg-block" />
                  Within 3 Minutes
                </h2>
                <div>
                  <a href="https://my.tradsify.com/en/auth/sign-up" className="btn btn_black text-uppercase  wow animate__  animate__fadeInUp  animated">
                    Sign Up
                  </a>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="step_count count_1">
                  <span className="d-inline-block count">1</span>
                  <h5 className="fw-bold fs-4">
                    Register
                  </h5>
                  <p>
                    Sign up in an IB program with one of the largest global multi-asset brokers.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="step_count count_2">
                  <span className="d-inline-block count">2</span>
                  <h5 className="fw-bold fs-4">
                    Fund
                  </h5>
                  <p>
                    Expand your referral network by introducing new clients to trade with tradesify.
                  </p>
                </div>
              </div>
              <div className="col-md-4 col-12">
                <div className="step_count">
                  <span className="d-inline-block count">3</span>
                  <h5 className="fw-bold fs-4">
                    Trade
                  </h5>
                  <p>
                    Earn volume-based commissions for each trade made by clients you’ve referred.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section className="section_space trade-smarter  bg_gray" >
          <div className="container">
            <div className="row g-xl-5 g-3">
              <div className="col-12">
                <h2 className="sub_heading text-center mb-lg-5 mb-3
                       wow animate__animated  animate__fadeInUp">
                  Trade Smarter with Our Trading Tools
                </h2>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="position-relative tools_card trade-smarter-content-item active">
                  <img src="assets/images/trade_mobile/academy.svg" alt="" className="img-fluid d-block mb-3  wow animate__animated  animate__fadeInRight" />
                  <div>
                    <h2 className="sami_medium_heading mb-xl-3 mb-2  wow animate__animated  animate__fadeInRight">
                      ACADEMY
                      <img
                        src="assets/images/home/trade-smarter-vector.svg"
                        alt=""
                      />
                    </h2>
                    <p className="font_16 mb-0  wow animate__animated  animate__fadeInRight">
                      Get all the free education you need to become a better trader.
                      Whether you’re a beginner or an expert, we’ll have something
                      for you. From articles, to educational webinars, videos, and
                      courses; you name it, we’ll have it.
                      Get all the free education you need to become a better
                      trader. Whether you’re a beginner or an expert, we’ll have
                      something for you. From articles, to educational webinars,
                      videos, and courses; you name it, we’ll have it.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="position-relative tools_card trade-smarter-content-item active">
                  <img src="assets/images/trade_mobile/guides.svg" alt="" className="img-fluid d-block mb-3 wow animate__animated  animate__fadeInRight" />
                  <div className="order-2">
                    <h2 className="sami_medium_heading mb-xl-3 mb-2
                               wow animate__animated  animate__fadeInRight">
                      Economic Calendar
                      <img
                        src="assets/images/home/trade-smarter-vector.svg"
                        alt=""
                      />
                    </h2>
                    <p className="font_16 mb-0  wow animate__animated  animate__fadeInRight">
                      Stay ahead of the game with our comprehensive economic calendar. Track important, market-moving events effortlessly and anticipate major movements in the market. Our calendar keeps you informed about key dates and times, so you can make smarter trading decisions with confidence.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="position-relative tools_card trade-smarter-content-item active">
                  <img src="assets/images/trade_mobile/analysis.svg" alt="" className="img-fluid d-block mb-3 wow animate__animated  animate__fadeInRight" />
                  <div>
                    <h2 className="sami_medium_heading mb-xl-3 mb-2
                               wow animate__animated  animate__fadeInRight">
                      MARKET ANALYSIS
                      <img
                        src="assets/images/home/trade-smarter-vector.svg"
                        alt=""
                      />
                    </h2>
                    <p className="font_16 mb-0
                               wow animate__animated  animate__fadeInRight">
                      With our expert market analysis, we help traders break down what’s happening in the markets and stay informed about the latest trends. Our analysis includes daily market commentary, and technical analysis reports.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-12">
                <div className="position-relative tools_card trade-smarter-content-item active">
                  <img src="assets/images/trade_mobile/support.svg" alt="" className="img-fluid d-block mb-3 wow animate__animated  animate__fadeInRight" />
                  <div className="">
                    <h2 className="sami_medium_heading mb-xl-3 mb-2
                               wow animate__animated  animate__fadeInRight">
                      CUSTOMER SUPPORT
                      <img
                        src="assets/images/home/trade-smarter-vector.svg"
                        alt=""
                      />
                    </h2>
                    <p className="font_16 mb-0
                               wow animate__animated  animate__fadeInRight">
                      We provide support and detailed account information at every stage of your journey. Our dedicated customer support team is available 24/7 to provide assistance with any trading-related issues or questions.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

      

        {/* <section className="section_space">
          <div className="container">
            <div className="row align-items-center justify-content-center g-lg-4 g-3">
              <div className="col-lg-9 text-center mb-xl-5 mb-4">
                <h2 className="sub_heading text-center mb-lg-5 mb-3  wow animate__animated  animate__fadeInUp">
                  Read More About CFD <br />
                  Trading Here
                </h2>
                <Link to="" className="btn btn_black text-uppercase  wow animate__animated  animate__fadeInUp">
                  Read More
                </Link>
              </div>
              <div className="col-lg-4">
                <div className="trade_hover  wow animate__animated  animate__fadeInUpBig">
                  <div className="h-100 overflow-hidden d-block">
                    <img
                      src="assets/images/blog/blog1.avif"
                      alt=""
                      className="img-fluid w-100 trade_immg"
                    />
                  </div>

                  <div className="trade-box mb-5">
                    <p className="font_18 fw-bold">Washington Watch</p>
                    <p className="sub_small_heading">
                      Did Elon Musk kill a bill blocking investments in China to help Tesla?
                    </p>
                    <p className="font_15 blog_cards mb-md-5">
                      Democrats have accused the Tesla CEO of influencing the government-funding bill to his own benefit
                    </p>
                    <a href="#" class="round_button float-end">
                      <img src="assets/images/header/arrow_white.svg" alt="" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="trade_hover  wow animate__animated  animate__fadeInUpBig">
                  <div className="h-100 overflow-hidden d-block">
                    <img
                      src="assets/images/blog/blog2.avif"
                      alt=""
                      className="img-fluid trade_immg"
                    />
                  </div>
                  <div className="trade-box mb-5">
                    <p className="font_18 fw-bold">Economic Report</p>
                    <p className="sub_small_heading">
                      Consumer confidence dented by stock-market worries and threat of Trump tariffs
                    </p>
                    <p className="font_15 blog_cards mb-md-5">
                      Consumer-confidence index falls to a three-month low
                    </p>
                    <a href="#" class="round_button float-end">
                      <img src="assets/images/header/arrow_white.svg" alt="" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="trade_hover  wow animate__animated  animate__fadeInUpBig">
                  <div className="d-block h-100 overflow-hidden">
                    <div className="d-block h-100 overflow-hidden">
                      <img
                        src="assets/images/blog/blog3.jpeg"
                        alt=""
                        className="img-fluid trade_immg"
                      />
                    </div>
                  </div>
                  <div className="trade-box mb-5">
                    <p className="font_18 fw-bold">Crypto</p>
                    <p className="sub_small_heading">
                      Will a Trump-backed U.S. bitcoin reserve power crypto’s next rally?
                    </p>
                    <p className="font_15 blog_cards mb-md-5">
                      Would the plan enrich bitcoin holders, or put the U.S. economy on a sounder footing?
                    </p>
                    <a href="#" class="round_button float-end">
                      <img src="assets/images/header/arrow_white.svg" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        {/* 
        <section className="section_space">
          <div className="container">
            <div className="row g-4">
              <div className="col-lg-5">
                <h2 className="sub_heading mb-xl-3 mb-md-2">How it works?</h2>
                <p>
                  Trade  Bundles allows you to invest in  without{" "}
                  <br /> having to think about which coin to buy. The perfect tool
                  for the <br /> passive investor to buy-and-hold for the long
                  term!
                </p>
              </div>
              <div className="col-lg-6 offset-lg-1">
                <div className="d-flex gap-5 mb-4">
                  <h4 className="heading_sub_big">01&nbsp; </h4>
                  <div className="mt-xl-2 mt-sm-1 ">
                    <h5 className="sub_small_heading mb-xl-3 mb-2">
                      Lorem ipsum dolor sit amet.
                    </h5>
                    <p className="font_16">
                  
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae eos numquam voluptatibus sint mollitia consequatur voluptates recusandae. Cumque, architecto eum.
                    </p>
                  </div>
                </div>
                <div className="d-flex gap-5 mb-4">
                  <h4 className="heading_sub_big"> 02</h4>
                  <div className="mt-xl-2 mt-sm-1 ">
                    <h5 className="sub_small_heading mb-3">
                      Lorem ipsum dolor sit amet.
                    </h5>
                    <p className="font_16">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae eos numquam voluptatibus sint mollitia consequatur voluptates recusandae. Cumque, architecto eum.

                    </p>
                  </div>
                </div>
                <div className="d-flex gap-5 mb-4">
                  <h4 className="heading_sub_big"> 03</h4>
                  <div className="mt-xl-2 mt-sm-1 ">
                    <h5 className="sub_small_heading mb-3">
                      Lorem ipsum dolor sit amet.
                    </h5>
                    <p className="font_16">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae eos numquam voluptatibus sint mollitia consequatur voluptates recusandae. Cumque, architecto eum.

                    </p>
                  </div>
                </div>
                <div className="d-flex gap-5 mb-4">
                  <h4 className="heading_sub_big"> 04</h4>
                  <div className="mt-xl-2 mt-sm-1 ">
                    <h5 className="sub_small_heading mb-3">
                      Lorem ipsum dolor sit amet.
                    </h5>
                    <p className="font_16">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae eos numquam voluptatibus sint mollitia consequatur voluptates recusandae. Cumque, architecto eum.

                    </p>
                  </div>
                </div>
                <div className="d-flex  gap-5 mb-4">
                  <h4 className="heading_sub_big"> 05</h4>
                  <div className="mt-xl-2 mt-sm-1 ">
                    <h5 className="sub_small_heading mb-3">
                      Lorem ipsum dolor sit amet.
                    </h5>
                    <p className="font_16">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quae eos numquam voluptatibus sint mollitia consequatur voluptates recusandae. Cumque, architecto eum.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <Enjoymembership /> */}

        <Ourcommunity />
        <Get_Apps />
        <Faq />
        <section className="section_space support_section">
          <div className="container">
            <div className="row align-items-center justify-content-center text-center">
              <div className="col-lg-7">
                <div className="py-5">
                  <h2 className="sub_heading mb-3  wow animate__animated animate__fadeInUp">
                    Rely on Big Support
                  </h2>
                  <p className="font_16 mb-5  wow animate__animated animate__fadeInUp">
                    Whenever you need us we're just a few seconds away,
                    24/7, and other options.
                  </p>
                  <Link to="/contact">
                    <button className="btn btn_black  wow animate__animated animate__fadeInUp">
                      Contact Us
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
        {/* <NewYearPopup /> */}
      </ScrollToTop>
    </>
  );
}
