import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPasssword from "./pages/ResetPasssword";
import SendMail from "./pages/SendMail";
import About from "./pages/About";
import Service from "./pages/Service";
import Amlpolicy from "./pages/Amlpolicy";
import Metatrader5 from "./pages/Metatrader5";
import Metatrader4 from "./pages/Metatrader4";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Guide from "./pages/Guide";
import Riskdisclosureandwarningsnotice from "./pages/Riskdisclosureandwarningsnotice.jsx";
import CommingSoon from "./pages/CommingSoon.jsx";
import Instruments from "./pages/Instruments.jsx"
import Tradingaccounts from "./pages/Tradingaccounts.jsx";
import Support from "./pages/Support.jsx";
import TradingAc from "./pages/Trading.jsx";
import Trading from "./pages/Trading.jsx";
import DepositBonus from "./pages/DepositBonus.jsx";
import CopyTrading from "./pages/CopyTrading.jsx";
import DemoTrading from "./pages/DemoTrading.jsx"
import TradingApp from "./pages/TradingApp.jsx";
import PartnerOverview from "./pages/PartnerOverview.jsx";
import ReferFriend from "./pages/ReferFriend.jsx";
import HelpCenter from "./pages/HelpCenter.jsx";
import Markets from "./pages/Markets.jsx";
import PlatformsOverview from "./pages/PlatformsOverview.jsx";
import IntroducingBroker from "./pages/IntroducingBroker.jsx";
import WhyTradsify from "./pages/WhyTradsify.jsx";
import Course from "./pages/Course.jsx";
import AboutOverview from "./pages/AboutOverview.jsx";
import Reviews from "./pages/Reviews.jsx";
import FundsGrowth from "./pages/FundsGrowth.jsx";
import PageNotFound from "./pages/PageNotFound.jsx";
import TradsifyRewards from "./pages/TradsifyRewards.jsx";
import Pricing from "./pages/Pricing.jsx";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Home />} />
        <Route path="/services" element={<Service />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        {/* <Route path="/login" element={<Login />} /> */}
        {/* <Route path="/register" element={<Signup />} /> */}
        {/* <Route path="/forgot" element={<ForgetPassword />} /> */}
        {/* <Route path="/otp" element={<SendMail />} /> */}
        {/* <Route path="/reset" element={<ResetPasssword />} /> */}
        <Route path="/aml-policy" element={<Amlpolicy />} />
        <Route path="/metatrader-5" element={<Metatrader5 />} />
        <Route path="/metatrader-4" element={<Metatrader4 />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/guide" element={<Guide />} />
        <Route path="/risk-disclosure-and-warnings-notice" element={<Riskdisclosureandwarningsnotice />} />
        <Route path="/coming-soon" element={<CommingSoon />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/instruments" element={<Instruments />} />
        <Route path="/trading" element={<Trading />} />
        <Route path="/tradingaccounts" element={<Tradingaccounts />} />
        <Route path="/support" element={<Support />} />
        <Route path="/depositbonus" element={<DepositBonus />} />
        <Route path="/copytrading" element={<CopyTrading />} />
        <Route path="/demotrading" element={<DemoTrading />} />
        <Route path="/tradingapp" element={<TradingApp />} />
        <Route path="/partneroverview" element={<PartnerOverview />} />
        <Route path="/referfriend" element={<ReferFriend />} />
        <Route path="/helpcenter" element={<HelpCenter />} />
        <Route path="/markets" element={<Markets />} />
        <Route path="/platformsoverview" element={<PlatformsOverview />} />
        <Route path="/introducingbroker" element={<IntroducingBroker />} />
        <Route path="/whytradsify" element={<WhyTradsify />} />
        <Route path="/course" element={<Course />} />
        <Route path="/aboutoverview" element={<AboutOverview />} />
        {/* <Route path="/reviews" element={<Reviews />} /> */}
        <Route path="/fundsgrowth" element={<FundsGrowth />} />
        <Route path="/tradsifyrewards" element={<TradsifyRewards />} />
        <Route path="/pricing" element={<Pricing />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
