import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link } from "react-router-dom";


export default function Pricing() {
    return (
        <>
            <ScrollToTop>
                <Header />
                <section className="section_space contact_us">
                    <div class="container">
                        <div class="row align-items-center text-center justify-content-center">
                            <div className="col-12">
                                <h2 class="main_heading">
                                    <span>Accounts Pricing</span>
                                </h2>
                            </div>

                        </div>
                    </div>
                </section>
                <section className="pricing-section">
                    <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-3 col-md-6 col-sm-6 col-12 wow fadeInUp mb-4 mb-lg-0">
                                    <div className="pricing-block">
                                            <div className="icon-box border-bottom">
                                                <span className="text-center text-white font_20 mb-3 fw-bold d-block">
                                                    STANDARD
                                                </span>
                                                <h4 className="price broker_refer text-white text-center mb-0">$15</h4>
                                            </div>
                                            <div className="pricing_details">
                                                <ul className="features mt-3">
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">SPREAD </span> : 0.3 PIPS</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">COMMISSION</span> :  NO COMMISSION</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">MAX LEVERAGE</span> : 1:1000</li>
                                                    <li className="false"> <span className="fw-bold fs-7 text-dark text-capitalize"> INSTRUMENTS</span> : FOREX, METALS, CRYPTOCURRENCIES, ENERGIES, STOCKS, INDICES</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">MINIMUM LOT SIZE	</span> : 0.01</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">MAXIMUM LOT SIZE</span> : 200 (7:00 - 20:59 GMT+0), 60 (21:00 - 6:59 GMT+0)</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">MAXIMUM NUMBER OF POSITION</span> : UNLIMITED</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">HEDGED MARGIN</span> : 0%</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">MARGIN CALL</span> : 100%</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">STOP OUT</span> : 0%</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">ORDER EXECUTION</span> : MARKET</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">SWAP-FREE</span> : AVAILABLE</li>
                                                </ul>
                                                <Link to="#"  className="d-block text-center"> 
                                                        <button className="btn btn_blue hero_refer_btn px-lg-5 get_started_btn text-center w-100" type="button">Get Started</button>
                                                </Link>
                                            </div>
                                            
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-6 col-12 wow fadeInUp mb-4 mb-lg-0">
                                    <div className="pricing-block">
                                            <div className="icon-box  border-bottom">
                                                <span className="text-center text-white font_20 mb-3 fw-bold d-block">
                                                    ECN
                                                </span>
                                                    <h4 className="price broker_refer text-white text-center mb-0" >$50</h4>
                                            </div>   
                                            <div className="pricing_details"> 
                                                <ul className="features mt-3">
                                                    <li className="true "> <span className="fw-bold fs-7 text-dark text-capitalize">SPREAD </span> : 0.2 PIPS</li>
                                                    <li className="true "> <span className="fw-bold fs-7 text-dark text-capitalize">COMMISSION </span> : NO COMMISSION</li>
                                                    <li className="true "> <span className="fw-bold fs-7 text-dark text-capitalize">MAX LEVERAGE </span> : 1:1000</li>
                                                    <li className="false "> <span className="fw-bold fs-7 text-dark text-capitalize">INSTRUMENTS </span> : FOREX, METALS, CRYPTOCURRENCIES, ENERGIES, STOCKS, INDICES</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">MINIMUM LOT SIZE	</span> : 0.01</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">MAXIMUM LOT SIZE</span> : 200 (7:00 - 20:59 GMT+0), 60 (21:00 - 6:59 GMT+0)</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">MAXIMUM NUMBER OF POSITION</span> : UNLIMITED</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">HEDGED MARGIN</span> : 0%</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">MARGIN CALL</span> : 100%</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">STOP OUT</span> : 0%</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">ORDER EXECUTION</span> : MARKET</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">SWAP-FREE</span> : AVAILABLE</li>
                                                </ul>
                                        
                                                <Link to="#"  className="d-block text-center">
                                                        <button className="btn btn_blue hero_refer_btn px-lg-5 get_started_btn w-100" type="button">Get Started</button>
                                                </Link>
                                            </div>   
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-6 col-12 wow fadeInUp mb-4 mb-lg-0">
                                    <div className="pricing-block ">
                                            <div className="icon-box  border-bottom">
                                                <span className="text-center text-white font_20 mb-3 fw-bold d-block">
                                                    PRO
                                                </span>
                                                <h4 className="price broker_refer text-white text-center mb-0">$2000	</h4>   
                                            </div>
                                            <div className="pricing_details">
                                                <ul className="features mt-3">
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">SPREAD </span> : 0.1 PIPS	</li>
                                                    <li className="true">  <span className="fw-bold fs-7 text-dark text-capitalize">COMMISSION </span> : NO COMMISSION	</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">MAX LEVERAGE </span> : 1:200</li>
                                                    <li className="false"> <span className="fw-bold fs-7 text-dark text-capitalize">INSTRUMENTS </span> : FOREX, METALS, CRYPTOCURRENCIES, ENERGIES, STOCKS, INDICES	</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">MINIMUM LOT SIZE	 </span> : 0.01</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">MAXIMUM LOT SIZE</span> : SAME AS STANDARD</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">MAXIMUM NUMBER OF POSITION</span> : UNLIMITED</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">HEDGED MARGIN</span> : 0%</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">MARGIN CALL</span> : 80%</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">STOP OUT</span> : 0%</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">ORDER EXECUTION</span> : MARKET</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">SWAP-FREE</span> : NOT AVAILABLE</li>
                                                </ul>
                                            
                                                <Link to="#"  className="d-block text-center">
                                                        <button className="btn btn_blue hero_refer_btn px-lg-5 get_started_btn w-100" type="button">Get Started</button>
                                                </Link>
                                            </div>
                                    </div>
                                </div>

                                <div className="col-lg-3 col-md-6 col-sm-6 col-12 wow fadeInUp mb-4 mb-lg-0">
                                    <div className="pricing-block third_pricing_block">
                                            <div className="icon-box  border-bottom">
                                                    <span className="text-center text-white font_20 mb-3 fw-bold d-block">
                                                        PRIME
                                                    </span>
                                                   <h4 className="price broker_refer text-white text-center mb-0">$3000</h4>
                                            </div>
                                            <div className="pricing_details">
                                                <ul className="features mt-3">
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">SPREAD </span> : 0.0 PIPS</li>
                                                    <li className="true"><span className="fw-bold fs-7 text-dark text-capitalize">COMMISSION </span> : $3.5 EACH SIDE PER LOT</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">MAX LEVERAGE </span> : 1:200</li>
                                                    <li className="false"> <span className="fw-bold fs-7 text-dark text-capitalize">INSTRUMENTS </span> : FOREX, METALS, CRYPTOCURRENCIES, ENERGIES, STOCKS, INDICES</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">MINIMUM LOT SIZE	</span> : 0.01</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">MAXIMUM LOT SIZE</span> : SAME AS STANDARD</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">MAXIMUM NUMBER OF POSITION</span> : UNLIMITED</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">HEDGED MARGIN</span> : 0%</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">MARGIN CALL</span> : 80%</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">STOP OUT</span> : 0%</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">ORDER EXECUTION</span> : MARKET</li>
                                                    <li className="true"> <span className="fw-bold fs-7 text-dark text-capitalize">SWAP-FREE</span> : NOT AVAILABLE</li>
                                                </ul>
                                                <Link to="#" className="d-block text-center">
                                                    <button className="btn btn_blue hero_refer_btn px-lg-5 get_started_btn w-100" type="button">Get Started</button>
                                                </Link>
                                            </div>
                                    </div>
                                </div>


                            </div>
                    </div>
                </section>

                <Footer />
            </ScrollToTop>
        </>
    )
}