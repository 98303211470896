import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";


export default function PartnerOverview() {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [slidesPerView, setSlidesPerView] = useState(5);
  const [affiliates, setAffiliates] = useState(0);
  const [commissions, setCommissions] = useState(0);
  const [volume, setVolume] = useState(0);
  const [clients, setClients] = useState(0);

  useEffect(() => {
    const intervals = [
      { key: 'affiliates', start: 0, end: 20000, setter: setAffiliates },
      { key: 'commissions', start: 0, end: 5000000, setter: setCommissions },
      { key: 'volume', start: 0, end: 200, setter: setVolume },
      { key: 'clients', start: 0, end: 900000, setter: setClients }
    ];

    intervals.forEach((item) => {
      let currentValue = item.start;
      const interval = setInterval(() => {
        if (currentValue < item.end) {
          currentValue += Math.floor(item.end / 100);
          item.setter(currentValue);
        } else {
          clearInterval(interval);
        }
      }, 10);
    });
  }, []);

  const formatNumber = (number) => {
    return number.toLocaleString();
  };


  ////////////////Hero slider///////////////
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 576) {
        setSlidesPerView(1);
      } else if (window.innerWidth <= 768) {
        setSlidesPerView(2);
      } else if (window.innerWidth <= 1024) {
        setSlidesPerView(3);
      } else {
        setSlidesPerView(4);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  ////////////FORM////////////
  const countries = [
    "United States",
    "Canada",
    "United Kingdom",
    "Australia",
    "India",
    "Germany",
    "France",
    "Japan",
    "China",
    "Brazil",
  ];

  const [message, setMessage] = useState("");
  const maxLength = 200;

  const handleInputChange = (e) => {
    const input = e.target.value;

    if (input.length <= maxLength) {
      setMessage(input);
    }
  };



  return (
    <>
      <ScrollToTop>
        <Header />
        <section className="section_space partner_hero_bg">
          <div className="container">
            <div className="row g-4 align-items-center">
              <div className="col-lg-12 order-2 order-lg-1">
                {/* <div className="moblie_hero_bg d-none">
                  <img src="assets/images/partnership-overview/partnerherobg.webp" alt="" />
                </div> */}
                <div className="part_hero_content">
                  <h1 className="main_heading text-white">Tradsify Partner Program</h1>
                  <p className="font_16 text-white">Partner with an industry-leading, multi-asset broker and join our partner programs today.</p>
                  <a href="#/" className="btn btn_blue fw-semibold font_16 hero_demotrading_btn z-0">GET STARTED NOW
                    <span><img src="assets/images/copytrading/hero-btn-icon.svg" alt="" className="hero_demotrading_img" /></span>
                  </a>
                </div>
              </div>
              {/* <div className="col-lg-7 order-md-1 order-lg-2">
                <div className="part_hero_">
                  <div className="mainimag">
                    <img src="assets/images/partnership-overview/partnerherobg.png" alt="" className="w-100"/>
                    <img src="assets/images/partnership-overview/partner1.svg" alt="" className="partner1" />
                    <img src="assets/images/partnership-overview/partner2.svg" alt="" className="partner2" />
                    <img src="assets/images/partnership-overview/partner3.svg" alt="" className="partner3" />
                    <img src="assets/images/partnership-overview/partner4.svg" alt="" className="partner4" />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>

        {/* <section className="section_space">
          <div className="container">
            <div className="swiper_container">
              <h2 className="medium_heading text-center pb-3">Award Winning Multi-Asset Broker</h2>
              <Swiper className="text-center"
                modules={[Autoplay]}
                spaceBetween={50}
                slidesPerView={slidesPerView}
                loop={true}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
              >
                <SwiperSlide className="award">
                  <img src="assets/images/partnership-overview/swiper1.webp" alt="Award 1" />
                  <p className="font_16 mt-4">ADVFN international Financial Awards 2022</p>
                </SwiperSlide>
                <SwiperSlide className="award">
                  <img src="assets/images/partnership-overview/swiper2.webp" alt="Award 2" />
                  <p className="font_16 mt-4">The Global Forex Awards 2022 - Retail</p>
                </SwiperSlide>
                <SwiperSlide className="award">
                  <img src="assets/images/partnership-overview/swiper3.webp" alt="Award 3" />
                  <p className="font_16 mt-4">Global Business and Finance Magazine 2023</p>
                </SwiperSlide>
                <SwiperSlide className="award">
                  <img src="assets/images/partnership-overview/swiper4.webp" alt="Award 4" />
                  <p className="font_16 mt-4">Global Business and Finance Magazine 2023</p>
                </SwiperSlide>
                <SwiperSlide className="award">
                  <img src="assets/images/partnership-overview/swiper5.webp" alt="Award 5" />
                  <p className="font_16 mt-4">ADVFN international Financial Awards 2022</p>
                </SwiperSlide>
                <SwiperSlide className="award">
                  <img src="assets/images/partnership-overview/swiper6.webp" alt="Award 6" />
                  <p className="font_16 mt-4">The Global Forex Awards 2022 - Retail</p>
                </SwiperSlide>
                <SwiperSlide className="award">
                  <img src="assets/images/partnership-overview/swiper7.webp" alt="Award 7" />
                  <p className="font_16 mt-4">Global Business and Finance Magazine 2023</p>
                </SwiperSlide>
                <SwiperSlide className="award">
                  <img src="assets/images/partnership-overview/swiper8.webp" alt="Award 8" />
                  <p className="font_16 mt-4">Global Business and Finance Magazine 2023</p>
                </SwiperSlide>
                <SwiperSlide className="award">
                  <img src="assets/images/partnership-overview/swiper9.webp" alt="Award 9" />
                  <p className="font_16 mt-4">Global Business and Finance Magazine 2023</p>
                </SwiperSlide>
                <SwiperSlide className="award">
                  <img src="assets/images/partnership-overview/swiper10.webp" alt="Award 10" />
                  <p className="font_16 mt-4">ADVFN international Financial Awards 2022</p>
                </SwiperSlide>
                <SwiperSlide className="award">
                  <img src="assets/images/partnership-overview/swiper11.webp" alt="Award 11" />
                  <p className="font_16 mt-4">The Global Forex Awards 2022 - Retail</p>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </section> */}

       

        <section className="section_space broker_bg overflow-hidden">
          <div className="container">
            <div className="row align-items-center text-center g-4">
              <div className="col-lg-6 order-lg-1 order-2">
                <div className="broker_content">
                  <h1 className="sub_heading">Who is Tradsify</h1>
                  <p className="font_16">Tradsify is a global, multi-asset CFD broker offering clients access to a nimble and powerful service for CFD trading on Forex, Indices, Stocks, Commodities, ETFs and Bonds.</p>
                  <p className="font_16">We aim to help our valued clients grow their earnings, with a focus on offering competitive and industry-leading commissions via our partnership programs.</p>
                </div>
              </div>
              <div className="col-lg-6 order-lg-2 order-1">
                <div className="handshake d-flex justify-content-center">
                  <img src="assets/images/partnership-overview/handshake.webp" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section_space partner_card overflow-hidden">
          <div className="container">
            <div className="row g-3 justify-content-center">
              <div className="col-12">
                <h2 class="sub_heading text-center pb-3">Be a Tradsify Partner Today</h2>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="partner_card_bg text-center">
                  <div className="card_img mb-4">
                    <img src="assets/images/partnership-overview/partnercard1.svg" alt="" />
                  </div>
                  <div className="card_text">
                    <h3 className="font_18 mb-4">Unparalleled Commissions and Rewards System</h3>
                    <p className="card_view font_16">view more</p>
                  </div>
                  <div class="card_cap">
                    <span></span>
                  </div>
                  <div class="hover_text">
                    <p className="font_16">Customisable program tailored to meet your business requirements.<br />
                      <span className="font_16"><i>1</i>. Zero setup fees<br /><i>2</i>. No commission cap</span></p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="partner_card_bg text-center">
                  <div className="card_img mb-4">
                    <img src="assets/images/partnership-overview/partnercard2.svg" alt="" />
                  </div>
                  <div className="card_text">
                    <h3 className="font_18 mb-4">Transparent and Comprehensive Performance Analytics</h3>
                    <p className="card_view font_16">view more</p>
                  </div>
                  <div class="card_cap">
                    <span></span>
                  </div>
                  <div class="hover_text">
                    <p className="font_16">Simplifying performance monitoring like never before<br />
                      <span className="font_16"><i>1</i>. Track your progress and optimise your strategies <br /><i>2</i>. Customised dashboard</span></p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="partner_card_bg text-center">
                  <div className="card_img mb-4">
                    <img src="assets/images/partnership-overview/partnercard3.svg" alt="" />
                  </div>
                  <div className="card_text">
                    <h3 className="font_18 mb-4">Quick Payouts and Withdrawals</h3>
                    <p className="card_view font_16">view more</p>
                  </div>
                  <div class="card_cap">
                    <span></span>
                  </div>
                  <div class="hover_text">
                    <p className="font_16">Enjoy swift payouts and withdrawals because we value the business you bring in<br />
                      <span className="font_16"><i>1</i>. Reliable and fast payouts<br /><i>2</i>. Total transparency</span></p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="partner_card_bg text-center">
                  <div className="card_img mb-4">
                    <img src="assets/images/partnership-overview/partnercard4.svg" alt="" />
                  </div>
                  <div className="card_text">
                    <h3 className="font_18 mb-4">Optimised Marketing Materials in Multiple Languages</h3>
                    <p className="card_view font_16">view more</p>
                  </div>
                  <div class="card_cap">
                    <span></span>
                  </div>
                  <div class="hover_text">
                    <p className="font_16">Boost your marketing capabilities with digital materials tailored to your needs.<br />
                      <span className="font_16"><i>1</i>. Localised image banners<br /><i>2</i>. User-friendly landing pages<br /><i>3</i>. Informational trading materials</span></p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="partner_card_bg text-center">
                  <div className="card_img mb-4">
                    <img src="assets/images/partnership-overview/partnercard5.svg" alt="" />
                  </div>
                  <div className="card_text">
                    <h3 className="font_18 mb-4">Award winning Multi-Asset Broker</h3>
                    <p className="card_view font_16">view more</p>
                  </div>
                  <div class="card_cap">
                    <span></span>
                  </div>
                  <div class="hover_text">
                    <p className="font_16">With numerous prestigious awards won, Tradsify is a globally-recognised broker.<br />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="partner_card_bg text-center">
                  <div className="card_img mb-4">
                    <img src="assets/images/partnership-overview/partnercard6.svg" alt="" />
                  </div>
                  <div className="card_text">
                    <h3 className="font_18 mb-4">Easy-to-Start Programs with Continuous Support</h3>
                    <p className="card_view font_16">view more</p>
                  </div>
                  <div class="card_cap">
                    <span></span>
                  </div>
                  <div class="hover_text">
                    <p className="font_16">Kick-starting your journey with ease.<br />
                      <span className="font_16"><i>1</i>. Personalised assistance from a committed account manager<br /><i>2</i>. Multilingual sales team fluent in over 15 languages<br /><i>3</i>. An extensive array of resources to tap on</span></p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="partner_card_bg text-center">
                  <div className="card_img mb-4">
                    <img src="assets/images/partnership-overview/partnercard7.svg" alt="" />
                  </div>
                  <div className="card_text">
                    <h3 className="font_18 mb-4">PAMM/MAM Solutions Tailored for Fund Managers</h3>
                    <p className="card_view font_16">view more</p>
                  </div>
                  <div class="card_cap">
                    <span></span>
                  </div>
                  <div class="hover_text">
                    <p className="font_16">Efficiently manage funds of various traders from a single trading account.<br />
                      <span className="font_16"><i>1</i>. Perfectly suited for automated strategies<br /><i>2</i>. A range of options including MAM, PAMM, and Copy Trading</span></p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="partner_card_bg text-center">
                  <div className="card_img mb-4">
                    <img src="assets/images/partnership-overview/partnercard8.svg" alt="" />
                  </div>
                  <div className="card_text">
                    <h3 className="font_18 mb-4">Partner Loyalty Program</h3>
                    <p className="card_view font_16">view more</p>
                  </div>
                  <div class="card_cap">
                    <span></span>
                  </div>
                  <div class="hover_text">
                    <p className="font_16">Stay tuned for our upcoming Partner Loyalty Program, designed to reward and provide greater value to all partners. <br />
                      Join us today for exclusive rewards.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <div className="partner_card_bg text-center">
                  <div className="card_img mb-4">
                    <img src="assets/images/partnership-overview/partnercard9.svg" alt="" />
                  </div>
                  <div className="card_text">
                    <h3 className="font_18 mb-4">Strictly Regulated</h3>
                    <p className="card_view font_16">view more</p>
                  </div>
                  <div class="card_cap">
                    <span></span>
                  </div>
                  <div class="hover_text">
                    <p className="font_16">As a leading global broker, your clients will be protected as Tradsify upholds the highest standards of regulation and compliance, as regulated by various Regulators.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section_space overflow-hidden">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <div className="partner_like d-flex justify-content-between">
                  <h2 class="medium_heading">Why Clients Like Tradsify Partners</h2>
                  <div className="p_arrow d-flex gap-4">
                    <div className="p_arrow1">
                      <img src="assets/images/partnership-overview/parrow1.svg" alt="Previous" className="left-arrow" />
                      <img src="assets/images/partnership-overview/parrow3.svg" alt="" className="img_hover" />
                      <div className="dottad_box"></div>
                    </div>
                    <div className="p_arrow2">
                      <img src="assets/images/partnership-overview/parrow2.svg" alt="Previous" className="right-arrow" />
                      <img src="assets/images/partnership-overview/parrow3.svg" alt="" className="img_hover" />
                      <div className="dottad_box"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="partner_staff">
                  <h4 className="sub_small_heading">Fast withdrawals & Expert Staff</h4>
                  <p className="font_16">Tradsify offers respectable commission fees and excellent A1 customer support with knowledgeable staff available for any inquiries. Withdrawals are processed quickly, typically within minutes upon request, making it a 10/10 broker. Highly recommended!</p>
                  <div className="anthony d-flex align-items-center gap-2">
                    <p className="font_16 an_text">Anthony</p>
                    <div className="staff_line"></div>
                  </div>
                  <div className="staff_trader">
                    <p className="font_16 m-0">Professional Trader</p>
                    <p className="font_16">Introducing Broker Program</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section_space partner_shaps_bg overflow-hidden">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-12">
                <h2 class="sub_heading text-white text-center">How to Become A Tradsify Partner</h2>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="step-circle">1</div>
                <h5 className="sub_small_heading part_refer">Sign Up</h5>
                <p className="font_16 text-white">Join as an IB with one of the largest global multi-asset brokers.</p>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="step-circle">2</div>
                <h5 className="sub_small_heading part_refer">Refer</h5>
                <p className="font_16 text-white">Expand your referral network by introducing new clients to trade with Tradsify.</p>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="step-circle">3</div>
                <h5 className="sub_small_heading part_refer">Earn</h5>
                <p className="font_16 text-white">Earn a volume-based commission for each trade made by clients you’ve referred.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="section_space">
          <div className="container">
            <div className="text-center">
              <h2 className="sub_heading mb-xl-5 mb-3">FREQUENTLY ASKED QUESTIONS</h2>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item border-0 mb-md-4 mb-3">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed font_20"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      1 .  What is the Tradsify Partners Program?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body text-start font_16">
                      Tradsify Partners offers partnership programs for traders that trade financial products such as forex, stocks, indices, commodities and more. Join our Introducing Broker (IB) program for some of the most flexible and competitive commissions in the industry.                                        </div>
                  </div>

                </div>
                <div className="accordion-item border-0 mb-md-4 mb-3">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed font_20"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      2 . Are there any fees to join the Tradsify Partners program?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body text-start font_16">
                      No, joining Tradsify Partners involves no charges. The process of registering as an Introducing Broker is quick, straightforward and entirely free.                                        </div>
                  </div>
                </div>
                <div className="accordion-item border-0 mb-md-4 mb-3">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed font_20"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      3 .  What is an Introducing Broker (IB)?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body text-start font_16">
                      An Introducing Broker program is designed to provide individuals with a strong trader audience the opportunity to generate consistent earnings from their clients’ trading activities.
                    </div>
                  </div>
                </div>
                <div className="accordion-item border-0 mb-md-4">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed font_20"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      4 . How can I attract leads and clients?
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body text-start font_16">
                      You can attract leads and clients by sharing your unique tracking links, which will lead your referrals to Tradsify landing pages. You can do these by building an online presence, offering educational content, and using paid advertising, just to name a few. But don’t worry, as our partner, we ensure that we provide solutions designed to aid you in advancing your business.                                        </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="section_space partner_contact overflow-hidden">
          <div className="container">
            <div className="row g-4">
              <div className="col-lg-6">
                <div className="contact_icon d-flex gap-3">
                  <img src="assets/images/partnership-overview/contact-us-icon01.svg" alt="" />
                  <img src="assets/images/partnership-overview/contact-us-icon02.svg" alt="" />
                </div>
                <h2 className="sami_medium_heading mb-3">Have a Question? Get in Touch</h2>
                <p className="font_16">If you have any questions or enquiries, fill in the form in this section. Alternatively, contact us at</p>
                <p className="font_16"><Link to="mailto:support@tradsify.com" className="">support@tradsify.com</Link>  and one of our Account Managers will get back to you soon.</p>
              </div>
              <div className="col-lg-6">
                <form className="country_form text-center">
                  <div className="deposit_email position-relative mb-4">
                    <input className="deposit_medium mb-3" type="text" name="email" autocomplete="off" placeholder="Full Name" />
                    <input className="deposit_medium mb-3" type="tel" name="email" id="number" autocomplete="off" placeholder="Phone" />
                    <input className="deposit_medium" type="email" name="email" id="email" autocomplete="off" placeholder="Email" />
                  </div>
                  <div className="mb-4">
                    <select
                      id="countrySelect"
                      className="form-select deposit_country"
                      value={selectedCountry}
                      onChange={(e) => setSelectedCountry(e.target.value)}
                    >
                      <option value="" disabled>
                        -- Select Country --
                      </option>
                      {countries.map((country, index) => (
                        <option key={index} value={country}>
                          {country}
                        </option>
                      ))}
                    </select>
                  </div>
                  <textarea
                    className="deposit_medium partner_message"
                    id="message"
                    name="message"
                    placeholder="Message"
                    rows="4"
                    cols="50"
                    value={message}
                    onChange={handleInputChange}>
                  </textarea>
                  <div className="text-start font_16 mt-2" id="charCount">{`${message.length} of ${maxLength} max characters`}</div>
                  <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn_blue px-lg-5 contact_button mb-4">CHECK NOW</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </ScrollToTop>
    </>
  )
}