import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import Header from "../component/Header";
import Footer from "../component/Footer";
export default function About() {
  return (
    <>
      <ScrollToTop>
        <Header />
        <section className="section_space about_section">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h1 className="main_heading  z-4 position-relative">
                  Get to Know tradsify
                </h1>
                <p className="font_15 position-relative ">
                  Whether you are from India, Philippines or beyond, discover the power of CFD trading
                  with our top-rated platforms: Metatrader 5.Trade Smarter with Our Trading Tools.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="section_space pt-0">
          <div className="container">
            <div className="row row gx-xl-5 gx-md-4 gx-3 mt-xl-0 mt-sm-1">
              <div className="col-lg-3 col-6">
                <div className="border-image hover_gradgent">
                  <h3 className="sami_medium_heading  mb-xl-4">
                    12Years+
                  </h3>
                  <p className="font_20 mb-0">Worldwide</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="border-image hover_gradgent">
                  <h3 className="sami_medium_heading  mb-xl-4">70+</h3>
                  <p className="font_20 mb-0">Awards and counting</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="border-image hover_gradgent">
                  <h3 className="sami_medium_heading  mb-xl-4">42 mln+</h3>
                  <p className="font_20 mb-0">Trading accounts opened</p>
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="border-image hover_gradgent">
                  <h3 className="sami_medium_heading  mb-xl-4">180+</h3>
                  <p className="font_20 mb-0">Countries across the world</p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="section_space pt-0">
          <div className="container">
            <div className="row g-lg-5 g-4 align-items-center">
              <div className="col-lg-6">
                <img
                  src="/assets/images/about/who.png"
                  alt=""
                  className="img-fluid w-100"
                />
              </div>
              <div className="col-lg-6">
                <h2 className="sub_heading">Who We Are</h2>
                <p className="font_15">
                  Tradsify is a global, multi-asset CFD broker offering clients access to a nimble and powerful service for CFD trading on Forex, Indices, Stocks, Commodities, ETFs and Bonds.
                  We aim to help our valued clients grow their earnings, with a focus on offering competitive and industry-leading commissions via our partnership programs.
                </p>
                <p className="font_15">
                  We are a leading CFD provider within the industry, having built a trusted community of traders who have chosen us as their preferred platform for their trading needs. In addition, Tradsify is regulated in various jurisdictions including tier-1 regulatory bodies in the global financial domain.
                </p>
                <p className="font_15">
                  Auto Trading, Portfolio Management, and Trade
                  Export—all in one platform.Gain an edge with lightning-fast execution, powerful charting, and access to professional signals.
                </p>
                <p className="font_15">
                  Explore Trading Tips, Guides and Technical
                  Analysis Articles on Tradsify.
                </p>
                <p className="font_15 mb-0">
                  Our lightning-fast execution speeds on our CFD trading platform ensure that traders can seize market opportunities quickly and efficiently.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section_space ">
          <div className="container">
            <div>
              <h4 className="text-center sub_heading mb-xl-5
               mb-3">
                Tradsify Values
              </h4>
            </div>
            <div className="row g-md-4 g-3">
              <div className="col-lg-4">
                <div className="about_value_card">
                  <h6 className="sub_para_title">User profit First</h6>
                  <p className="font_15">
                    We prioritize our users in everything we do, enabling them
                    to constantly stay ahead and do more .
                  </p>

                  <img
                    src="/assets/images/about/wallet.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="about_value_card">
                  <h6 className="sub_para_title">Reliable</h6>
                  <p className="font_15">
                    We are committed to providing a secure platform with 100%
                    user asset protection and 24/7 global support.
                  </p>
                  <img
                    src="/assets/images/about/protected.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="about_value_card">
                  <h6 className="sub_para_title">Diversified</h6>
                  <p className="font_15">
                    We provide a comprehensive range of products and services to
                    users of all levels, from beginners to professionals.
                  </p>
                  <img
                    src="/assets/images/about/pie_chart.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_space">
          <div className="container">
            <div className="row gy-3 gx-xl-3 gx-md-3 align-items-center">
              <div className="col-lg-5">
                <h2 className="sub_heading mb-xl-4 mt-lg-5">
                  Trade With An Advantage
                </h2>
                <p className="font_15 pe-lg-5">
                  Experience one-click trading, comprehensive TradingView charts, expert market analysis, and real-time price alerts all within the same CFD trading platform.
                </p>
                <p className="font_15 pe-lg-5 ">
                  Build your trading knowledge with our free articles, interactive courses and webinars, covering everything from how the markets work, to complex technical strategies.
                </p>
                <p className="font_15 pe-lg-5 mb-0">
                  We are committed to providing transparent pricing and competitive fees, allowing CFD traders to understand the costs associated with their trades.
                </p>
                {/* <button className="btn btn_black mt-3">View More</button> */}
              </div>
              <div className="col-lg-3 pt-xl-5 mt-lg-5 offset-lg-1">
                <div className="bg_grad we_do_card mb-3 mt-lg-5 mt-2 text-light">
                  <img
                    src="/assets/images/about/feature-2-icon-1.svg"
                    alt=""
                    className="img-fluid mb-xl-5 mb-lg-2 pt-xl-3"
                  />
                  <h6 className="font_20 fw-bold mt-xl-4 mt-3 w-bold ">
                    Low Spreads From 0.2 Pips
                  </h6>
                  <p className="font_15 mb-0">
                    Spreads affect your profits the most.
                  </p>
                </div>
                <div className="card we_do_card text-light">
                  <img
                    src="/assets/images/about/feature-2-icon-2.svg"
                    alt=""
                    className="img-fluid mb-xl-5 mb-lg-2 pt-xl-3"
                  />
                  <h6 className="font_20 fw-bold mt-xl-4 mt-3">
                    Quick Withdrawals
                  </h6>
                  <p className="font_15 mb-0">
                    Easy withdrawals and deposits currencies.
                  </p>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="bg_grad we_do_card text-light mb-3">
                  <img
                    src="/assets/images/about/feature-2-icon-3.svg"
                    alt=""
                    className="img-fluid mb-xl-5 mb-lg-2 pt-xl-3"
                  />
                  <h6 className="font_20 fw-bold mt-xl-4 mt-3">
                     Customer Support 24/7
                  </h6>
                  <p className="font_15 mb-0">
                     Whatever question you might have, drop us a line.
                  </p>
                </div>
                <div className="card we_do_card text-light">
                  <img
                    src="/assets/images/about/feature-2-icon-4.svg"
                    alt=""
                    className="img-fluid mb-xl-5 mb-lg- mb-2 pt-xl-3"
                  />
                  <h6 className="font_20 fw-bold mt-xl-4 mt-3">
                    No Commission
                  </h6>
                  <p className="font_15 mb-0">
                    Pay no trading fees, swaps, or payment method charges. 
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section_space">
          <div className="container">
            <div className="row g-md-4 g-3">
              <div className="col-lg-6">
                <div className="why_section_card p-5">
                  <img
                    src="/assets/images/about/mission.png"
                    alt=""
                    className="img-fluid mb-4 img-size"
                  />
                  <h5 className="small_heading">Mission</h5>
                  <p className="mb-0 font_15">
                    We are committed to providing a trustworthy and transparent <br />
                    platform, making accessible and friendly to everyone.
                  </p>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="why_section_card">
                  <img
                    src="/assets/images/about/vision.png"
                    alt=""
                    className="img-fluid mb-3 vision_img"
                  />
                  <h5 className="small_heading">Vision</h5>
                  <p className="mb-0 font_15">
                    We aim to build a gateway for the next billion users.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </ScrollToTop>
    </>
  );
}
