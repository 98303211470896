import React from "react";
export default function MetatradeHero({
  title = "",
  paragraph = "",
  btn = "",
  paragraph2 = "",
}) {
  return (
    <>
      <section className="section_space about_section mt5_bg">
        <div className="container">
          <h1 className="main_heading text-light">{title}</h1>
          <p className="font_15 mb-md-5 mb-4">
            {paragraph}
            <br />
            {paragraph2}
          </p>
          <div className="d-flex gap-3 gap-md-2">
            <a
              href="https://download.mql5.com/cdn/mobile/mt5/android?server=Tradsify-Live"
             >
              <img src="assets/images/social/windows.svg" alt="" className="img-fluid" />
            </a>
            <a
              href="https://download.mql5.com/cdn/mobile/mt5/ios?server=Tradsify-Live"
             >
              <img src="assets/images/social/macos.svg" alt="" className="img-fluid" />
            </a>
            <a
              href="https://download.mql5.com/cdn/web/tradsify.ltd/mt5/tradsify5setup.exe"
             >
              <img src="assets/images/social/dextop.svg" alt="" className="img-fluid" />
            </a>
          </div>
        </div>
      </section>
    </>
  );
}
