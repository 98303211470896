import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import ScrollToTop from "../component/ScrollToTop";
import { Link } from "react-router-dom";

export default function Course() {
    return (
        <>
            <ScrollToTop>
                <Header />
                <section className="section_space course_hero_bg">
                    <div className="container">
                        <div className="row align-items-center g-4">
                            <div className="col-lg-12 col-md-12 order-1 order-md-1">
                                <h2 className="main_heading text-white text-center mb-2">
                                    Courses
                                </h2>
                                <p className="font_16 text-white text-center mt-3">
                                    Explore Trading Tips, Guides and Technical
                                    <br />
                                    Analysis Articles on Tradsify
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space">
                    <div className="container">
                        <div className="row align-items-stretch">
                            <div className="col-lg-4">
                                <div className="course_card h-100">
                                    <h4 className="sub_small_heading">
                                        Forex Beginner Course
                                    </h4>
                                    <p className="font_15 mb-0">
                                        New to trading? Join us on an informative journey into the world of forex with our comprehensive beginner's course! Whether you've just heard about forex or have taken a few steps but feel lost, we've crafted this course especially for you.
                                    </p>
                                    <ul className="mt-3">
                                        <li className="font_15">
                                            <img src="" alt="" />
                                            24 December 2024
                                        </li>
                                        <li className="font_15">
                                            <img src="" alt="" />
                                            90 mins
                                        </li>
                                        <li className="font_15">
                                            <img src="" alt="" />
                                            Beginner
                                        </li>
                                    </ul>
                                </div>

                            </div>
                            <div className="col-lg-4">
                                <div className="course_card h-100">
                                    <h4 className="sub_small_heading">
                                        Stock Beginner Course
                                    </h4>
                                    <p className="font_15 mb-0">
                                        New to trading? Join us on an informative journey into the world of stock with our comprehensive beginner's course! This course will dive into the basics of trading stock and beyond, leaving you better prepared to make your first trade.
                                    </p>
                                    <ul className="mt-3">
                                        <li className="font_15">
                                            <img src="" alt="" />
                                            24 December 2024
                                        </li>
                                        <li className="font_15">
                                            <img src="" alt="" />
                                            90 mins
                                        </li>
                                        <li className="font_15">
                                            <img src="" alt="" />
                                            Beginner
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="course_card h-100">
                                    <h4 className="sub_small_heading">
                                        Indices Beginner Course
                                    </h4>
                                    <p className="font_15 mb-0">
                                        New to trading? Join us on an informative journey into the world of indices with our comprehensive beginner's course! This course will guide you through the fundamentals of trading indices, providing you with the essential knowledge and strategies to start trading. Whether you are just starting out or looking to refine your understanding, this course is tailored to help you navigate the complexities of indices trading.
                                    </p>
                                    <ul className="mt-3"> 
                                        <li className="font_15">
                                            <img src="" alt="" />
                                            24 December 2024
                                        </li>
                                        <li className="font_15">
                                            <img src="" alt="" />
                                            90 mins
                                        </li>
                                        <li className="font_15">
                                            <img src="" alt="" />
                                            Beginner
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </ScrollToTop>

        </>
    )
}