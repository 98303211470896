import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link } from "react-router-dom";

export default function Tradingaccounts() {

    return (
        <>
            <ScrollToTop>
                <Header />
                <section className="section_space contact_us">
                    <div className="container">
                        <div className="row align-items-center text-center justify-content-center">
                            <div className="col-lg-9 text-section">
                                <h2 className="main_heading">
                                    <span>Trading Accounts</span>
                                </h2>
                                <p className="font_16">
                                    A trading account for traders of all experience levels.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space">
                    <div className="container">
                        <div className="row g-3">
                           
                            <div className="col-xl-8 col-lg-8 col-md-6 px-lg-3 py-lg-2">
                                <Link to="/pricing" className="text-decoration-none">
                                    <div className="stan_traders">
                                        <div className="pro_heading">
                                            {/* <img src="assets/images/trandingaccounts/pro3.svg" alt="" /> */}
                                            <h4 className="text-uppercase fs-1 fst-italic fw-bold">STANDARD</h4>
                                            <span className="right_arrow">
                                                <img src="assets/images/trandingaccounts/rightarrow.svg" alt="" />
                                            </span>
                                        </div>
                                        <div className="pro_para">
                                            <p class="font_16">Novice Traders</p>
                                            <p class="font_16">For beginner traders looking for direct market access with no commissions.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 px-lg-3 py-lg-2">
                                <Link to="/pricing" className="text-decoration-none">
                                    <div className="stan_exp">
                                        <div className="pro_heading">
                                            {/* <img src="assets/images/trandingaccounts/pro4.svg" alt="" /> */}
                                            <h4 className="text-uppercase fs-1 fst-italic fw-bold">ECN</h4>
                                            <span className="right_arrow">
                                                <img src="assets/images/trandingaccounts/rightarrow.svg" alt="" />
                                            </span>
                                        </div>
                                        <div className="pro_para">
                                            <p class="font_16">Any Level</p>
                                            <p class="font_16">Ideal for traders with religious beliefs that prohibit receiving or paying swaps.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 px-lg-3 py-lg-2">
                                <Link to="/pricing" className="text-decoration-none">
                                    <div className="pro_traders">
                                        <div className="pro_heading">
                                            {/* <img src="assets/images/trandingaccounts/pro1.svg" alt="" /> */}
                                            <h4 className="text-uppercase fs-1 fst-italic fw-bold">pro</h4>
                                            <span className="right_arrow">
                                                <img src="assets/images/trandingaccounts/rightarrow.svg" alt="" />
                                            </span>
                                        </div>
                                        <div className="pro_para">
                                            <p class="font_16">Professional Traders</p>
                                            <p class="font_16">For professional traders and money managers who trade large volumes.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-6 px-lg-3 py-lg-2">
                                <Link to="/pricing" className="text-decoration-none">
                                    <div className="pro_exp">
                                        <div className="pro_heading">
                                            {/* <img src="assets/images/trandingaccounts/pro2.svg" alt="" /> */}
                                            <h4 className="text-uppercase fs-1 fst-italic fw-bold">prime</h4>
                                            <span className="right_arrow">
                                                <img src="assets/images/trandingaccounts/rightarrow.svg" alt="" />
                                            </span>
                                        </div>
                                        <div className="pro_para">
                                            <p class="font_16">Experienced Traders</p>
                                            <p class="font_16">Offering seasoned traders razor-sharp spreads, low commissions, and deep liquidity.</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space cards_bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 mb-lg-5 mb-4">
                                <h2 class="sub_heading text-center mb-xl-2 mb-3">Trading Accounts</h2>
                                <p className="font_16 text-center mb-0">
                                    A trading account for traders of all experience levels.
                                </p>
                            </div>
                            <div className="parents">
                                <div class="Trade_card">
                                    <div class="d-flex justify-content-center mb-4">
                                        <img src="assets/images/trandingaccounts/card1.svg" alt="Transparent Pricing" />
                                    </div>
                                    <h3 class="sub_small_heading text-center mb-lg-4 mb-4">Minimum Trading Size</h3>
                                    <p class="font_15 text-center mb-0">Start trading from just 0.01 lot per trade.</p>
                                </div>
                                <div class="Trade_card">
                                    <div class="d-flex justify-content-center mb-4">
                                        <img src="assets/images/trandingaccounts/card2.svg" alt="Transparent Pricing" />
                                    </div>
                                    <h3 class="sub_small_heading text-center mb-lg-4 mb-4">Variety of Currencies Available</h3>
                                    <p class="font_15 text-center mb-0">Conveniently fund your trading account with a range of base currencies.</p>
                                </div>
                                <div class="Trade_card">
                                    <div class="d-flex justify-content-center mb-4">
                                        <img src="assets/images/trandingaccounts/card3.svg" alt="Transparent Pricing" />
                                    </div>
                                    <h3 class="sub_small_heading text-center mb-lg-4 mb-4">1,000+ Tradable instruments</h3>
                                    <p class="font_15 text-center mb-0">Access over 1,000+ CFD assets including forex, shares, indices, gold, commodities and more.</p>
                                </div>
                                <div class="Trade_card">
                                    <div class="d-flex justify-content-center mb-4">
                                        <img src="assets/images/trandingaccounts/card4.svg" alt="Transparent Pricing" />
                                    </div>
                                    <h3 class="sub_small_heading text-center mb-lg-4 mb-4">Low spreads & commissions</h3>
                                    <p class="font_15 text-center mb-0">With competitive spreads from 0.0, trade all the products at minimum cost. </p>
                                </div>
                                <div class="Trade_card">
                                    <div class="d-flex justify-content-center mb-4">
                                        <img src="assets/images/trandingaccounts/card5.svg" alt="Transparent Pricing" />
                                    </div>
                                    <h3 class="sub_small_heading text-center mb-lg-4 mb-4">Transparency</h3>
                                    <p class="font_15 text-center mb-0">Stay in control with transparent pricing and commissions on all trading accounts.</p>
                                </div>
                                <div class="Trade_card">
                                    <div class="d-flex justify-content-center mb-4">
                                        <img src="assets/images/trandingaccounts/card6.svg" alt="Transparent Pricing" />
                                    </div>
                                    <h3 class="sub_small_heading text-center mb-lg-4 mb-4">Leverage</h3>
                                    <p class="font_15 text-center mb-0">Open larger trading positions using leverage.</p>
                                </div>
                                <div class="Trade_card">
                                    <div class="d-flex justify-content-center mb-4">
                                        <img src="assets/images/trandingaccounts/card7.svg" alt="Transparent Pricing" />
                                    </div>
                                    <h3 class="sub_small_heading text-center mb-lg-4 mb-4">Desktop & Mobile</h3>
                                    <p class="font_15 text-center mb-0">Respond instantly to market news and trade anywhere, anytime with our diverse trading platforms.</p>
                                </div>
                                <div class="Trade_card">
                                    <div class="d-flex justify-content-center mb-4">
                                        <img src="assets/images/trandingaccounts/card8.svg" alt="Transparent Pricing" />
                                    </div>
                                    <h3 class="sub_small_heading text-center mb-lg-4 mb-4">Expert advisors (EA)</h3>
                                    <p class="font_15 text-center mb-0">Effortlessly integrate your expert advisors with our MT5 configurations.</p>
                                </div>
                                <div class="Trade_card">
                                    <div class="d-flex justify-content-center mb-4">
                                        <img src="assets/images/trandingaccounts/card9.svg" alt="Transparent Pricing" />
                                    </div>
                                    <h3 class="sub_small_heading text-center mb-lg-4 mb-4">Low latency trading</h3>
                                    <p class="font_15 text-center mb-0">Optimise your trading performance and reduce slippage with Tradsify 's cutting-edge technology.</p>
                                </div>
                                <div class="Trade_card">
                                    <div class="d-flex justify-content-center mb-4">
                                        <img src="assets/images/trandingaccounts/card10.svg" alt="Transparent Pricing" />
                                    </div>
                                    <h3 class="sub_small_heading text-center mb-lg-4 mb-4">24/7 Customer Support</h3>
                                    <p class="font_15 text-center mb-0">Our dedicated customer support team is available 24/7 to provide assistance for all your trading account queries.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="trad_bg">
                                    <div className="text-center mb-lg-5 mb-4">
                                        <h2 className="sub_heading text-white">Try Any of Our Trading Accounts</h2>
                                    </div>
                                    <div className="d-flex justify-content-center gap-3 align-items-center flex-wrap flex-md-nowrap flex-column flex-md-row">
                                        <a href="#/" className="btn btn_blue px-lg-5 fw-semibold font_16 trade_btn">TRADE NOW</a>
                                        <a href="#/" className="btn btn_blue px-lg-5 fw-semibold font_16 trade_btn">LEARN TO TRADE</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space">
                    <div className="container">
                        <div className="text-center">
                            <h2 className="sub_heading mb-xl-5 mb-3">Frequently Asked Questions</h2>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="false"
                                            aria-controls="collapseOne"
                                        >
                                            1 .  What is a trading account?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            A trading account is an account opened with a broker that allows traders to engage with a wide range of offered assets. Utilising the trading account, traders can buy or sell securities such as stocks, commodities, or foreign exchange. It serves as your personal account for day-to-day trading.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            2 .  What is the best trading account to open?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse "
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            The best trading account varies from one individual to another, as it depends on your personal needs and goals as a trader. Fee structures play a role in differentiating accounts; for instance, some accounts may offer low spreads coupled with commissions, while others might provide commission-free trading but with wider spreads.
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                            Sometimes, a trader’s preferred trading account would also be dependent on the platform interface and overall user experience.It is crucial for traders to identify the factors that suit their unique trading style before selecting and opening an account.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                        >
                                            3 .   Does it cost money to open a trading account?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            At Tradsify, we are pleased to inform you that there are no charges for opening any of the trading accounts offered by our platform. However, it is important to note that we do have minimum deposit requirements in place. Furthermore, it is essential to note that commissions or other fees may be applicable to each trading transaction, depending on the specific trading account selected.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour"
                                        >
                                            4 .  How do I open a trading account?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFour"
                                        className="accordion-collapse collapse "
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            Opening a Tradsify account is a quick and straightforward process that takes just 5 minutes of your time. To begin, visit the Tradsify registration page or click here to start the process. There, you will be guided through a registration form that needs to be completed.
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                            During the registration, you will have the opportunity to select the specific trading account you wish to open. Carefully consider your options and choose the account that aligns with your trading style. Once you have made your selection and provided the required information, your account will be promptly set up.                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive"
                                            aria-expanded="false"
                                            aria-controls="collapseFive"
                                        >
                                            5 . How much should a beginner start trading with?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFive"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingFive"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            The initial amount that you deposit would depend on your financial circumstances and also goals in trading. You can start with as little as $50 with Tradsify. If you plan on making larger trades, a bigger initial deposit could reduce the need for multiple deposits.
                                        </div>
                                        <div className="accordions-body text-start font_16">
                                            For beginners, you can also consider trying out a demo account. A demo account is designed to allow traders to explore trading different products and familiarise themselves with the functionalities of the trading platform.
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                            At Tradsify, we offer a demo account with virtual funds of up to $100,000 for beginners to practise trading before making the decision to switch to a live account. If your virtual funds are depleted, you can simply reset the account to start practising again.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </ScrollToTop>
        </>
    )
}