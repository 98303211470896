import React from "react";
import MetatradeHero from "../component/MetatradeHero";
import LeftSection from "../component/LeftSection";
import RightSection from "../component/RightSection";
import MetaCard from "../component/MetaCard";
import Footer from "../component/Footer";
import Header from "../component/Header";
import TradeMt5 from "../component/TradeMt5";
import MetaHoverCard from "../component/MetaHoverCard";
import MtFaq from "../component/MtFaq";
export default function Metatrader4() {
  const metatrader4card = [
    {
      img: "/assets/images/metatrade5/custom_trading.svg",
      heading: "Trading flexibility",
      paragraph:
        "Experience flexible trading on MetaTrader 4 with Tradsify. Trade CFDs with 6 types of pending orders and 2 execution types: Instant Execution and Market Execution. Enjoy the ability to build and implement your trading strategies, regardless of complexity, and trade your desired financial instruments.",
    },
    {
      img: "/assets/images/metatrade5/chart_line_e669515409.svg",
      heading: "Analytical tools",
      paragraph:
        "With 30 built-in technical indicators and 23 analytical objects, the terminal has an arsenal of analytical tools, including technical analysis, for you to effectively respond to market movements and price changes, and identify entry and exit points. Other tools also include trailing stop and trading signals on the desktop platform.",
    },
    {
      img: "/assets/images/metatrade5/settings_automation_1c8b5c44d2.svg",
      heading: "Automated trading",
      paragraph:
        "Autotrading on the financial markets is possible on the MetaTrader 4 desktop terminals. Thanks to trading robots, Expert Advisors (EAs), you can fully automate trading and analytical operations on the financial markets. You are also able to create your own expert advisor and scripts using MetaQuotes Language 4 (MQL4) or import a new Expert Advisor easily.",
    },
    {
      img: "/assets/images/metatrade5/lock_58088c8999.svg",
      heading: "Security",
      paragraph:
        "Financial and data security is of utmost importance to us. Thus, we place the highest priority in protecting our client data by encrypting all communications between the server and the MT4 platform using 128-bit keys.",
    },
  ];
  const tradecard = [
    {
      heading: "Forex",
      paragraph:
        "There are more than 100 currency pairs available for CFD trading on MT4 in Tradsify. We offer major currency pairs, including EURUSD, GBPUSD and USDJPY, and minor currency pairs. There is also a long list of exotic pairs available for you to trade CFDs on.",
    },
    {
      heading: "Metals",
      paragraph:
        "On MT4 with Tradsify, you can trade CFDs on metals in the form of currency pairs, which include XAUUSD, XAUEUR, XAUGBP and XAUAUD for gold and XAGUSD, XAGEUR, XAGGBP and XAGAUD for silver. You can also trade on platinum (XPT) and palladium (XPD) in currency pairs.",
    },
    {
      heading: "Energies",
      paragraph:
        "Expand your portfolio on MT4 with Tradsify and trade CFDs on popular energies like brent crude oil (UKOIL), crude oil (USOIL) and natural gas (XNGUSD) with better-than-market conditions.",
    },
    {
      heading: "Stocks",
      paragraph:
        "Gain exposure to a large selection of stock CFDs while trading on MT4 with Tradsify. Trade CFDs on stocks from different industries, such as technology (APPL, META), consumer discretionary (TSLA), consumer staples (KO) and others.",
    },
    {
      heading: "Indices",
      paragraph:
        "Diversify your portfolio and trade CFDs on major stock indices from the US, UK, Germany, Japan, and China on MT4 with Tradsify. Access popular global indices like the Dow Jones, NASDAQ, FTSE 100, and NIKKEI 225.",
    },
    {
      heading: "currencies",
      paragraph:
        "You can trade the most popular currency pairs on MetaTrader 4. These include CFDs on Bitcoin, Ethereum and Litecoin, with Bitcoin available in BTCUSD, BTCKRW, BTCJPY and more.",
    },
  ];
  const metaHovercard = [
    {
      sub_title:"1 STEP",
      heading: "Download MT4",
      paragraph:
        "Open a live account or demo account to get the Tradsify MetaTrader 4 (MT4) software.",
    },
    {
        sub_title:"2 STEP",
        heading: "Run Setup",
        paragraph:
          "Open the Tradsifysetup.exe file and select “Run”.",
    },
    {
        sub_title:"3 STEP",
        heading: "Complete Installation",
        paragraph:
          "Allow changes, agree to the Licence Agreement, and choose settings if needed.",
    },
    {
        sub_title:"4 STEP",
        heading: "Finish MT4 Installation",
        paragraph:
          "Click `Next` to proceed through the Tradsify MT4 Setup. The installer will download the latest MT4 version. Once done, click `Finish`.",
    },  
  ];
  const faq = [
    {
      question: "1. Can you trade CFD on MetaTrader 4 (MT 4) platform?",
      answer:
        "Yes, you can trade Contracts for Difference (CFDs) on MT 4 from your home country, whether you are from India or other regions of the world. Tradsify offers CFD trading for forex, indices, shares, commodities, ETFs and bonds. You can trade these CFDs on both MetaTrader 4 (MT4) and MetaTrader 4 (MT 4) trading platforms.",
    },
    {
      question: "2. What can you trade on MetaTrader 4 (MT 4)?",
      answer:
        "You can trade CFDs for forex, indices, shares, commodities, ETFs and bonds on MetaTrader 4. This means access to highly traded forex pairs, leading indices, top companies listed on stock exchanges, and more.",
    },
    {
      question: "3. Is MetaTrader 4 (MT 4) suitable for beginners?",
      answer:
        "MetaTrader 4 can be a suitable trading platform for all types of traders, from beginner to expert. As a beginner, MT 4 allows you to gain access to a variety of tools for your fundamental and technical analysis. With the latest version of MT 4, you can also tap on the support of forex robots, trading signals and the capability to hedge positions. The MetaTrader 4 (MT 4) platform can be accessed anywhere, anytime, and is available on both mobile apps and web browsers." ,
    },
    {
      question: "4. Can you use MetaTrader 4 (MT 4) for stocks?",
      answer:
        "Yes, you can use MetaTrader 4 to trade stocks via share CFDs on Tradsify. Stocks that you can trade include Apple (AAPL), Tesla (TSLA), Microsoft (MSFT), Netflix (NFLX), Alphabet (GOOG) and more.",
    },
  ];
  return (
    <>
      <Header />
      <MetatradeHero
        title="MetaTrader 4 (MT4)"
        paragraph="Free to download right on our website, Tradsify provides traders with MetaTrader 4 trading platform for trading currency pairs and other financial instruments through contract for differences (CFDs)."
        btn="Download MetaTrader 4"
      />
      <LeftSection
        img="/assets/images/mt4/mt4.webp"
        main_title="About MetaTrader 4"
        para1="A well-known trading platform among traders of all levels and experience, the MetaTrader 4 could be said to be integral to brokers and traders alike."
        para2="Read on to discover the uniqueness of the platform and how it enhances the trading experience."
        heading="Usage of MetaTrader 4"
        metapara1="The many tools of thetradsify, including flexible trading systems, algorithmic trading and mobile trading, provide investment opportunities for traders of all skill levels to trade in the financial markets. These integral parts of MetaTrader 4 not only help you to effectively determine entry and exit points and identify market trends, but also enhance your trading experience."
        metapara2="Supporting trade execution modes, including Market and Instant Execution, the trading platform MetaTrader 4 also provides charts, expert advisors, trading signals and technical indicators. Signals enable traders to copy the trading orders and trading strategies of other traders. There are also financial news and alerts tools so that traders are updated with the latest trading news and articles."
      />
      <MetaCard cardItem={metatrader4card} />
      <RightSection
        heading="MT4 MultiTerminal"
        para1="Enjoy unparalleled convenience managing multiple accounts – up to 128 MetaTrader 4 trading accounts and 10 demo accounts – on a platform designed for account managers, available only for Windows."
        link_text="Download MT4 MultiTerminal"
         img="/assets/images/mt4/mt5.webp"
      />
      <TradeMt5
        title="What you can trade on MT4 "
        para1="At Tradsify, you can enjoy trading CFDs on more than 200
                  instruments, which include trading "
        para2="forex currency pairs, metals, currencies, stocks, indices
                  and energies."
        tradecard={tradecard}
      />
      <MetaHoverCard
        title="MetaTrader 4 (MT4) Download"
        paragraph=" Discover a superior trading platform in MetaTrader 4 (MT4) now."
        metaHovercard={metaHovercard}
      /> 
      <MtFaq title="Frequently asked questions" faq={faq} />
   
      <Footer />
    </>
  );
}
