import React from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import ScrollToTop from "../component/ScrollToTop";
import Faq from "../component/Faq";
import Comparison from "../component/Comparison";
export default function Trading() {
  const trading = [
    {
      id: 1,
      question: "What is tradsify?",
      answer:
        "tradsify is a trading platform designed to simplify and elevate your trading experience.",
    },
    {
      id: 2,
      question: "Is tradsify secure?",
      answer:
        "Yes, we take security very seriously and have implemented robust measures to protect your account and data.",
    },
    {
      id: 3,
      question: "What services does tradsify offer?",
      answer:
        "We offer auto trading, portfolio management, and trade export services.",
    },
    {
      id: 4,
      question: "How do I get started with tradsify?",
      answer:
        "Simply sign up, deposit funds, and choose your service. Our step-by-step guide will walk you through the process.",
    },
    {
      id: 5,
      question: "Can I use tradsify on mobile?",
      answer:
        "Yes, tradsify is fully compatible with mobile devices, allowing you to trade on the go.",
    },
  ];
  return (
    <>
      <ScrollToTop>
        <Header />
        <section className="section_space trading_bg">
          <div className="container">
            <div className="row align-items-end text-xl-start text-center">
              <div className="col-xl-5 text-white wow animate__animated animate__fadeInUp">
                <h1 className="main_heading text-white z-4 position-relative ">
                  Trading With tradsify
                </h1>
                <p className="font_15 position-relative">
                  Trade in top financial markets with tradsify today.
                </p>
              </div>
              <div className="col-xl-7 wow animate__animated animate__fadeInUp">
                <ul className="nav nav-tabs justify-content-center border-0 flex-wrap gap-2">
                  <li
                    className={`nav-item nav-link tabs_back text-dark trading_cards`}
                  >
                    <div>
                      <img
                        src="assets/images/instruments/tab1.svg"
                        alt=""
                        className="img_tab"
                      />
                      <div className="d-flex align-items-center justify-content-center">
                        <p className="font_14 fw-semibold">Forex</p>
                      </div>
                    </div>
                  </li>
                  <li
                    className={`nav-item nav-link tabs_back text-dark trading_cards`}
                  >
                    <div>
                      <img
                        src="assets/images/instruments/tab2.svg"
                        alt=""
                        className="img_tab"
                      />
                      <div className="d-flex align-items-center justify-content-center">
                        <p className="font_14 fw-semibold">Indices</p>
                      </div>
                    </div>
                  </li>
                  <li
                    className={`nav-item nav-link tabs_back text-dark trading_cards`}
                  >
                    <div>
                      <img
                        src="assets/images/instruments/tab3.svg"
                        alt=""
                        className="img_tab"
                      />
                      <div className="d-flex align-items-center justify-content-center">
                        <p className="font_14 fw-semibold">Precious Metals</p>
                      </div>
                    </div>
                  </li>
                  <li
                    className={`nav-item nav-link tabs_back text-dark trading_cards`}
                  >
                    <div>
                      <img
                        src="assets/images/instruments/tab4.svg"
                        alt=""
                        className="img_tab"
                      />
                      <div className="d-flex align-items-center justify-content-center">
                        <p className="font_14 fw-semibold">Soft Commodities</p>
                      </div>
                    </div>
                  </li>
                  <li
                    className={`nav-item nav-link tabs_back text-dark trading_cards`}
                  >
                    <div>
                      <img
                        src="assets/images/instruments/tab5.svg"
                        alt=""
                        className="img_tab"
                      />
                      <div className="d-flex align-items-center justify-content-center">
                        <p className="font_14 fw-semibold">Energy</p>
                      </div>
                    </div>
                  </li>
                  <li
                    className={`nav-item nav-link tabs_back text-dark trading_cards`}
                  >
                    <div>
                      <img
                        src="assets/images/instruments/tab6.svg"
                        alt=""
                        className="img_tab"
                      />
                      <div className="d-flex align-items-center justify-content-center">
                        <p className="font_14 fw-semibold">ETFs</p>
                      </div>
                    </div>
                  </li>
                  <li
                    className={`nav-item nav-link tabs_back text-dark trading_cards`}
                  >
                    <div>
                      <img
                        src="assets/images/instruments/tab7.svg"
                        alt=""
                        className="img_tab"
                      />
                      <div className="d-flex align-items-center justify-content-center">
                        <p className="font_14 fw-semibold">Share CFDs</p>
                      </div>
                    </div>
                  </li>
                  <li
                    className={`nav-item nav-link tabs_back text-dark trading_cards`}
                  >
                    <div>
                      <img
                        src="assets/images/instruments/tab8.svg"
                        alt=""
                        className="img_tab"
                      />
                      <div className="d-flex align-items-center justify-content-center">
                        <p className="font_14 fw-semibold">Bonds</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="section_space">
          <div className="container">
            <div className="cfd_box">
              <div className="cfd_img">
                <img
                  src="assets/images/trading/cfdbroker.webp"
                  alt=""
                  className="img-fluid "
                />
              </div>
              <div className="cfd_card">
                <h2 className="sami_medium_heading mb-xl-3 mb-3">
                  Online CFD Trading Broker Platform & Tools
                </h2>
                <p className="font_15">
                  Established in 2009, Tradsify has emerged as one of the world's
                  leading CFD brokers.
                  we are pioneers in the industry, offering you the confidence
                  of trading with a trusted broker. Tradsify's CFD trading
                  platform offers access to over 1,000 CFD products and a range
                  of advanced trading tools and features.
                </p>
                <p className="font_15">
                  No matter where you are, you can seamlessly explore this array
                  of markets through the powerful MetaTrader 4 and MetaTrader 5
                  platforms, along with WebTrader and our mobile app. Make the
                  most of these features, including one-click trading, advanced
                  charting, access to free educational materials, and a suite of
                  risk management tools.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="section_space">
          <div className="container">
            <h2 className="sub_heading text-center mb-xl-5 mb-3">
              Trading Account Comparison
            </h2>
            <div class="table-responsive comparison-table-wrapper">
              <table class="comparison-table">
                <thead>
                  <tr class="table-header">
                    <th class="account_th"></th>
                    <th class="account_th popular-cell">
                      <img
                        src="assets/images/trading/raw.svg"
                        alt=""
                        className="mb-3"
                      />
                      <div>
                        <span className="font_14 fw-normal">
                          Tight spreads & <br />
                          lowest commissions
                        </span>
                      </div>
                      <div className="d-flex justify-content-center gap-2">
                        <p className="font_15 mb-0">0.0</p>
                        <span className="font_15 mb-0 mt-lg-2 mt-1">pip</span>
                      </div>
                      <span class="popular-tag">Popular</span>
                    </th>
                    <th class="account_th">
                      <img
                        src="assets/images/trading/standard.svg"
                        alt=""
                        className="mb-3"
                      />
                      <div>
                        <span className="font_14 fw-normal">
                          No commission
                          <br /> trades
                        </span>
                      </div>
                      <div className="d-flex justify-content-center gap-2">
                        <p className="font_15 mb-0">1.1</p>
                        <span className="font_15 mb-0 mt-lg-2 mt-1">pip</span>
                      </div>
                    </th>
                    <th class="account_th">
                      <img
                        src="assets/images/trading/comparision_pro.svg"
                        alt=""
                        className="mb-3"
                      />
                      <div>
                        <span className="font_14 fw-normal">
                          Tight spreads & <br />
                          lowest commissions
                        </span>
                      </div>
                      <div className="d-flex justify-content-center gap-2">
                        <p className="font_20 mb-0">0.0</p>
                        <span className="font_15 mb-0 mt-lg-2 mt-1">pip</span>
                      </div>
                    </th>
                    <th class="account_th">
                      <img
                        src="assets/images/trading/cent.svg"
                        alt=""
                        className="mb-3"
                      />
                      <div>
                        <span className="font_14 fw-normal">
                          Small
                          <br /> deposits
                        </span>
                      </div>
                      <div className="d-flex justify-content-center gap-2">
                        <p className="font_20 mb-0">0.0</p>
                        <span className="font_15 mb-0 mt-lg-2 mt-1">pip</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="Comparison_body">
                  <tr class="table-row">
                    <td class="table-cell">Minimum Deposit</td>
                    <td class="table-cell">$50</td>
                    <td class="table-cell">$50</td>
                    <td class="table-cell">
                      <p className="mb-0">
                        $10,000 <br />
                        <span>
                          Refer to your assigned sales representative for terms
                          and conditions
                        </span>
                      </p>
                    </td>
                    <td class="table-cell">$50</td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-cell">Commission / per lot per side</td>
                    <td class="table-cell">From $3.00</td>
                    <td class="table-cell">$0</td>
                    <td class="table-cell">From $1.50</td>
                    <td class="table-cell">From $0–$3.00</td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-cell">
                      Free Access to ProTrader Platform
                    </td>
                    <td class="table-cell">
                      <img src="assets/images/trading/yes.svg" alt="" />
                    </td>
                    <td class="table-cell">
                      <span>
                        Available for clients who meet the requirements
                      </span>
                    </td>
                    <td class="table-cell">
                      <img src="assets/images/trading/yes.svg" alt="" />
                    </td>
                    <td class="table-cell">
                      <img src="assets/images/trading/wrong.svg" alt="" />
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-cell">Competitive Prices</td>
                    <td class="table-cell">
                      <img src="assets/images/trading/yes.svg" alt="" />
                    </td>
                    <td class="table-cell">
                      <span>Refer to account type description</span>
                    </td>
                    <td class="table-cell">
                      <img src="assets/images/trading/yes.svg" alt="" />
                    </td>
                    <td class="table-cell">
                      <img src="assets/images/trading/wrong.svg" alt="" />
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-cell">Exclusive Expert Support</td>
                    <td class="table-cell">
                      <img src="assets/images/trading/yes.svg" alt="" />
                    </td>
                    <td class="table-cell">
                      <img src="assets/images/trading/wrong.svg" alt="" />
                    </td>
                    <td class="table-cell">
                      <img src="assets/images/trading/yes.svg" alt="" />
                    </td>
                    <td class="table-cell">
                      <img src="assets/images/trading/wrong.svg" alt="" />
                    </td>
                  </tr>
                  <tr class="table-row">
                    <td class="table-cell">Exclusive Expert Support</td>
                    <td class="table-cell">
                      <img src="assets/images/trading/yes.svg" alt="" />
                    </td>
                    <td class="table-cell">
                      <span>
                        Available for clients who meet the requirements
                      </span>
                    </td>
                    <td class="table-cell">
                      <img src="assets/images/trading/yes.svg" alt="" />
                    </td>
                    <td class="table-cell">
                      <img src="assets/images/trading/wrong.svg" alt="" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </section> */}

        <Comparison />
        <section className="section_space d-flex">
          <div className="container">
            <div className="row g-xl-4 g-3 ">
              <h2 className="sub_heading text-center mb-xl-4 mb-4">
                Why Trade With Tradsify?
              </h2>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="Why_Trade">
                  <div>
                    <img src="assets/images/trading/Accessibility.svg" alt="" />
                  </div>
                  <p className="font_15 fw-bold">
                    Accessibility to popular instruments
                  </p>
                  <p className="font_14">
                    Trade 1,000+ instruments across a diverse range of asset
                    classes.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="Why_Trade">
                  <div>
                    <img src="assets/images/trading/Tight.svg" alt="" />
                  </div>
                  <p className="font_15 fw-bold">Tight, competitive spreads</p>
                  <p className="font_14">
                    Trade popular products at minimum cost with competitive
                    spreads from 0.0.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="Why_Trade">
                  <div>
                    <img src="assets/images/trading/wherever.svg" alt="" />
                  </div>
                  <p className="font_15 fw-bold">Trade wherever, whenever</p>
                  <p className="font_14">
                    Buy and sell anytime. React swiftly to news on our trading
                    platform and mobile app.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="Why_Trade">
                  <div>
                    <img src="assets/images/trading/educational.svg" alt="" />
                  </div>
                  <p className="font_15 fw-bold">Free educational materials</p>
                  <p className="font_14">
                    Equip yourself better with trading knowledge through free
                    educational materials on our academy.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="Why_Trade">
                  <div>
                    <img src="assets/images/trading/markets.svg" alt="" />
                  </div>
                  <p className="font_15 fw-bold">
                    Trade both bull and bear markets
                  </p>
                  <p className="font_14">
                    Flexibility to go long or short in your trades.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="Why_Trade">
                  <div>
                    <img src="assets/images/trading/transparent.svg" alt="" />
                  </div>
                  <p className="font_15 fw-bold">Fair and transparent fees</p>
                  <p className="font_14">
                    Start trading with $0 deposit fees with no hidden fees.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="Why_Trade">
                  <div>
                    <img src="assets/images/trading/mitigation.svg" alt="" />
                  </div>
                  <p className="font_15 fw-bold">Risk mitigation tools</p>
                  <p className="font_14 ">
                    Negative balance protection, price alerts, and stop-loss to
                    ensure you trade within your means.
                  </p>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-md-6">
                <div className="Why_Trade">
                  <div>
                    <img src="assets/images/trading/Powerful.svg" alt="" />
                  </div>
                  <p className="font_15 fw-bold">
                    Powerful MT5 accounts
                  </p>
                  <p className="font_14">
                    Get access to global markets with powerful
                    Metatrader 5 accounts.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="section_space Competitive_bg">
          <div className="container">
            <h2 className="mb-xl-5 mb-4 text-center sub_heading">See Our Competitive Prices</h2>
            <div className="Prices_bg">
              <div class="table-responsive custom-table">
                <table class="table text-center mb-0">
                  <thead>
                    <tr className="table-row">
                      <th></th>
                      <th className="tradesify_border">Tradsify</th>
                      <th className="tradesify_border2">Pepperstone</th>
                      <th className="tradesify_border3">Exness</th>
                      <th className="tradesify_border4">TMGM</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>NZD/JPY</td>
                      <td class="tradsify_price position-relative">1.40</td>
                      <td>1.52</td>
                      <td>1.85</td>
                      <td>1.51</td>
                    </tr>
                    <tr>
                      <td>USD/SGD</td>
                      <td class="tradsify_price position-relative">1.11</td>
                      <td>2.00</td>
                      <td>7.51</td>
                      <td>1.32</td>
                    </tr>
                    <tr>
                      <td>EU50</td>
                      <td class="tradsify_price position-relative">15.33</td>
                      <td>1.99</td>
                      <td>21.77</td>
                      <td>35.27</td>
                    </tr>
                    <tr>
                      <td>NAS100</td>
                      <td class="tradsify_price position-relative">7.09</td>
                      <td>16.45</td>
                      <td>17.30</td>
                      <td>35.53</td>
                    </tr>
                    <tr>
                      <td>SP500</td>
                      <td class="tradsify_price position-relative">3.61</td>
                      <td>4.23</td>
                      <td>4.55</td>
                      <td>23.52</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <p class="text-muted font_15 mt-2">
              *Spreads are in pips. <br />
              *Last updated on 13 August 2024. <br />
              All spreads (in pips) are for indicative purposes only as net
              costs may differ at any time of the day.
            </p>
          </div>
        </section>
        <section className="section_space tradsify_faq">
          <div className="container">
            <div className="text-center">
              <h2
                className="sub_heading mb-xl-5 mb-3
                         wow animate__animated animate__fadeInUp
                        "
              >
                Frequently Asked Questions
              </h2>
              <div className="accordion" id="accordionExample">
                <div className="accordion-item border-0 mb-md-4 mb-3">
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className="accordion-button collapsed font_20"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      1. How do you trade with Tradsify?
                    </button>
                  </h2>
                  <div
                    id="collapseOne"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body font_16">
                      <ul className="list-unstyled">
                        <li>
                          You can trade with Tradsify easily by following the
                          steps below:
                        </li>
                        <li>
                          1. Open a live account with Tradsify and be verified.
                        </li>
                        <li>2. Deposit funds to your newly created account.</li>
                        <li>
                          3. Analyse the product markets and determine which
                          products you wish to trade.
                        </li>
                        <li>
                          4. Open and monitor your first trading position.
                        </li>
                        <li>
                          5. When you think it’s time, close the position to
                          complete the trade.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="accordion-item border-0 mb-md-4 mb-3">
                  <h2 className="accordion-header" id="headingTwo">
                    <button
                      className="accordion-button collapsed font_20"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      2 .What is the difference between Tradsify MT5?
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse "
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body font_16">
                      Yes, we take security very seriously and have implemented
                      robust measures to protect your account and data.
                    </div>
                  </div>
                </div>
                <div className="accordion-item border-0 mb-md-4 mb-3">
                  <h2 className="accordion-header" id="headingThree">
                    <button
                      className="accordion-button collapsed font_20"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      3 . What is the use of the Tradsify app?
                    </button>
                  </h2>
                  <div
                    id="collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body font_16">
                      The Tradsify app is a mobile app that allows users to gain
                      access to a variety of trading assets. It features
                      advanced tools for every trading style, a user-friendly
                      interface, swift account setup, and seamless funding
                      methods.
                    </div>
                  </div>
                </div>
                <div className="accordion-item border-0  mb-md-4 mb-3">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed font_20"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      4 . What is the minimum deposit for Tradsify trade?
                    </button>
                  </h2>
                  <div
                    id="collapseFour"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body font_16">
                      Tradsify allows clients to open a trading account with as
                      little as $50 USD or account currency equivalent for all
                      Standard STP accounts and RAW ECN accounts.
                    </div>
                    <p className="accordion-body font_16">
                      Just remember that you can open and keep a live trading
                      account with a $0 balance, there is no account keeping or
                      maintenance fees. For your subsequent deposits, the
                      minimum deposit amount is $50.
                    </p>
                  </div>
                </div>
                <div className="accordion-item border-0">
                  <h2 className="accordion-header" id="headingFour">
                    <button
                      className="accordion-button collapsed font_20"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      5. How do I withdraw from Tradsify?
                    </button>
                  </h2>
                  <div
                    id="collapseFive"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <ul className="accordion-body list-unstyled">
                      <li>
                        Clients can securely withdraw their funds with just four
                        simple steps:
                      </li>
                      <li>
                        1. Log in to the Tradsify app and navigate to the profile
                        tab.
                      </li>
                      <li>
                        {" "}
                        2. Click on the withdraw button to initiate the process.
                      </li>
                      <li>
                        3. Enter the withdrawal amount and select your preferred
                        withdrawal method.
                      </li>
                      <li>
                        4. Finally, click proceed to finalise the withdrawal
                        process.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </ScrollToTop>
    </>
  );
}
