import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import { useState } from 'react';
// import "swiper/swiper-bundle.css"
// import "swiper/css";
import { Pagination, Mousewheel } from "swiper/modules";

export default function TradingApp() {
    const [activeSlide, setActiveSlide] = useState(1);
    const swiperRef = React.useRef(null);
    const slides = [
        {
            id: 1,
            title2: "Open an account",
            src: "assets/images/tradingapp/swiper1.webp",
            number: "01",
            title: "Sign up for an Account",
            description: "Enter your phone number and gain access to 1,000+ tradable assets in global markets.",
        },
        {
            id: 2,
            title2: "Trade",
            src: "assets/images/tradingapp/swiper1.webp",
            number: "02",
            title: "Trade with Tradsify App",
            description: "Simple tap on your desired product and execute trades in seconds.",
        },
        {
            id: 3,
            title2: "Order",
            src: "assets/images/tradingapp/swiper1.webp",
            number: "03",
            title: "Place your Order with Tradsify App",
            description: "Open, adjust, and close your orders easily. Set your stop-loss and take-profit with ease.",
        },
        {
            id: 4,
            title2: "Discover",
            src: "assets/images/tradingapp/swiper1.webp",
            number: "04",
            title: "Discover Page",
            description: "Stay ahead of the markets with our Economic Calendar, Newsletter, and in-depth Market Analysis.",
        },
        {
            id: 5,
            title2: "Profile & Setting",
            src: "assets/images/tradingapp/swiper1.webp",
            number: "05",
            title: "Setting Up your Profile and Setting",
            description: "Effortlessly toggle between accounts using our account management feature. Plus, customise your theme and language preferences with just a tap on the settings button!",
        },
    ];

    const handleCircleClick = (id) => {
        setActiveSlide(id);
        if (swiperRef.current) {
            swiperRef.current.swiper.slideTo(id - 1); // Swiper index is 0-based
        }
    };

    return (
        <>
            <ScrollToTop>
                <Header />
                <section className="section_space pb-0">
                    <div className="container">
                        <div className="row">
                            <div className="hero_tradingapp">
                                <h2 className="main_heading text-white">Tradsify App</h2>
                                <p className="font_16 text-white">Access global markets anywhere, anytime with the Tradesify App on mobile.</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space">
                    <div className="container">
                        <div className="row text-center justify-content-center">
                            <div className="col-10 col-lg-6">
                                <div className="demo_social d-flex justify-content-center gap-3">
                                    <img src="assets/images/demotrading/google-play-demo.svg" alt="." />
                                    <img src="assets/images/demotrading/apple-store-demo.svg" alt="." />
                                </div>
                                <div className="demo_content mt-4">
                                    <h2 className="sub_heading mb-3">What is Tradsify App？</h2>
                                    <p className="font_16">The Tradesify App, designed for both iOS and Android devices, offers traders seamless mobile access to a broad selection of their favourite assets for trading. Experience the power of advanced trading tools integrated into an intuitive, user-friendly charting interface right on your mobile device.</p>
                                    <p className="font_16">With the Tradesify app, you can enjoy a seamless account setup process and an integrated funds management system that supports secure deposits and withdrawals.Tradesify App users also gain easy access to daily market commentary, global financial news, and in-depth market analysis.</p>
                                    <p className="font_16">Harness the power of mobile trading with the Tradesify App and trade anytime, anywhere.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space trade_bg">
                    <div className="container">
                        <div className="row g-4">
                            <h2 className="sub_heading text-center">Why Trade on Tradsify APP</h2>
                            <div className="col-lg-4 col-md-6">
                                <div className="tradingapp_card">
                                    <h3 className="small_heading">Accessibility to 1,000+ instruments</h3>
                                    <p className="font_16">Discover a world of trading opportunities with the Tradsify App. Gain instant access to 1,000+ markets including forex, commodities, indices, shares, ETFs, bonds, and more.</p>
                                    {/* <div className="tradingapp1_img">
                                        <img src="assets/images/tradingapp/tradindappcard1.webp" alt="" />
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="tradingapp_card2">
                                    <h3 className="small_heading">Powerful trading tools and global market news</h3>
                                    <p className="font_16">Boost your trading with powerful tools including a range of technical indicators, customisable charting tools, and 10 timeframes for precise analysis.</p>
                                    <p className="font_16">Seamlessly manage orders, create watchlists and stay informed with alerts and a news calendar. Tradsify app users can also conveniently access daily market commentary and in-depth analysis using the app.</p>
                                    {/* <div className="tradingapp2_img">
                                        <img src="assets/images/tradingapp/tradindappcard2.webp" alt="" />
                                    </div> */}
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="tradingapp_card3">
                                    <h3 className="small_heading">Copy trading</h3>
                                    <p className="font_16">Replicate the trading strategies and orders made by trading experts. Broaden your trading knowledge by monitoring and learning the trading strategies used by experts.</p>
                                    <p className="font_16">Copy trading also presents an efficient way to manage your time allowing you to automate your trading activities by following the tactics employed by signal providers.</p>
                                    {/* <div className="tradingsapp3_img d-flex justify-content-center">
                                        <img src="assets/images/tradingapp/tradindappcard3.webp" alt="" />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space swiper_spaceapp pb-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2 className="sub_heading text-center mb-3">Tradsify App User Guide</h2>
                                <div className="demo_social d-flex justify-content-center gap-3">
                                    <img src="assets/images/demotrading/google-play-demo.svg" alt="." />
                                    <img src="assets/images/demotrading/apple-store-demo.svg" alt="." />
                                </div>
                            </div>
                            <div className="mouse-scroll-swiper-section">
                                <div>
                                    {slides.map((slide) => (
                                        <div key={slide.id} style={{ display: "flex", flexDirection: "row", gap:"20px", alignItems: "center" }}>
                                            <div
                                                onClick={() => handleCircleClick(slide.id)}
                                                style={{
                                                    width: "40px",
                                                    height: "40px",
                                                    borderRadius: "50%",
                                                    backgroundColor: slide.id === activeSlide ? "#2484c6" : "#818181",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    margin: "10px 0",
                                                    color: slide.id === activeSlide ? "#fff" : "black",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                {slide.id}
                                            </div>
                                            <p className="font_16 tradingapp_list" style={{ width: "122px", marginTop: "15px", textAlign: "start", color: slide.id === activeSlide ? "#2484c6" : "#818181" }}>
                                                {slide.title2} {/* This paragraph can show the title of the slide */}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                                <Swiper
                                    ref={swiperRef}
                                    direction="vertical"
                                    spaceBetween={20}
                                    slidesPerView={1}
                                    mousewheel
                                    modules={[Pagination, Mousewheel]}
                                    style={{ height: "790px", width: "1000px" }}
                                    onSlideChange={(swiper) => setActiveSlide(swiper.activeIndex + 1)}
                                >
                                    {slides.map((slide) => (
                                        <SwiperSlide key={slide.id}>
                                            <div className="slide-contents">
                                                <img
                                                    src={slide.src}
                                                    alt={slide.alt || `Slide ${slide.id}`}
                                                    style={{
                                                        width: "400px",
                                                        height: "550px",
                                                        borderRadius: "10px",
                                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                                    }}
                                                />
                                                <div>
                                                    <h2 className="medium_heading text-sm-center">{slide.number}</h2>
                                                    <h3 className="slide-title text-sm-center">{slide.title}</h3>
                                                    <p className="slide-description text-sm-center">{slide.description}</p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space trade_fund">
                    <div className="container">
                        <div className="tradingapp_shadow">
                            <div className="row g-4">
                                <div className="hero_tradingapp">
                                    <h2 className="medium_heading text-white mb-4">How to Trade on Tradsify App</h2>
                                    <a href="#/" className="btn btn_blue fw-semibold font_16 hero_demotrading_btn z-0">TRADE NOW
                                        <span><img src="assets/images/copytrading/hero-btn-icon.svg" alt="" className="hero_demotrading_img" /></span>
                                    </a>
                                </div>
                                <div className="col-lg-2">
                                    <div className="app_step">1</div>
                                    <div className="app_step_title sub_small_heading">Register</div>
                                    <div className="app_step_text font_16">Quick and easy account opening process.</div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="app_step">2</div>
                                    <div className="app_step_title sub_small_heading">Fund</div>
                                    <div className="app_step_text font_16">Fund your trading account with an extensive choice of deposit methods.</div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="app_step">3</div>
                                    <div className="app_step_title sub_small_heading">Register</div>
                                    <div className="app_step_text font_16">Trade with spreads starting as low as 0.0 and gain access to over 1,000+ CFD instruments.</div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="demo_social d-flex justify-content-lg-end gap-3">
                                        <img src="assets/images/demotrading/google-play-demo.svg" alt="." />
                                        <img src="assets/images/demotrading/apple-store-demo.svg" alt="." />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space">
                    <div className="container">
                        <div className="text-center">
                            <h2 className="sub_heading mb-xl-5 mb-3">FREQUENTLY ASKED QUESTIONS</h2>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="false"
                                            aria-controls="collapseOne"
                                        >
                                            1 .  What is the Tradsify app?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            The Tradsify app is a mobile app that allows users to gain access to a variety of trading assets. It features advanced tools for every trading style, a user-friendly interface, swift account setup, and seamless fund management.
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                            Download the app on both <a href="#/">iOS</a> or <a href="#/">Android</a>.
                                        </div>
                                    </div>

                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            2 . How long do deposits and withdrawals take?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse "
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            Deposits from UnionPay are instant.
                                        </div>
                                        <div className="accordions-body text-start font_16">
                                            Withdrawals are usually processed within 1 - 3 working days depending on your bank's operational procedures.
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                            Wire and bank transfers are subject to your bank's processing time.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                        >
                                            3 .   Why is my withdrawal taking so long?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            Withdrawals are usually processed within 1 - 3 working days depending on your bank's operational procedures.
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                            If you have not received your withdrawal after 3 working days, kindly contact Customer Support (available 24/7) at <a href="#/">support@tradsifymarkets.com,</a> call at +44 2080 363 883, or Live Chat.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour"
                                        >
                                            4 . Are there any restrictions or fees on deposits and withdrawals?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFour"
                                        className="accordion-collapse collapse "
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            The minimum deposit is USD200 (or equivalent).
                                        </div>
                                        <div className="accordions-body text-start font_16">
                                            If you use UnionPay to withdraw more than USD100, no fees will be charged; if your withdrawal is less than USD100, the bank will charge a fee of USD20 per transaction.
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                            Wire or bank withdrawals will incur a USD20 (or equivalent) fee.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive"
                                            aria-expanded="false"
                                            aria-controls="collapseFive"
                                        >
                                            5 .   Is the Tradsify app free?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFive"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingFive"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            Yes, the Tradsify app is free to download on both <a href="#/">IOS</a> and <a href="#/">Android</a>. Experience the world of trading at your fingertips today.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </ScrollToTop>
        </>
    )
}