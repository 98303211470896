// import HeroSwiper from "./HeroSwiper";/

export default function Hero() {
  return (
    <>
      {/* <section className="">
        <div className="mb-xl-5 pb-xl-5 pb-3">
          <div className="section_space mt-lg-5 position-relative hero_vidioo">
            <div className="container">
              <div className="row mt-xl-5 pt-xl-5">
                <div className="col-lg-8">
                  <h1 className="main_heading mb-lg-4 mb-md-3 mb-2 z-3 position-relative hero_text">
                    Transform Your Trading <br className="d-md-block d-none" />{" "}
                    Experience with tradsify
                  </h1>
                  <p className="mb-4 font_15 z-3position-relative hero_text">
                    Auto Trading, Portfolio Management, and Trade <br />
                    Export—all in one platform.
                  </p>
                  <div>
                    <button className="btn btn_black px-lg-5 px-2">
                      Join Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="section_space hero_video position-relative">
          <div class="container">
            <div class="row my-md-5 my-3 py-lg-5 py-3">
            <div className="col-lg-8">
                  <h1 className="main_heading mb-lg-4 mb-md-3 mb-2 text-white wow animate__animated animate__fadeInUp">
                    Transform Your Trading <br className="d-md-block d-none" />{" "}
                    Experience With Tradsify
                  </h1>
                  <p className="mb-md-4 mb-3 font_15 text-white position-relative wow animate__animated animate__fadeInUp">
                    Auto Trading, Portfolio Management, and Trade <br />
                    Export—all in one platform.
                  </p>
                  <div>
                    <a href="https://my.tradsify.com/en/auth/sign-up" className="btn btn_blue px-lg-5 px-2 wow animate__animated animate__fadeInUp">
                      Join Now
                    </a>
                  </div>
                </div>
            </div>
          </div>
        </section>
    </>
  );
}
