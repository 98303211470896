import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link } from "react-router-dom";
import { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Mousewheel, Autoplay } from "swiper/modules";


export default function TradsifyRewards() {
    const [activeSlide, setActiveSlide] = useState(1);
    const swiperRef = React.useRef(null);
    const slides = [
        {
            id: 1,
            title2: "Get T-Points with every trade you make.",
            src: "assets/images/tradsifyrewards/slider1.png",
            title: "Collect T-Points",
        },
        {
            id: 2,
            title2: "Use accumulated T-Points to redeem rewards of your choice.",
            src: "assets/images/tradsifyrewards/slider2.png",
            title: "Redeem Rewards",
        },
        {
            id: 3,
            title2: "Earn additional T-Points when you complete missions.",
            src: "assets/images/tradsifyrewards/slider3.png",
            title: "Complete Missions",
        },
        {
            id: 4,
            title2: "Rise to new tiers and unlock exclusive rewards.",
            src: "assets/images/tradsifyrewards/slider4.png",
            title: "Unlock New Tiers",
        },
        {
            id: 5,
            title2: "Daily free spins and trade vouchers await.",
            src: "assets/images/tradsifyrewards/slider5.png",
            title: "Get Freebies",
        },
    ];

    const handleCircleClick = (id) => {
        setActiveSlide(id);
        if (swiperRef.current) {
            swiperRef.current.swiper.slideTo(id - 1); // Swiper index is 0-based
        }
    };

    return (
        <>
            <ScrollToTop>
                <Header />
                <section className="section_space hero_rewards_bg">
                    <div className="container">
                        <div className="row align-items-center justify-content-center text-lg-start text-center g-3">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <h2 class="main_heading">Tradsify Rewards</h2>
                                <p className="font_16">Reap more rewards as you trade.</p>
                                <Link to="">
                                    <button className="btn btn_blue px-lg-5 deposit_button font_16">TRADE NOW</button>
                                </Link>
                                <p className="font_16 mt-3">*<Link to="" className="text-decoration-underline text-dark">Terms and Conditions</Link> apply.</p>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="rewards_hero_img">
                                    {/* <img src="assets/images/tradsifyrewards/reawards-hero-img.webp" alt="" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section_space swiper_spaceapp pb-0">
                    <div className="container">
                        <div className="row g-3">
                            <div className="col-lg-12">
                                <h2 className="sub_heading text-center mb-3">Get Rewarded with Every Trade</h2>
                            </div>
                            <div className="row d-flex align-items-center justify-content-center flex-column flex-md-row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <Swiper
                                    className="trad_rewards"
                                        ref={swiperRef}
                                        direction="vertical"
                                        spaceBetween={20}
                                        slidesPerView={1}
                                        mousewheel
                                        modules={[Pagination, Mousewheel, Autoplay]}
                                        autoplay={{
                                            delay: 3000,
                                            disableOnInteraction: false,
                                        }}
                                        style={{ height: "660px", width: "100%" }}
                                        onSlideChange={(swiper) => setActiveSlide(swiper.activeIndex + 1)}
                                    >
                                        {slides.map((slide) => (
                                            <SwiperSlide key={slide.id}>
                                                <div className="slide-contents">
                                                    <img
                                                        src={slide.src}
                                                        alt={slide.alt || `Slide ${slide.id}`}
                                                        className="img-fluid"
                                                        style={{
                                                            width: "100%",
                                                            height: "auto",
                                                            borderRadius: "10px",
                                                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                                            objectFit: "cover",
                                                        }}
                                                    />
                                                </div>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 mt-4 mt-md-0">
                                    {slides.map((slide) => (
                                        <div
                                            key={slide.id}
                                            className="d-flex flex-row gap-3 align-items-center"
                                        >
                                            <div onClick={() => handleCircleClick(slide.id)}>
                                                <p
                                                    className="font_20 fw-bold"
                                                    style={{
                                                        textAlign: "start",
                                                        color: slide.id === activeSlide ? "#2484c6" : "#818181",
                                                    }}
                                                >
                                                    {slide.title}
                                                </p>
                                                <p
                                                    className="font_16"
                                                    style={{
                                                        textAlign: "start",
                                                        color: slide.id === activeSlide ? "#2484c6" : "#818181",
                                                    }}
                                                >
                                                    {slide.title2}
                                                </p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <section className="section_space overflow-hidden">
                    <div class="container">
                        <div className="row">
                            <h1 className="sub_heading text-center">How to Join Tradsify Rewards</h1>
                            <div className="col-lg-12">
                                <ul class="nav nav-tabs mt-4 justify-content-center mb-4" id="myTab" role="tablist">
                                    <li class="nav-item deposit_item" role="presentation">
                                        <button class="nav-link deposit_tab_button font_20 active" id="new-user-tab" data-bs-toggle="tab" data-bs-target="#new-user" type="button" role="tab" aria-controls="new-user" aria-selected="true">New User</button>
                                    </li>
                                    <li class="nav-item deposit_item" role="presentation">
                                        <button class="nav-link deposit_tab_button font_20" id="existing-user-tab" data-bs-toggle="tab" data-bs-target="#existing-user" type="button" role="tab" aria-controls="existing-user" aria-selected="false">Existing User</button>
                                    </li>
                                </ul>
                            </div>
                            <div class="tab-content mt-4" id="myTabContent">
                                <div class="tab-pane fade show active" id="new-user" role="tabpanel" aria-labelledby="new-user-tab">
                                    <div class="row g-3">
                                        <div class="col-lg-3 col-md-6 col-sm-6 g-3">
                                            <div className="main_funds position-relative">
                                                <div className="funds_join_img">
                                                    <img src="assets/images/tradsifyrewards/tab1.webp" alt="" className="trad_live"/>
                                                </div>
                                                <div className="funds_step1">1</div>
                                                <p className="font_16">Open a Tradsify live account.</p>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-6 col-sm-6 g-3">
                                            <div className="main_funds position-relative">
                                                <div className="funds_join_img">
                                                    <img src="assets/images/tradsifyrewards/tab2.webp" alt="" className="trad_live"/>
                                                </div>
                                                <div className="funds_step2">2</div>
                                                <p className="font_16">Earn T-Points with each trade.</p>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-6 col-sm-6 g-3">
                                            <div className="main_funds position-relative">
                                                <div className="funds_join_img">
                                                    <img src="assets/images/tradsifyrewards/tab3.webp" alt="" className="trad_live"/>
                                                </div>
                                                <div className="rewards_step3">3</div>
                                                <p className="font_16">Complete missions to earn additional T-Points.</p>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-md-6 col-sm-6 g-3">
                                            <div className="main_funds position-relative">
                                                <div className="funds_join_img">
                                                    <img src="assets/images/tradsifyrewards/tab4.webp" alt="" className="trad_live"/>
                                                </div>
                                                <div className="funds_step3">4</div>
                                                <p className="font_16">Redeem rewards with the accumulated T-Points.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center d-flex justify-content-center mt-4">
                                        <button class="btn btn_blue hero_refer_btn px-lg-5" type="button">OPEN LIVE ACCOUNT</button>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="existing-user" role="tabpanel" aria-labelledby="existing-user-tab">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-6 g-3">
                                            <div className="main_funds position-relative">
                                                <div className="funds_join_img">
                                                <img src="assets/images/tradsifyrewards/tab1.webp" alt="" className="trad_live"/>
                                                </div>
                                                <div className="funds_step1">1</div>
                                                <p className="font_16">Log in to your account and start trading.</p>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 g-3">
                                            <div className="main_funds position-relative">
                                                <div className="funds_join_img">
                                                <img src="assets/images/tradsifyrewards/tab3.webp" alt="" className="trad_live"/>
                                                </div>
                                                <div className="funds_step2">2</div>
                                                <p className="font_16">Trade and complete missions to earn T-Points.</p>
                                            </div>
                                        </div>
                                        <div class="col-lg-4 col-md-6 g-3">
                                            <div className="main_funds position-relative">
                                                <div className="funds_join_img">
                                                <img src="assets/images/tradsifyrewards/tab4.webp" alt="" className="trad_live"/>
                                                </div>
                                                <div className="funds_step3">3</div>
                                                <p className="font_16">Redeem rewards with the accumulated T-Points. <em>*Applicable exclusively to clients from specific jurisdictions as per regulatory requirements.</em></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center d-flex justify-content-center mt-4">
                                        <button class="btn btn_blue hero_refer_btn px-lg-5" type="button">LOGIN NOW</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="section_space testimonials mb-5">
                    <h2 className="medium_heading text-center mb-4">
                        Here’s What Our Users Have To Say
                    </h2>
                    <div
                        className="testimonials-container"
                        ref={containerRef}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUpOrLeave}
                        onMouseLeave={handleMouseUpOrLeave}
                    >
                        {[
                            {
                                title: "I am new to all of this but have found…",
                                content:
                                    "Very user friendly application with tons of in-depth information about stock marker aswell app mechanics. I love the trader copy system, i would for sure recommend Tradsify to all my friends and family. FAQ is clear and short.One improvement is that the server speed to calculate the trade in real time sometimes gets frozen and you need to re open the app. Besides that everything works like a charm",
                                user: "phil oakley",
                            },
                            {
                                title: "I must say Tradsify platform is the…",
                                content:
                                    "I must say Tradsify platform is the best, I have just realized you have swap Free accounts too, you are all in one, I have also compared the lot size in relation to margin holds, you people your platform is the best for even beginners with minimum capital. Regards",
                                user: "Martin Tumukunde",
                            },
                            {
                                title: "I deposited money into my tradsify…",
                                content:
                                    "I deposited money into my tradsify account, did a few trades, lost and won here and there...Best part is l managed to withdraw my funds with no hustle, my money was deposited back into my account within 24 hours.",
                                user: "Mtha",
                            },
                            {
                                title: "The best of the bests, for sure",
                                content:
                                    "I have an acc with Tradsify for a year, and I'm in the market every single day with Tradsify, the Team are great, perfect honestly my acc manager answers all my questions and the support team is 24/7 for everything. I can confirm that this is the most positive experience I had online.",
                                user: "Marcio Rodrigues",
                            },
                            {
                                title: "I have been with tradsify a few years",
                                content:
                                    "I have been with tradsify a few years. When i have needed assistance tradsify have been swift in returning my emails with information to help me. When withdrawing funds. Never had to wait more the 48hrs at times quicker than that. Very happy with thier swap prices. Tradsify is a good Broker.",
                                user: "Leonette Henry",
                            },
                            {
                                title: "Great Trading App",
                                content:
                                    "I've been using this trading app for a while now and I'm thoroughly impressed. The user friendly interface makes navigating the complex world of trading a breeze, even for someone like me who's new to the game. The app's real time market updates and intuitive charting tools have empowered me to make well informed decisions. What I appreciate the most is the security measures in place that ensure my sensitive information and transactions are well protected. With a wide range of investment options and seamless execution, this app has truly elevated my trading experience. It's become an indispensable tool in my financial journey",
                                user: "Saim Ali",
                            },
                            {
                                title: "Very good broker",
                                content:
                                    "I have a very good experience in tradsify. Easy to deposit and to withdraw. The spread is also low that you can can catch the maximum profit you want. Their app is also great and easy to use. Keep up the good work.",
                                user: "Mark Angelo",
                            },
                            {
                                title: "Trustworthy, quality customer relations, patience and understanding when interacting with customers.",
                                content:
                                    "I loved how they gave me a bonus that I have been trading on until I withdraw my profits. Customer care is topnotch and I really loved how they took me step by step on customer service. Would recommend all the time",
                                user: "grace betty",
                            },
                        ].map((testimonial, index) => (
                            <div key={index} className="testimonial-card">
                                <h3 className="sub_small_heading deposit_test">{testimonial.title}</h3>
                                <p className="font_16 deposit_test">{testimonial.content}</p>
                                <span className="font_16 deposit_test">{testimonial.user}</span>
                            </div>
                        ))}
                    </div>

                </section> */}

                <section className="section_space">
                    <div className="container">
                        <div className="text-center">
                            <h2 className="sub_heading">Frequently Asked Questions</h2>
                            <p className="font_16">Find answers to commonly asked questions down below. For further queries, please use the <Link to="" className="text-dark">Contact Us</Link> page.</p>
                            </div>
                            <div className="accordion" id="accordionExample">
                                <h3 className="font_30 text-start">GENERAL</h3>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="false"
                                            aria-controls="collapseOne"
                                        >
                                            1.1 .  What is Tradsify Rewards and how does it work?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            Tradsify Rewards is a point-based loyalty program that allows our clients to earn T-Points as they trade. These points can be used to redeem rewards — just another way for us to say thank you to our clients, and show how much we appreciate your loyalty.
                                        </div>
                                    </div>

                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            1.2 . Can the Program be terminated?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse "
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            Yes. We may, at our sole discretion, cancel, modify, restrict, or terminate the program or any aspect or feature of Tradsify Rewards at any time with or without prior notice, whereas such changes may affect the value of rewards, benefits, and/or points already accumulated or earned and/or the ability to redeem accumulated rewards, benefits and points.
                                        </div>
                                    </div>
                                </div>
                                <h3 className="font_30 text-start">MEMBERSHIP</h3>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                        >
                                            2.1 .   How can I join?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            All Tradsify clients will automatically be enrolled on the Loyalty Program as a Member. However, only clients with live and funded accounts will be allowed to redeem rewards using T-Points.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour"
                                        >
                                            2.2 . Can my membership in the program be terminated?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFour"
                                        className="accordion-collapse collapse "
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            Tradsify reserves the right, at its sole discretion, to terminate any membership with or without cause. However, under normal circumstances, only those who are not acting in good faith, not in compliance with the Terms and Conditions, and/or involved in any abuse or fraudulent practice will be terminated.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive"
                                            aria-expanded="false"
                                            aria-controls="collapseFive"
                                        >
                                            2.3 .   How do I terminate my membership?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFive"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingFive"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            If you decide you no longer want to be a part of the program, please send your request to opt-out to promotions@tradsifymarkets.com
                                        </div>
                                    </div>
                                </div>
                                <h3 className="font_30 text-start">POINTS AND REWARDS</h3>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingSix">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseSix"
                                            aria-expanded="false"
                                            aria-controls="collapseSix"
                                        >
                                            3.1 .  What is T-Points?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseSix"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingSix"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            T-Points can be used to redeem rewards such as vouchers, lucky draw entry, spin the wheel chances and many more. The number of T-Points needed will differ depending on the type of reward.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingSeven">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseSeven"
                                            aria-expanded="false"
                                            aria-controls="collapseSeven"
                                        >
                                            3.2 .    How do I earn T-Points?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseSeven"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingSeven"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            T-Points can be earned as you trade our products. The number of T-Points that can be earned per 1 mil notional volume traded will depend on the type of products being traded as well as your current Tier. Visit Tradsify Rewards Tiers for more details on the points system.
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                            Other than that, you can also earn T-Points by completing our daily, weekly, monthly, and ad-hoc missions.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingEight">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseEight"
                                            aria-expanded="false"
                                            aria-controls="collapseEight"
                                        >
                                            3.3 .   What are the rewards?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseEight"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingEight"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16 pt-0">
                                            You will find all available rewards listed in Points Center. Rewards type may be updated from time to time.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingNine">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseNine"
                                            aria-expanded="false"
                                            aria-controls="collapseNine"
                                        >
                                            3.4 .   How do I check my current points?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseNine"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingNine"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            Your current Tier and T-Points are displayed at the top section of all pages within Tradsify Rewards. You must login to your account to view your T-Points.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingTen">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTen"
                                            aria-expanded="false"
                                            aria-controls="collapseTen"
                                        >
                                            3.5 .    Can my points expire?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTen"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingTen"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            Yes. Your accumulated T-Points are only valid for a year. However, the good news is, your points will be extended for 1 year once you make a trade. This extended expiry date will be 1 year from the date of the latest trade you make. If no trade is executed, all points will expire based on the earliest expiry date of the first points earned. So, keep trading to keep your T-Points valid!
                                        </div>
                                    </div>
                                </div>
                                <h3 className="font_30 text-start">TIERS AND BENEFITS</h3>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingeleven">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseeleven"
                                            aria-expanded="false"
                                            aria-controls="collapseeleven"
                                        >
                                            4.1 .   What is Tradsify Rewards Tiers?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseeleven"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingeleven"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            Tiers are determined by the notional volume a Member has traded within 90 days. There are 4 tiers with associated benefits – Bronze, Silver, Gold, and Platinum.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingtwelve">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapsetwelve"
                                            aria-expanded="false"
                                            aria-controls="collapsetwelve"
                                        >
                                            4.2 .   What are the benefits?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapsetwelve"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingtwelve"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            The higher your Tier is, the more T-Points you can earn based on your trading volume. You will also unlock more exclusive benefits as you progress through the Tiers. Visit Tradsify Rewards Tiers for more details on the benefits.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingthirteen">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapsethirteen"
                                            aria-expanded="false"
                                            aria-controls="collapsethirteen"
                                        >
                                            4.3 .   How do I upgrade my Tier?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapsethirteen"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingthirteen"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            You need to increase your notional volume traded on closed trades to upgrade your Tier.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingfourteen">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapsefourteen"
                                            aria-expanded="false"
                                            aria-controls="collapsefourteen"
                                        >
                                            4.4 .   Will I get downgraded?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapsefourteen"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingfourteen"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            Yes. You will be downgraded if you do not complete 10 trades within your Maintenance Period of 90 days.
                                        </div>
                                    </div>
                                </div>
                            </div>
                      
                    </div>
                </section>
                <Footer />
            </ScrollToTop>
        </>
    )
}