import React from "react";
import ScrollToTop from "../component/ScrollToTop";
import Header from "../component/Header";
import Footer from "../component/Footer";
import { Link } from "react-router-dom";


export default function CopyTrading() {
    return (
        <>
            <ScrollToTop>
                <Header />
                <section className="section_space copy_hero_bg">
                    <div className="container">
                        <div className="row">
                            <div className="hero_heading">
                                <h2 className="main_heading text-white">Tradsify Copy Trading</h2>
                                <p className="font_16 text-white">Copy, trade, and earn in one tap</p>
                                <Link target="_blank" to="https://my.tradsify.com/en/auth/sign-in" className="btn btn_blue fw-semibold font_16 hero_copytrading_btn z-0">Copy Trade Now
                                    <span><img src="assets/images/copytrading/hero-btn-icon.svg" alt="" className="hero_btn_icon" /></span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space">
                    <div className="container">
                        <div className="row g-5">
                            <div className="col-12">
                                <h1 class="sub_heading text-center">Tradsify Copy Trading</h1>
                                <p className="font_16 text-center">Empowering beginners with strategies, enhancing opportunities for experts</p>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="copy_trading_content text-center">
                                    <h3 className="small_heading">Transparent access to unlimited strategies</h3>
                                    <p className="font_16 text-muted">Analyse and get an overview of every trader's profile and returns before you copy</p>
                                </div>
                                <div className="copy_trading_img d-flex justify-content-center">
                                    <img src="assets/images/copytrading/copy-trading-strag1.png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="copy_trading_content text-center">
                                    <h3 className="small_heading">Trusted and secure</h3>
                                    <p className="font_16 text-muted">Trade safely with two-factor authentication, multiple account security levels, and safety locks</p>
                                </div>
                                <div className="copy_trading_imgs d-flex justify-content-center">
                                    <img src="assets/images/copytrading/copy-trading-strag2.png" alt="" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="copy_trading_content text-center">
                                    <h3 className="small_heading">Seize opportunities in one tap</h3>
                                    <p className="font_16 text-muted">Diversify, copy, and trade in real-time. When they trade, you trade</p>
                                </div>
                                <div className="copy_trading_imgs d-flex justify-content-center">
                                    <img src="assets/images/copytrading/copy-trading-strag3.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space">
                    <div className="container">
                        <div className="row g-xl-5 g-4">
                            <div className="col-12">
                                <h3 className="medium_heading text-center">If they profit, you profit too</h3>
                                <p className="font_16 text-center">Make a master's portfolio yours with a single tap - Pick from our diverse range of traders with a wide array of products in their portfolio.</p>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="copy_trading_cardbody">
                                    <div className="copy_trading_profit mb-5">
                                        <h4 className="sub_small_heading text-center">Copy from as low  as USD 50</h4>
                                    </div>
                                    <div className="copy_trading_img d-flex justify-content-center">
                                        <img src="assets/images/copytrading/copy-trading-profit1.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="copy_trading_cardbody">
                                    <div className="copy_trading_profit mb-5">
                                        <h4 className="sub_small_heading text-center">Suitable for all levels of traders</h4>

                                    </div>
                                    <div className="copy_trading_img d-flex justify-content-center">
                                        <img src="assets/images/copytrading/copy-trading-profit2.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="copy_trading_cardbody">
                                    <div className="copy_trading_profit mb-5">
                                        <h4 className="sub_small_heading text-center">3 ways for you to copy trade</h4>

                                    </div>
                                    <div className="copy_trading_img d-flex justify-content-center">
                                        <img src="assets/images/copytrading/copy-trading-profit3.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="copy_trading_cardbody">
                                    <div className="copy_trading_profit mb-5">
                                        <h4 className="sub_small_heading text-center">Ultra-low barrier to entry</h4>

                                    </div>
                                    <div className="copy_trading_img d-flex justify-content-center">
                                        <img src="assets/images/copytrading/copy-trading-profit4.svg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space">
                    <div className="container">
                        <div className="row g-xl-3 g-3">
                            <div className="col-12">
                                <h3 className="medium_heading text-center">Your profitable trades power your earnings</h3>
                                <p className="font_16 text-center">Share your trades publicly by becoming a Tradsify Signal Provider today. With Tradsify's Profit Sharing model, you have control over your earnings. Start creating up to 10 strategies and automatically receive your payouts on time.</p>
                            </div>
                            <div className="col-lg-6 col-md-6">
                              <div className="copy_trading_earning d-flex gap-4">
                                <img src="assets/images/copytrading/copy-trading-earning1.svg" alt="" />
                                <p className="font_16 d-flex align-items-center mt-3">Timely and transparent transactions</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                            <div className="copy_trading_earning d-flex gap-4">
                                <img src="assets/images/copytrading/copy-trading-earning2.svg" alt="" />
                                <p className="font_16 d-flex align-items-center mt-3">Link your MT5 accounts seamlessly</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                            <div className="copy_trading_earning d-flex gap-4">
                                <img src="assets/images/copytrading/copy-trading-earning3.svg" alt="" />
                                <p className="font_16 d-flex align-items-center mt-3">Freely adjust your Profit Sharing ratio</p>
                              </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                            <div className="copy_trading_earning d-flex gap-4">
                                <img src="assets/images/copytrading/copy-trading-earning4.svg" alt="" />
                                <p className="font_16 d-flex align-items-center mt-3">Establish a global reputation</p>
                              </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="section_space">
                    <div className="container">
                        <div className="text-center">
                            <h2 className="sub_heading mb-xl-5 mb-3">General FAQs</h2>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseOne"
                                            aria-expanded="false"
                                            aria-controls="collapseOne"
                                        >
                                            1 .  What is Copy Trading?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseOne"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingOne"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            Copy Trading is a trading strategy and function that automatically copies the entire portfolio of any other trader. Copy Trading mainly consists of 2 roles, which are:
                                        </div>
                                        <div className="accordions-body text-start font_16 pb-1 pt-0">
                                            <strong>Signal Provider:</strong> Users who publicly share their trades for other users to copy.
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                            <strong>Copier:</strong> Users who copy the publicly shared trades of Signal Providers.
                                        </div>
                                    </div>

                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTwo"
                                            aria-expanded="false"
                                            aria-controls="collapseTwo"
                                        >
                                            2 . What is the minimum amount to start Copy Trading?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTwo"
                                        className="accordion-collapse collapse "
                                        aria-labelledby="headingTwo"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            The starting amount differs based on the currency of the account. The minimum amount starts from as low as USD 50, EUR 50, HKD 400, JPY 7000, USC 4000 and INR 4000.                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingThree">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseThree"
                                            aria-expanded="false"
                                            aria-controls="collapseThree"
                                        >
                                            3 .   Which markets can I trade on through a Copy Trading Account?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseThree"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingThree"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            You can access over 1,000 tradeable CFDs ranging from Forex, Crypto, Shares, Indices, Metals and Commodities.
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                            Both Signal Providers and Copiers may trade these assets through the Manual Trading tab in the Orders Page.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingFour">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFour"
                                            aria-expanded="false"
                                            aria-controls="collapseFour"
                                        >
                                            4 . How do I start Copy Trading?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFour"
                                        className="accordion-collapse collapse "
                                        aria-labelledby="headingFour"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            <strong>New Users: </strong>Download the Tradsify app to begin. First-time signups can immediately choose to open a Copy Trading account.
                                        </div>
                                        <div className="accordion-body text-start font_16">
                                            <strong>Existing Users: </strong>Head to the Account Management Page in your Profile, and select Open Copy Trading Account.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingFive">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseFive"
                                            aria-expanded="false"
                                            aria-controls="collapseFive"
                                        >
                                            5 .   Can I stop copying a Signal Provider anytime?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseFive"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingFive"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            Yes. You can stop a copy trade order with just one tap on the Orders page.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingSix">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseSix"
                                            aria-expanded="false"
                                            aria-controls="collapseSix"
                                        >
                                            6 .  Can I copy more than one strategy at a time?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseSix"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingSix"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            Yes, you can! There are no limits to how many strategies you can copy as long as you have sufficient margin in your account.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingSeven">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseSeven"
                                            aria-expanded="false"
                                            aria-controls="collapseSeven"
                                        >
                                            7 .   Are there any exit orders in Copy Trading?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseSeven"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingSeven"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            Yes, there is. You can set your own Stop Loss and Take Profit before you copy a trader.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingEight">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseEight"
                                            aria-expanded="false"
                                            aria-controls="collapseEight"
                                        >
                                            8 .   How can I become a Signal Provider?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseEight"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingEight"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordions-body text-start font_16">
                                            There are two conditions that you need to fulfil become a Signal Provider:
                                        </div>
                                        <div className="accordions-body text-start font_16 pt-0 pb-1">
                                            1. Make a single, minimum deposit of USD 500 or more.
                                        </div>
                                        <div className="accordion-body text-start font_16 pt-0">
                                            2. Possess extensive trading experience, adhere to the best industry practices, and conduct trades with integrity.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0 mb-md-4 mb-3">
                                    <h2 className="accordion-header" id="headingNine">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseNine"
                                            aria-expanded="false"
                                            aria-controls="collapseNine"
                                        >
                                            9 .   Can I copy trade as a Signal Provider?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseNine"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingNine"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            Yes, you can. Anyone with a Copy Trading account can copy the trades of others without any limits.
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item border-0">
                                    <h2 className="accordion-header" id="headingTen">
                                        <button
                                            className="accordion-button collapsed font_20"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#collapseTen"
                                            aria-expanded="false"
                                            aria-controls="collapseTen"
                                        >
                                            10 .   How does a Signal Provider trade on a Copy Trading account?
                                        </button>
                                    </h2>
                                    <div
                                        id="collapseTen"
                                        className="accordion-collapse collapse"
                                        aria-labelledby="headingTen"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="accordion-body text-start font_16">
                                            Signal Providers can trade as you would on a regular trading account. Do note that you can only have your trades copied while trading on a Copy Trading account.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </ScrollToTop>
        </>
    )
}