import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
export default function Swipercomponent() {
  const cardsData = [
    {
      img: "assets/images/home/Educational.svg",
      title: "Free Educational Resources",
      description:
        "Build your trading knowledge with our free articles, interactive courses and webinars, covering everything from how the markets work, to complex technical strategies.",
    },
    {
      img: "assets/images/home/Trading-Tools.svg",
      title: "Advanced Trading Tools & Features",
      description:
        "Experience one-click trading, comprehensive TradingView charts, expert market analysis, and real-time price alerts all within the same CFD trading platform.",
    },
    {
      img: "assets/images/home/Diversification.svg",
      title: "Diversification",
      description:
        "Stocks, forex, or gold? With a range of asset classes and products available, CFD traders can diversify their portfolios and take adTradsify of opportunities in multiple CFD trading markets.",
    },
    {
      img: "assets/images/home/Competitive-Spreads.svg",
      title: "Competitive Spreads",
      description:
        "Fees and spreads can affect your CFD trading profitability. Enjoy competitive spreads across a wide range of CFD markets, allowing you to maximise your returns.",
    },
    {
      img: "assets/images/home/Fast-Execution.svg",
      title: "Fast Execution",
      description:
        "Our lightning-fast execution speeds on our CFD trading platform ensure that traders can seize market opportunities quickly and efficiently.",
    },
    {
      img: "assets/images/home/Transparent-Pricing.svg",
      title: "Transparent Pricing",
      description:
        "We are committed to providing transparent pricing and competitive fees, allowing CFD traders to understand the costs associated with their trades.",
    },
    {
      img: "assets/images/home/Demo-Account.svg",
      title: "Demo Account",
      description:
        "Practise your trading skills risk-free, with virtual credit. The Tradsify demo account provides access to the same asset classes and products as a live trading account.",
    },
    {
      img: "assets/images/home/Dedicated-Customer.svg",
      title: "Dedicated Customer Support",
      description:
        "Our dedicated and friendly customer support team is available 24/7 to provide assistance with any trading-related issues or questions.",
    },
  ];
  return (
    <section className="section_space text-center bg-ofwhite">
      <div className="container">
        <div className="row my-xl-0 my-3 overflow-hidden">
          <div className="col-lg-12 text-center">
            <h2 className="sub_heading mb-xl-5 mb-4 wow animate__animated animate__fadeInUp">
              Trade With Tradsify, Your CFD Broker
            </h2>
          </div>
          <div className="col-lg-12 h-100">
            <div className="my-xl-0 mt-lg-5 mt-0 my-4  wow animate__animated animate__zoomIn">
              <Swiper
                modules={[Navigation, Pagination, Autoplay]}
                spaceBetween={25}
                slidesPerView={4}
                navigation={{
                  nextEl: ".swiper-button-leader-next",
                  prevEl: ".swiper-button-leader-prev",
                }}
                loop={true}
                autoplay={{
                  delay: 8000,
                }}
                breakpoints={{
                  1440: {
                    slidesPerView: 4,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                  600: {
                    slidesPerView: 2,
                  },
                  0: {
                    slidesPerView: 1,
                  },
                }}
              >
                {cardsData.map((card, index) => (
                  <>
                    <SwiperSlide>
                      <div className="Trade_cards">
                        <div className=" mb-3">
                          <img src={card.img} alt={card.title} />
                        </div>
                        <h3 className="sub_small_heading mb-lg-4 mb-2">
                          {card.title}
                        </h3>
                        <p className="font_15">{card.description}</p>
                      </div>
                    </SwiperSlide>
                  </>
                ))}
              </Swiper>
            </div>
            {/* <div className="d-flex gap-2 justify-content-center align-items-center">
              <div className="btn_about_prev bg-white border border-dark swiper-button-leader-prev">
                <img
                  src="assets/images/home/leftarroww.svg"
                  alt="Previous"
                  className="swiper-button-leader-prev"
                />
              </div>
              <div className="btn_about_next bg-white border border-dark swiper-button-leader-next">
                <img
                  src="assets/images/home/rightarrow.svg"
                  alt="Next"
                  className="swiper-button-leader-next"
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
}
